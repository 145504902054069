const aerialApiUri = process.env.aerialPlotConnections.aerialPlotApi.baseUrl;

export function getEntityNames(flightId, fieldId, trialId, accessToken) {
	let queryString = "";

	if (flightId) {
		queryString += `flightId=${flightId}`;
	}

	if (fieldId) {
		if (queryString.length > 0) {
			queryString += "&";
		}
		queryString += `fieldId=${fieldId}`;
	}

	if (trialId) {
		if (queryString.length > 0) {
			queryString += "&";
		}
		queryString += `trialId=${trialId}`;
	}

	return fetch(`${aerialApiUri}api/v1/Entities/GetEntityNames?${queryString}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}

export function checkClient(flightId, fieldId, trialId, protocolId, currentClientId, accessToken) {
	let queryString = "";

	if (flightId) {
		queryString += `flightId=${flightId}`;
	}

	if (fieldId) {
		if (queryString.length > 0) {
			queryString += "&";
		}
		queryString += `fieldId=${fieldId}`;
	}

	if (trialId) {
		if (queryString.length > 0) {
			queryString += "&";
		}
		queryString += `trialId=${trialId}`;
	}

	if (protocolId) {
		if (queryString.length > 0) {
			queryString += "&";
		}
		queryString += `protocolId=${protocolId}`;
	}

	if (currentClientId) {
		if (queryString.length > 0) {
			queryString += "&";
		}
		queryString += `currentClientId=${currentClientId}`;
	}

	return fetch(`${aerialApiUri}api/v1/Entities/CheckClient?${queryString}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			if (res.status !== 204) {
				return res.json();
			} else {
				return "";
			}
		})
		.then((data) => data);
}
