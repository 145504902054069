import moment from "moment";
const DATE_FORMAT = "MM/DD/YYYY h:mma";
export function getApplicationOptions(clientId, cropId, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Applications/ApplicationOptions?clientId=${clientId}&cropId=${cropId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getApplications(protocolId, clientId, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Applications/Applications?protocolId=${protocolId}&clientId=${clientId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function saveApplications(applications, protocolId, clientId, accessToken) {
	const applicationsData = new FormData();
	let applicationIndex = 0;
	for (const application of applications) {
		applicationsData.append(`Applications[${applicationIndex}].Id`, application.id);
		applicationsData.append(`Applications[${applicationIndex}].ClientId`, clientId);
		applicationsData.append(`Applications[${applicationIndex}].Code`, application.code);
		applicationsData.append(`Applications[${applicationIndex}].ProtocolId`, application.protocolId);
		applicationsData.append(`Applications[${applicationIndex}].ApplicationTypeId`, application.applicationTypeId);
		applicationsData.append(`Applications[${applicationIndex}].ApplicationMethodId`, application.applicationMethodId);
		applicationsData.append(
			`Applications[${applicationIndex}].GroundDataGrowthPhaseId`,
			application.groundDataGrowthPhaseId
		);
		applicationsData.append(
			`Applications[${applicationIndex}].GroundDataTimingMethodId`,
			application.groundDataTimingMethodId
		);
		applicationsData.append(
			`Applications[${applicationIndex}].ApplicationPlacementId`,
			application.applicationPlacementId
		);
		applicationsData.append(
			`Applications[${applicationIndex}].GrowthPhaseText`,
			application.growthPhaseText ? application.growthPhaseText : "" //TODO name probably needs changed??
		);
		applicationsData.append(
			`Applications[${applicationIndex}].GrowthPhaseDateUTC`,
			application.growthPhaseDateUTC ? moment(application.growthPhaseDateUTC).format(DATE_FORMAT) : "" //TODO name probably needs changed??
		);
		applicationsData.append(
			`Applications[${applicationIndex}].GrowthStageStartStageId`,
			application.growthStageStartStageId ? application.growthStageStartStageId : ""
		);
		applicationsData.append(
			`Applications[${applicationIndex}].GrowthStageEndStageId`,
			application.growthStageEndStageId ? application.growthStageEndStageId : ""
		);
		applicationsData.append(`Applications[${applicationIndex}].Notes`, application.notes ? application.notes : "");
		applicationsData.append(
			`Applications[${applicationIndex}].ApplicationPlacementId`,
			application.applicationPlacementId
		);
		applicationsData.append(
			`Applications[${applicationIndex}].GrowthPhaseInteger`,
			application.growthPhaseInteger ? application.growthPhaseInteger : ""
		);
		applicationIndex++;
	}
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Applications/Applications?protocolId=${protocolId}&clientId=${clientId}`,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`
			},
			body: applicationsData
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function updateApplications(applications, clientId, accessToken) {
	const applicationsData = new FormData();
	let applicationIndex = 0;
	const protocolId = applications[0].protocolId;

	for (const application of applications) {
		applicationsData.append(`Applications[${applicationIndex}].Id`, application.id);

		applicationsData.append(`Applications[${applicationIndex}].Application.ClientId`, clientId);
		applicationsData.append(`Applications[${applicationIndex}].Application.ProtocolId`, application.protocolId);
		applicationsData.append(
			`Applications[${applicationIndex}].Application.ApplicationTypeId`,
			application.applicationTypeId
		);
		applicationsData.append(
			`Applications[${applicationIndex}].Application.ApplicationMethodId`,
			application.applicationMethodId
		);
		applicationsData.append(
			`Applications[${applicationIndex}].Application.GroundDataGrowthPhaseId`,
			application.groundDataGrowthPhaseId
		);
		applicationsData.append(
			`Applications[${applicationIndex}].Application.GroundDataTimingMethodId`,
			application.groundDataTimingMethodId
		);
		applicationsData.append(
			`Applications[${applicationIndex}].Application.GrowthPhaseText`,
			application.notes //TODO name probably needs changed??
		);
		applicationsData.append(
			`Applications[${applicationIndex}].Application.GrowthStageStartStageId`,
			application.growthStageStartStageId
		);
		applicationsData.append(
			`Applications[${applicationIndex}].Application.GrowthStageEndStageId`,
			application.growthStageEndStageId
		);
		applicationsData.append(`Applications[${applicationIndex}].Application.Notes`, application.notes);
		applicationsData.append(
			`Applications[${applicationIndex}].Application.ApplicationPlacementId`,
			application.applicationPlacementId
		);
		applicationIndex++;
	}
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Applications/Applications?protocolId=${protocolId}&clientId=${clientId}`,
		{
			method: "PUT",
			headers: {
				Authorization: `Bearer ${accessToken}`
			},
			body: applicationsData
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}
