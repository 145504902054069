export const objectIsNullOrEmpty = obj => {
	const isObjectNullOrEmpty = !obj || Object.keys(obj).length === 0;
	return isObjectNullOrEmpty;
};

export const toTitleCase = str => {
	var splitStr = str.toLowerCase().split(" ");
	for (var i = 0; i < splitStr.length; i++) {
		splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
	}
	return splitStr.join(" ");
};

export const getDistinctObjects = arr => {
	if (!arr) {
		return null;
	}

	if (arr.length === 0) {
		return [];
	}

	const distinctArray = [];
	const map = new Map();

	for (const item of arr) {
		if (!map.has(item.id)) {
			map.set(item.id, true);
			distinctArray.push(item);
		}
	}

	return distinctArray;
};
