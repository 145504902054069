import React, { useEffect, useState } from "react";
import { Table, Segment, Loader, Button, Icon } from "semantic-ui-react";
import "./style.scss";

import PropTypes from "prop-types";
import _ from "lodash";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import CorrelationColorGradient from "../CorrelationColorGradient";
import {
	NEUTRAL_CORRELATION_COLOR,
	NEGATIVE_CORRELATION_COLOR,
	POSITIVE_CORRELATION_COLOR
} from "../CorrelationColors";
import formatPlannedTiming from "../FormatPlannedTiming";
import calculateOverlappingCorrelationValue from "../CalculateOverlappingCorrelationValue";

const FullCorrelationMatrix = ({ growthCurveData, groundData, groundDataSelected, selectedAnchor, colors }) => {
	// Build a list of header cells based on the fullPearsonData that were passed into this page.
	const [headers, setHeaders] = useState([]);
	const [dataRows, setDataRows] = useState([]);

	const [fullPearsonData, setFullPearsonData] = useState(null);
	const [fullPearsonLoading, setFullPearsonLoading] = useState(true);

	const [displayNameMap, setDisplayNameMap] = useState({});

	// Calculate the correlation values based on the data passed into this matrix.
	function getFullPearsonData() {
		let coefficientData = [];

		const dnMap = {};

		let groupedGrowthCurveData = _.groupBy(growthCurveData, "displayName");
		// delete groupedGrowthCurveData[undefined];

		for (let gcd of growthCurveData) {
			if (gcd.curveModelDisplayName) {
				dnMap[gcd.displayName] = gcd.curveModelDisplayName;
			}
		}

		setDisplayNameMap(dnMap);
		// Create a collection of assessments and the corresponding data.
		/*
		Example:
			structuredRawData["CPP_NDVI"] = [
				{plotRange: 1, plotColumn: 1, value: 0.98, excludeFromAssessment: true},
				{plotRange: 1, plotColumn: 2, value: 0.72, excludeFromAssessment: false}
			]
		*/
		let structuredRawData = [];
		let values = [];
		_.forEach(groupedGrowthCurveData, (entry, key) => {
			if (dnMap[key]) {
				values = [];
				entry.forEach((obj) => {
					values.push({
						plotRange: obj.plotRange,
						plotColumn: obj.plotColumn,
						value: obj.value,
						plotId: obj.plotId,
						excludeFromAssessment: obj.excludeFromAssessment
					});
				});

				structuredRawData[key] = values;
			}
		});

		if (groundDataSelected) {
			// Group by ground data set Id
			const groundDataSetGroupedData = _.groupBy(groundData, "groundDatasetId");
			_.forEach(groundDataSetGroupedData, (groundDatasetGroup) => {
				// Build a distinct list of the assessment ids which are averaged in another assessment in this dataset.
				const averagedAssessmentIds = _(groundDatasetGroup)
					.filter((obj) => {
						return obj["averageFor"] != null;
					})
					.uniqBy((obj) => {
						return obj["averageFor"];
					})
					.map("averageFor")
					.value();

				// Group by ground data assessment Id
				let assessmentGroupedData = _.groupBy(groundDatasetGroup, "groundDataAssessmentId");
				_.forEach(assessmentGroupedData, (assessmentGroup) => {
					let firstAssessmentRecordInGroup = assessmentGroup[0];
					// If the Id for this assessment does not appear in the averageFor field of another assessment on this dataset, show it.
					if (!_.includes(averagedAssessmentIds, firstAssessmentRecordInGroup["groundDataAssessmentId"])) {
						values = [];
						assessmentGroup.forEach((obj) => {
							values.push({
								plotRange: obj.plotRange,
								plotColumn: obj.plotColumn,
								value: obj.value,
								excludeFromAssessment: obj.excludeFromAssessment
							});
						});

						let display = formatPlannedTiming(
							firstAssessmentRecordInGroup["assessmentName"],
							firstAssessmentRecordInGroup["timingMethodType"],
							firstAssessmentRecordInGroup["timingMethodName"],
							firstAssessmentRecordInGroup["growthStageName"],
							firstAssessmentRecordInGroup["growthPhaseInteger"],
							firstAssessmentRecordInGroup["growthPhaseStart"],
							firstAssessmentRecordInGroup["growthPhaseEnd"]
						);

						structuredRawData[display] = values;
					}
				});
			});
		}

		// Example: Accessing correlation data for the "A" field, yields an object where each property represents "A"'s correlation to that (other) property.
		// An equivalent value would be expected for A.B and B.A, for example.
		// {
		//	 A : { B: 0.5; C: 0.6; };
		//	 B : { A: 0.5; C: 0.7; };
		//	 C : { A: 0.6; B: 0.7; };
		// }

		for (const columnA in structuredRawData) {
			coefficientData[columnA] = [];
			for (const columnB in structuredRawData) {
				let correlationValue = calculateOverlappingCorrelationValue(
					structuredRawData[columnA],
					structuredRawData[columnB]
				);
				if (correlationValue < -1) {
					correlationValue = -1;
				} else if (correlationValue > 1) {
					correlationValue = 1;
				}
				coefficientData[columnA][columnB] = correlationValue;
			}
		}

		setFullPearsonData(coefficientData);
		setFullPearsonLoading(false);
	}

	function getSortedKeysListForAnchor(coefficientData, targetAnchor) {
		// Make sure that a non-empty value was received...
		if (targetAnchor !== "") {
			// Make sure that the selected anchor assessment is contained in the filtered data after the ortho checkboxes have been applied.
			// I.E. The user hasn't selected "AUC_NDVI", but unchecked the "NDVI" checkbox.
			if (_.includes(Object.keys(coefficientData), targetAnchor)) {
				var itemsToSort = Object.keys(coefficientData[targetAnchor]).map((key) => {
					return [key, coefficientData[targetAnchor][key]];
				});

				let sortedItems = itemsToSort.sort((firstElement, secondElement) => {
					return secondElement[1] - firstElement[1];
				});

				let sortedKeys = [];
				_.forEach(sortedItems, (item) => {
					sortedKeys.push(item[0]);
				});

				return sortedKeys;
			}
			// If the selected anchor isn't a valid option
			else {
				return Object.keys(coefficientData);
			}
		}

		return [];
	}

	// When the component is created, calculate the correlation values.
	useEffect(() => {
		getFullPearsonData();
	}, [growthCurveData, groundData, groundDataSelected, selectedAnchor]);

	// When the correlation values have been calculated, create the table.
	useEffect(() => {
		if (fullPearsonLoading == false) {
			let localHeaders = [<Table.HeaderCell key="top-left-blank-cell"></Table.HeaderCell>];
			let localDataRows = [];

			let sortedPearsonKeys = getSortedKeysListForAnchor(fullPearsonData, selectedAnchor);

			// For each item that we need to calculate correlation for...
			if (sortedPearsonKeys.length > 0) {
				// Against every other item that we need to calculate correlation for...
				_.forEach(sortedPearsonKeys, (key) => {
					// Create a header cell at the top of the table.
					localHeaders.push(
						<Table.HeaderCell key={key + "-header-cell"} title={key}>
							{displayNameMap[key] ?? key}
						</Table.HeaderCell>
					);

					// For each correlation value that needs displayed for this item...
					let data = _.map(sortedPearsonKeys, (spk) => {
						// Determine the color associated with the correlation value for this particular cell.
						let cellColor = CorrelationColorGradient(
							colors.negativeColor,
							colors.neutralColor,
							colors.positiveColor,
							-1,
							1,
							fullPearsonData[key][spk]
						);

						// Build a cell to display the correlation value.
						return (
							<Table.Cell
								key={spk + "-data-cell"}
								style={{
									backgroundColor: "rgb(" + cellColor.red + "," + cellColor.green + "," + cellColor.blue + ")"
								}}
							>
								{isNaN(fullPearsonData[key][spk]) ? "-" : parseFloat(fullPearsonData[key][spk].toFixed(3))}
							</Table.Cell>
						);
					});
					// For each row that will be shown in the table...
					localDataRows.push(
						// Create a header cell for the row and display the row data.
						<Table.Row key={key + "-data-row"}>
							<Table.HeaderCell title={key}>{displayNameMap[key] ?? key}</Table.HeaderCell>
							{data}
						</Table.Row>
					);
				});
			}

			// Update the state variables with the result of our modifications on these local arrays.
			setHeaders(localHeaders);
			setDataRows(localDataRows);
		}
	}, [fullPearsonData]);

	return (
		<Segment basic style={{ overflowX: "auto" }}>
			{fullPearsonLoading ? (
				<Loader active={true} />
			) : (
				<div style={{ border: "1px solid" }}>
					<TransformWrapper
						options={{
							disabled: false,
							limitToWrapper: false,
							limitToBounds: false,
							minScale: "0.2",
							centerContent: false
						}}
						defaultScale={1}
						defaultPositionX={0}
						defaultPositionY={0}
						wheel={{
							wheelEnabled: false
						}}
						style={{
							display: "block"
						}}
						zoomOut={{
							step: 5
						}}
						zoomIn={{
							step: 5
						}}
					>
						{({ zoomIn, zoomOut, resetTransform }) => (
							<React.Fragment>
								<div className="tools" style={{ float: "left" }}>
									<Button icon onClick={zoomIn}>
										<Icon name="plus" />
									</Button>
									<Button icon onClick={zoomOut}>
										<Icon name="minus" />
									</Button>
									<Button icon onClick={resetTransform}>
										<Icon name="repeat" />
									</Button>
								</div>
								<TransformComponent>
									<Table celled className="full-correlation-table">
										<Table.Header key="table-header">
											<Table.Row key="header-collection">{headers}</Table.Row>
										</Table.Header>
										<Table.Body key="table-body">{dataRows}</Table.Body>
									</Table>
								</TransformComponent>
							</React.Fragment>
						)}
					</TransformWrapper>
				</div>
			)}
		</Segment>
	);
};

FullCorrelationMatrix.propTypes = {
	growthCurveData: PropTypes.array,
	groundData: PropTypes.array,
	groundDataSelected: PropTypes.bool,
	selectedAnchor: PropTypes.string,
	colors: PropTypes.object
};

FullCorrelationMatrix.defaultProps = {
	growthCurveData: [],
	groundData: [],
	groundDataSelected: false,
	selectedAnchor: "",
	colors: {
		negativeColor: NEGATIVE_CORRELATION_COLOR,
		neutralColor: NEUTRAL_CORRELATION_COLOR,
		positiveColor: POSITIVE_CORRELATION_COLOR
	}
};

export default FullCorrelationMatrix;
