import React from "react";
import { render } from "react-dom";
import { Router, Route } from "react-router-dom";

import { Auth0Provider } from "./auth/auth0";

import { Provider as ReduxProvider } from "react-redux"; //-- This wil provide Redux store data to our React components.

import { createBrowserHistory } from "history";

import configureStore from "./redux/store/configureStore";

import App from "./components/App";

import { CookiesProvider } from "react-cookie";

import "react-widgets/styles.css"; //-- This is the css that is used for the DateTimePicker

export const reduxStore = configureStore();
const browserHistory = createBrowserHistory({
	basename: process.env.PUBLIC_PATH
});

render(
	<Auth0Provider
		domain={process.env.aerialPlotConnections.aerialPlotWeb.domain}
		client_id={process.env.aerialPlotConnections.aerialPlotWeb.clientId}
		redirect_uri={process.env.aerialPlotConnections.aerialPlotWeb.redirectUri}
		audience={process.env.aerialPlotConnections.aerialPlotWeb.apiAudience}
	>
		<ReduxProvider store={reduxStore}>
			<Router history={browserHistory}>
				<Route>
					<CookiesProvider>
						<App browserHistory={browserHistory} />
					</CookiesProvider>
				</Route>
			</Router>
		</ReduxProvider>
	</Auth0Provider>,
	document.getElementById("app")
);
