import { produce } from "immer";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import _ from "lodash";
import moment from "moment";
import * as uuid from "uuid";

import { toast } from "react-toastify";
import { DateTimePicker } from "react-widgets";
import {
	Button,
	Confirm,
	Divider,
	Form,
	Grid,
	Icon,
	Input,
	Loader,
	Message,
	Modal,
	Popup,
	Segment,
	Table,
	TextArea
} from "semantic-ui-react";

import "./style.css";

import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import { useUserSettings } from "../../../../hooks/useUserSettings";

import * as trialActions from "../../../../redux/actions/trialActions";
import * as editTrialAuth from "./editTrialAuth";
import { validateTreatments, validateTrial } from "./trialValidation";

import { Prompt, useHistory } from "react-router-dom";
import ConfrimNavModal from "../../../Lumber/ConfirmNavModal";
import TreatmentCSVUpload from "../../../Lumber/TreatmentCSVUpload";
import TrialFavoritingWidget from "../../Widgets/Favorites/TrialFavoritingWidget";

import RemoveTrialOwnerSponsorModal from "./RemoveTrialOwnerSponsorModal";

const EditTrial = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();

	const userSettings = useUserSettings();

	const DATE_FORMAT = "M/D/YYYY";

	//-- Dropdown options
	const [companyOptions, setCompanyOptions] = useState([]);
	const [treatmentTypeOptions, setTreatmentTypeOptions] = useState([]);
	const [clientOptions, setClientOptions] = useState([]);
	const [meanComparisonOptions, setMeanComparisonOptions] = useState([]);
	const [alphaOptions, setAlphaOptions] = useState([]);
	const [flightScheduleOptions, setFlightScheduleOptions] = useState([]);
	const [emailAddressOptions, setEmailAddressOptions] = useState([]);

	//-- Trial data
	const [originalTrial, setOriginalTrial] = useState(null);
	const [workingTrial, setWorkingTrialSilently] = useState({});
	const [trialName, setTrialName] = useState(null);
	const [trialOwnerEmailAddress, setTrialOwnerEmailAddress] = useState({});
	const [trialSponsorEmailAddress, setTrialSponsorEmailAddress] = useState({});

	//-- Validation
	const [trialIsValid, setTrialIsValid] = useState(true);
	const [trialValidation, setTrialValidation] = useState({});

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [modalLoading, setModalLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [showEditCancelConfirm, setShowEditCancelConfirm] = useState(false);
	const [resetKey, setResetKey] = useState(uuid.v4());
	const [showEmailModal, setShowEmailModal] = useState(false);
	const [emailModalType, setEmailModalType] = useState("");
	const [trialOwnerChangingTrialOwner, setTrialOwnerChangingTrialOwner] = useState(false);
	const [trialSponsorChangingTrialSponsor, setTrialSponsorChangingTrialSponsor] = useState(false);
	const [showSendEmailConfirm, setShowSendEmailConfirm] = useState(false);
	const [isTrialDirty, setIsTrialDirty] = useState(false);
	const [isTreatmentDirty, setIsTreatmentDirty] = useState(false);
	const [openConfirmNavModal, setOpenConfirmNavModal] = useState(false);
	const [target, setTarget] = useState("");
	//For Admin dropdown
	const [emailAddressForOwner, setEmailAddressForOwner] = useState(null);
	const [emailAddressForSponsor, setEmailAddressForSponsor] = useState(null);

	const [openRemoveTrialOwnerSponsorModal, setOpenRemoveTrialOwnerSponsorModal] = useState(false);
	const [removeTrialOwnerOrSponsor, setRemoveTrialOwnerOrSponsor] = useState("");

	const isDirty = useMemo(() => isTreatmentDirty || isTrialDirty, [isTreatmentDirty, isTrialDirty]);

	useEffect(() => {
		if (!isDirty && target !== "") {
			history.push(target);
		}
	}, [isDirty, target]);

	useEffect(() => {
		if (userAuth.currentClientId && moduleNavigation.trialId) {
			getTrialOptions(userAuth.currentClientId);
			getTrialData(userAuth.currentClientId);
		}
	}, [userAuth.currentClientId]);

	const setWorkingTrial = (newState, updateType) => {
		if (updateType === "treatment") {
			setIsTreatmentDirty(true);
		} else {
			setIsTrialDirty(true);
		}
		setWorkingTrialSilently(newState);
	};

	const handleNavigateOut = async (action) => {
		switch (action) {
			case "saveCont":
				await save(false);
				setIsTrialDirty(false);
				setIsTreatmentDirty(false);
				setOpenConfirmNavModal(false);
				break;
			case "save":
				await save(false);
				setTarget("");
				setOpenConfirmNavModal(false);
				setIsTrialDirty(false);
				setIsTreatmentDirty(false);
				break;
			case "discard":
				setIsTrialDirty(false);
				setIsTreatmentDirty(false);
				setOpenConfirmNavModal(false);
				break;
			default:
				setTarget("");
				setOpenConfirmNavModal(false);
		}
	};

	async function getTrialOptions(clientId) {
		const accessToken = await getTokenSilently();
		setLoading(true);
		dispatch(trialActions.getTrialOptions(clientId, moduleNavigation.trialId, accessToken))
			.then((res) => {
				initializeCompanyOptions(res.companies);
				initializeClientOptions(userAuth.user.userInfo.clientMemberships);
				initializeTreatmentTypeOptions(res.treatmentTypes);
				initializeMeanComparisons(res.meanComparisons);
				initializeAlphas(res.alphas);
				initializeFlightScheduleOptions(res.flightScheduleOptions);
				initializeEmailAddressOptions(res.emailAddresses);
			})
			.catch((err) => {
				console.log("Error loading trial options");
				console.log(err);
				setLoading(false);
			});
	}

	async function getTrialData(clientId) {
		const accessToken = await getTokenSilently();
		dispatch(trialActions.getTrialData(clientId, moduleNavigation.trialId, accessToken))
			.then((res) => {
				setTrialData(res);
				setLoading(false);
			})
			.catch((err) => {
				console.log("Error loading trial data");
				console.log(err);
				setLoading(false);
			});
	}

	const initializeCompanyOptions = (companies) => {
		const companyOpts = _.map(companies, (c) => {
			const opt = {
				key: c.id,
				value: c.id,
				text: c.name
			};
			return opt;
		});
		let filteredCompanyOpts = _.filter(companyOpts, (c) => {
			return c.key;
		});
		setCompanyOptions(filteredCompanyOpts);
	};

	const initializeClientOptions = (clients) => {
		const clientOpts = _.map(clients, (c) => {
			const opt = {
				key: c.clientId,
				value: c.clientId,
				text: c.clientName
			};
			return opt;
		});
		setClientOptions(clientOpts);
	};

	const initializeTreatmentTypeOptions = (treatmentTypes) => {
		const treatmentTypeOpts = _.map(treatmentTypes, (tt) => {
			const opt = {
				key: tt.id,
				value: tt.id,
				text: tt.name
			};
			return opt;
		});
		setTreatmentTypeOptions(treatmentTypeOpts);
	};

	function initializeMeanComparisons(meanComparisons) {
		const meanComparisonOpts = _.map(meanComparisons, (mc) => {
			const opt = {
				key: mc.id,
				value: mc.id,
				text: mc.name
			};
			return opt;
		});
		setMeanComparisonOptions(meanComparisonOpts);
	}

	function initializeAlphas(alphas) {
		alphas = _.sortBy(alphas, "value");
		const alphaOpts = _.map(alphas, (a) => {
			const opt = {
				key: a.id,
				value: a.id,
				text: a.value
			};
			return opt;
		});
		setAlphaOptions(alphaOpts);
	}

	const initializeFlightScheduleOptions = (flightScheduleData) => {
		let flightScheduleOpts = _.map(flightScheduleData, (fs) => {
			const opt = {
				key: fs.id,
				value: fs.id,
				text: fs.name
			};
			return opt;
		});
		let filteredFlightScheduleOpts = _.filter(flightScheduleOpts, (c) => {
			return c.key;
		});
		filteredFlightScheduleOpts = _.orderBy(filteredFlightScheduleOpts, ["text"]);
		setFlightScheduleOptions(filteredFlightScheduleOpts);
	};

	const initializeEmailAddressOptions = (emailAddressData) => {
		let emailAddressOpts = _.map(emailAddressData, (ea) => {
			const opt = {
				key: ea.emailAddress,
				value: ea.emailAddress,
				text: ea.emailAddress
			};
			return opt;
		});
		let filteredEmailAddressOpts = _.filter(emailAddressOpts, (c) => {
			return c.key;
		});
		filteredEmailAddressOpts = _.orderBy(filteredEmailAddressOpts, (filteredEmailAddressOpts) =>
			filteredEmailAddressOpts.text.toLowerCase()
		);
		setEmailAddressOptions(filteredEmailAddressOpts);
	};

	const setTrialData = (trialData) => {
		const ot = {
			clientId: trialData.trialInfo.clientId,
			id: trialData.trialInfo.trialId,
			name: trialData.trialInfo.trialName,
			company: trialData.trialInfo.companyName,
			trialOwnerPersonId: trialData.trialInfo.trialOwnerPersonId,
			trialOwner: trialData.trialInfo.trialOwnerPersonId
				? `${trialData.trialInfo.trialOwnerFirstName} ${trialData.trialInfo.trialOwnerLastName}`
				: "Not assigned",
			trialSponsor: trialData.trialInfo.trialSponsorPersonId
				? `${trialData.trialInfo.trialSponsorFirstName} ${trialData.trialInfo.trialSponsorLastName}`
				: "Not assigned",
			fieldResearcher: trialData.trialInfo.fieldResearcher,
			fieldResearcherId: trialData.trialInfo.fieldResearcherId,
			location: trialData.trialInfo.farmName,
			gpsCoordinates:
				trialData.trialInfo.plot101Lat && trialData.trialInfo.plot101Long
					? `${trialData.trialInfo.plot101Lat}, ${trialData.trialInfo.plot101Long}`
					: ``,
			plantDate: trialData.trialInfo.plantDate ? new Date(trialData.trialInfo.plantDate) : null,
			harvestDate: trialData.trialInfo.harvestDate ? new Date(trialData.trialInfo.harvestDate) : null,
			treatments: trialData.treatments,
			repCount: trialData.trialInfo.repCount,
			notes: trialData.trialInfo.notes ? trialData.trialInfo.notes : "",
			trialOwnerClientId: trialData.trialInfo.trialOwnerClientId,
			trialOwnerCompanyId: trialData.trialInfo.trialOwnerCompanyId,
			trialOwnerUserId: trialData.trialInfo.trialOwnerUserId,
			trialSponsorClientId: trialData.trialInfo.trialSponsorClientId,
			trialSponsorCompanyId: trialData.trialInfo.trialSponsorCompanyId,
			trialSponsorUserId: trialData.trialInfo.trialSponsorUserId,
			publishedToTrialOwner: trialData.trialInfo.publishedToTrialOwner,
			publishedToTrialSponsor: trialData.trialInfo.publishedToTrialSponsor,
			trialOwnerIsPending: trialData.trialInfo.trialOwnerIsPending,
			trialSponsorIsPending: trialData.trialInfo.trialSponsorIsPending,
			trialOwnerClientName: trialData.trialInfo.trialOwnerClientName,
			trialSponsorClientName: trialData.trialInfo.trialSponsorClientName,
			meanComparisonId: trialData.trialInfo.meanComparisonId,
			alphaId: trialData.trialInfo.alphaId,
			isEstimatedPlantDate: trialData.trialInfo.isEstimatedPlantDate,
			cooperatorName: trialData.trialInfo.cooperatorName,
			city: trialData.trialInfo.farmCity,
			state: trialData.trialInfo.farmState,
			flightScheduleId: trialData.trialInfo.flightScheduleId
		};

		setTrialOwnerEmailAddress(trialData.trialInfo.trialOwnerEmailAddress);
		setTrialSponsorEmailAddress(trialData.trialInfo.trialSponsorEmailAddress);

		setEmailAddressForOwner(trialData.trialInfo.trialOwnerEmailAddress);
		setEmailAddressForSponsor(trialData.trialInfo.trialSponsorEmailAddress);

		setOriginalTrial(ot);
		setTrialName(ot.name);
		setWorkingTrialSilently(ot);
	};

	const undoChanges = () => {
		setResetKey(uuid.v4());
		setWorkingTrialSilently(originalTrial);
		setIsTreatmentDirty(false);
		setIsTrialDirty(false);
		setTrialIsValid(true);
		setTrialValidation({});
	};

	const save = async () => {
		const trialValidation = validateTrial(workingTrial);
		setTrialIsValid(trialValidation.isValid);
		setTrialValidation(trialValidation);

		if (!trialValidation.isValid) {
			window.scrollTo(0, 0);
			return;
		}

		const trialUpdateObj = getUpdateObject();

		setIsSaving(true);

		const accessToken = await getTokenSilently();
		dispatch(trialActions.updateTrial(trialUpdateObj, accessToken))
			.then((res) => {
				if (res.statusCode === 200) {
					toast.success("Trial saved successfully");
					setOriginalTrial({ ...originalTrial, trialName: trialUpdateObj.trialName });
					setTrialName(trialUpdateObj.trialName);
				} else if (res.statusCode === 400) {
					if (res.messages && res.messages.length > 0) {
						res.messages.forEach((m) => {
							if (m.type === "Informational") {
								toast.info(m.text);
							} else if (m.type === "FailedValidation") {
								toast.error(m.text);
							}
						});
					}
				}

				setIsSaving(false);
				setIsTrialDirty(false);
				setIsTreatmentDirty(false);
			})
			.catch((error) => {
				setIsSaving(false);
				setIsTrialDirty(false);
				setIsTreatmentDirty(false);
				if (error && error.errors) {
					error.errors.forEach((e) => toast.error(e));
				} else {
					toast.error("Trial failed to update");
				}
			});
	};

	const saveTreatments = async (treatments) => {
		const treatmentValidation = validateTreatments(treatments);
		const accessToken = await getTokenSilently();
		setTrialIsValid(treatmentValidation.isValid);
		setTrialValidation(treatmentValidation);

		if (!treatmentValidation.isValid) {
			window.scrollTo(0, 0);
			return;
		}

		setIsSaving(true);

		try {
			const res = await dispatch(trialActions.updateTrialTreatments(userAuth.currentClientId, treatments, accessToken));

			if (res.statusCode === 200) {
				toast.success("Treatments saved successfully");
			} else if (res.statusCode === 400) {
				if (res.messages && res.messages.length > 0) {
					res.messages.forEach((m) => {
						if (m.type === "Informational") {
							toast.info(m.text);
						} else if (m.type === "FailedValidation") {
							toast.error(m.text);
						}
					});
				}
			}
		} catch (error) {
			if (error && error.errors) {
				error.errors.forEach((e) => toast.error(e));
			} else {
				toast.error("Trial failed to update");
			}
		} finally {
			setIsSaving(false);
			setIsTreatmentDirty(false);
		}
	};

	const getUpdateObject = () => {
		const trialUpdateObj = {
			clientId: userAuth.currentClientId,
			trialId: moduleNavigation.trialId,
			trialName: editTrialAuth.userCanEditTrialName(userAuth) ? workingTrial.name : originalTrial.name,
			trialOwnerCompanyId: workingTrial.trialOwnerCompanyId,
			trialOwnerClientId: workingTrial.trialOwnerClientId,
			trialSponsorCompanyId: workingTrial.trialSponsorCompanyId,
			trialSponsorClientId: workingTrial.trialSponsorClientId,
			plantDate: editTrialAuth.userCanEditPlantDate(userAuth, workingTrial.clientId)
				? workingTrial.plantDate
				: originalTrial.plantDate,
			harvestDate: editTrialAuth.userCanEditHarvestDate(userAuth, workingTrial.clientId)
				? workingTrial.harvestDate
				: originalTrial.harvestDate,
			treatments: editTrialAuth.userCanEditTreatments(userAuth) ? workingTrial.treatments : originalTrial.treatments,
			notes: editTrialAuth.userCanEditNotes(userAuth) ? workingTrial.notes : originalTrial.notes,
			meanComparisonId: workingTrial.meanComparisonId,
			alphaId: workingTrial.alphaId,
			isEstimatedPlantDate: workingTrial.isEstimatedPlantDate,
			flightScheduleId: workingTrial.flightScheduleId
		};

		return trialUpdateObj;
	};

	const handleSaveTrialOwnerEmailAddress = async () => {
		setModalLoading(true);
		if (
			(userAuth.isApAdmin && _.isEmpty(emailAddressForOwner)) ||
			(!userAuth.isApAdmin && _.isEmpty(trialOwnerEmailAddress))
		) {
			toast.error("Please enter a valid email address");
			setModalLoading(false);
			return;
		}
		const accessToken = await getTokenSilently();
		dispatch(
			trialActions.setTrialOwnerEmailAddress(
				userAuth.isApAdmin ? emailAddressForOwner : trialOwnerEmailAddress,
				workingTrial.id,
				userAuth.currentClientId,
				workingTrial.trialOwnerClientId,
				trialOwnerChangingTrialOwner,
				workingTrial.publishedToTrialOwner,
				workingTrial.fieldResearcherId,
				workingTrial.trialSponsorUserId,
				accessToken
			)
		).then((res) => {
			if (res.errors && res.errors.length > 0) {
				toast.error(res.errors[0]);

				if (res.errors[0].includes("A user does not exist")) {
					setShowSendEmailConfirm(true);
				}
			} else {
				const newWorkingTrial = produce(workingTrial, (draft) => {
					draft.trialOwnerUserId = res.userId;
					draft.trialOwner = `${res.firstName} ${res.lastName}`;
					draft.trialOwnerClientId = res.singleClientId ?? null;
					draft.trialOwnerClientName = res.singleClientName ?? null;
					draft.trialOwnerIsPending = res.isPending;
				});
				setWorkingTrialSilently(newWorkingTrial);

				toast.success(res.message);
				setShowEmailModal(false);
			}

			setModalLoading(false);
		});
	};

	const handleSaveTrialSponsorEmailAddress = async () => {
		setModalLoading(true);
		if (
			(userAuth.isApAdmin && _.isEmpty(emailAddressForSponsor)) ||
			(!userAuth.isApAdmin && _.isEmpty(trialSponsorEmailAddress))
		) {
			toast.error("Please enter a valid email address");
			setModalLoading(false);
			return;
		}
		const accessToken = await getTokenSilently();
		dispatch(
			trialActions.setTrialSponsorEmailAddress(
				userAuth.isApAdmin ? emailAddressForSponsor : trialSponsorEmailAddress,
				workingTrial.id,
				userAuth.currentClientId,
				workingTrial.trialSponsorClientId,
				trialSponsorChangingTrialSponsor,
				workingTrial.publishedToTrialSponsor,
				workingTrial.fieldResearcherId,
				workingTrial.trialOwnerUserId,
				accessToken
			)
		).then((res) => {
			if (res.errors && res.errors.length > 0) {
				toast.error(res.errors[0]);

				if (res.errors[0].includes("A user does not exist")) {
					setShowSendEmailConfirm(true);
				}
			} else {
				const newWorkingTrial = produce(workingTrial, (draft) => {
					draft.trialSponsorUserId = res.userId;
					draft.trialSponsor = `${res.firstName} ${res.lastName}`;
					draft.trialSponsorClientId = res.singleClientId ?? null;
					draft.trialSponsorClientName = res.singleClientName ?? null;
					draft.trialSponsorIsPending = res.isPending;
				});
				setWorkingTrialSilently(newWorkingTrial);

				toast.success(res.message);
				setShowEmailModal(false);
			}

			setModalLoading(false);
		});
	};

	const sendNewUserEmail = async (emailAddress) => {
		// Same splitting logic as below
		let clientName = workingTrial.fieldResearcher;
		if (
			(userAuth.currentClientId === workingTrial.trialOwnerClientId ||
				userAuth.user.userInfo.basic.userId === workingTrial.trialOwnerUserId) &&
			workingTrial.publishedToTrialOwner
		) {
			clientName = workingTrial.trialOwnerClientName ? workingTrial.trialOwnerClientName : userAuth.currentClient;
		} else if (
			(userAuth.currentClientId === workingTrial.trialSponsorClientId ||
				userAuth.user.userInfo.basic.userId === workingTrial.trialSponsorUserId) &&
			workingTrial.publishedToTrialSponsor
		) {
			clientName = workingTrial.trialSponsorClientName ? workingTrial.trialSponsorClientName : userAuth.currentClient;
		}

		const accessToken = await getTokenSilently();
		dispatch(trialActions.sendNewUserEmail(emailAddress, clientName, userAuth.currentClientId, accessToken))
			.then(() => {
				toast.success("Email was sent to that address");
				setShowSendEmailConfirm(false);
				setShowEmailModal(false);
			})
			.catch((error) => {
				if (error && error.errors) {
					error.errors.forEach((e) => toast.error(e));
				}
				setShowSendEmailConfirm(false);
			});
	};

	function setPlantDate(date) {
		if (date) {
			date.setHours(0, 0, 0, 0);
			const newWorkingTrial = produce(workingTrial, (draft) => {
				draft.plantDate = date;
			});
			setWorkingTrial(newWorkingTrial);
		} else {
			const newWorkingTrial = produce(workingTrial, (draft) => {
				draft.plantDate = null;
			});
			setWorkingTrial(newWorkingTrial);
		}
	}

	function setHarvestDate(date) {
		if (date) {
			date.setHours(0, 0, 0, 0);
			const newWorkingTrial = produce(workingTrial, (draft) => {
				draft.harvestDate = date;
			});
			setWorkingTrial(newWorkingTrial);
		} else {
			const newWorkingTrial = produce(workingTrial, (draft) => {
				draft.harvestDate = null;
			});
			setWorkingTrial(newWorkingTrial);
		}
	}

	function saveTreatmentsFromValidCSV(results) {
		const tmnts = results.map((r, idx) => {
			const treatment = workingTrial.treatments.find((t) => t.trialTreatmentId === idx + 1);
			treatment.treatmentName = r.name;
			const typeId = r.type
				? treatmentTypeOptions.find((tto) => tto.text.toUpperCase() === r.type.toUpperCase())?.value
				: null;
			treatment.treatmentTypeId = typeId;
			treatment.treatmentDescription = r.description;
			return treatment;
		});

		setWorkingTrial(
			{
				...workingTrial,
				treatments: tmnts
			},
			"treatment"
		);

		saveTreatments(tmnts);
	}

	function clearTreatments() {
		const newWorkingTrial = produce(workingTrial, (draft) => {
			draft.treatments = draft.treatments.map((_r, idx) => {
				const treatment = draft.treatments.find((t) => t.trialTreatmentId === idx + 1);
				treatment.treatmentName = null;
				treatment.treatmentTypeId = null;
				treatment.treatmentDescription = null;
				return treatment;
			});
		});
		setWorkingTrial(newWorkingTrial, "treatment");
	}

	return loading ? (
		<Loader active />
	) : !loading && !originalTrial ? (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2>Edit Trial</h2>
			<hr />
			<Segment>
				<p>{"We could not find this trial."}</p>
			</Segment>
		</Segment>
	) : (
		<>
			<Segment style={{ marginLeft: 50, marginTop: 15 }}>
				<h2 style={{ float: "left" }}>Edit Trial</h2>
				<div style={{ float: "right" }}>
					<TrialFavoritingWidget
						style={{ display: "inline" }}
						clientId={userAuth.currentClientId.toUpperCase()}
						trialId={moduleNavigation.trialId.toUpperCase()}
						userSettings={userSettings}
					></TrialFavoritingWidget>
					<h2 style={{ display: "inline", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
						<i>{`${trialName} (${originalTrial.cooperatorName}, ${originalTrial.city}, ${originalTrial.state})`} </i>
					</h2>
				</div>
				<hr style={{ clear: "both" }} />
				<Form error={!trialIsValid}>
					<Grid verticalAlign="middle">
						{!trialIsValid && (
							<Grid.Row>
								<Form.Field width="16" style={{ marginBottom: 10 }}>
									<Message error list={trialValidation.messages} />
								</Form.Field>
							</Grid.Row>
						)}
						<Grid.Row>
							<Grid.Column width="2" textAlign="right">
								<Form.Field>
									<Form.Field>
										<label htmlFor="form-input-trial-name">Trial Name</label>
									</Form.Field>
								</Form.Field>
							</Grid.Column>
							<Grid.Column width="5">
								<Form.Input
									key={resetKey}
									id="form-input-trial-name"
									defaultValue={workingTrial.name}
									onBlur={(event) => {
										const newWorkingTrial = produce(workingTrial, (draft) => {
											draft.name = event.target.value;
										});
										setWorkingTrial(newWorkingTrial);
									}}
									error={trialValidation.nameError}
									readOnly={!editTrialAuth.userCanEditTrialName(userAuth)}
								/>
							</Grid.Column>
							<Grid.Column width="2" textAlign="right">
								<Form.Field>
									<label htmlFor="form-input-field-researcher">Field Researcher</label>
								</Form.Field>
							</Grid.Column>
							<Grid.Column width="5">
								<Form.Input id="form-input-field-researcher" value={workingTrial.fieldResearcher ?? ""} readOnly />
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							{/* If user is in client that trial is published to or user is the trial owner */}
							{(userAuth.currentClientId === workingTrial.trialOwnerClientId ||
								userAuth.user.userInfo.basic.userId === workingTrial.trialOwnerUserId) &&
							workingTrial.publishedToTrialOwner ? (
								<>
									<Grid.Column width="2" textAlign="right">
										<Form.Field>
											<label htmlFor="form-input-client">Trial Owner Org</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="5">
										{userAuth.user.userInfo.basic.userId !== workingTrial.trialOwnerUserId && !userAuth.isApAdmin ? (
											<Form.Input value={workingTrial.trialOwnerClientName} readOnly />
										) : (
											<Form.Select
												id="form-select-client"
												search
												options={clientOptions}
												value={workingTrial.trialOwnerClientId}
												onChange={(event, { value }) => {
													const newWorkingTrial = produce(workingTrial, (draft) => {
														draft.trialOwnerClientId = value;
													});
													setWorkingTrial(newWorkingTrial);
												}}
												loading={!clientOptions || clientOptions.length === 0}
												error={trialValidation.clientError}
												readOnly={
													userAuth.user.userInfo.basic.userId !== workingTrial.trialOwnerUserId && !userAuth.isApAdmin
												}
											/>
										)}
									</Grid.Column>
								</>
							) : (userAuth.currentClientId === workingTrial.trialSponsorClientId ||
									userAuth.user.userInfo.basic.userId === workingTrial.trialSponsorUserId) &&
							  workingTrial.publishedToTrialSponsor ? (
								<>
									{/* Above but for trial sponsor */}
									<Grid.Column width="2" textAlign="right">
										<Form.Field>
											<label htmlFor="form-select-client">Trial Sponsor Org</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="5">
										{userAuth.user.userInfo.basic.userId !== workingTrial.trialSponsorUserId && !userAuth.isApAdmin ? (
											<Form.Input value={workingTrial.trialSponsorClientName} readOnly />
										) : (
											<Form.Select
												id="form-select-client"
												search
												options={clientOptions}
												value={workingTrial.trialSponsorClientId}
												onChange={(event, { value }) => {
													const newWorkingTrial = produce(workingTrial, (draft) => {
														draft.trialSponsorClientId = value;
													});
													setWorkingTrial(newWorkingTrial);
												}}
												loading={!clientOptions || clientOptions.length === 0}
												error={trialValidation.trialSponsorClientId}
											/>
										)}
									</Grid.Column>
								</>
							) : (
								userAuth.currentClientId === workingTrial.clientId && (
									<>
										{/* If user is in the client that published trial */}
										<Grid.Column width="2" textAlign="right">
											<Form.Field>
												<label htmlFor="form-select-company">Company</label>
											</Form.Field>
										</Grid.Column>
										<Grid.Column width="5">
											<Form.Select
												id="form-select-company"
												search
												options={companyOptions}
												value={workingTrial.trialOwnerCompanyId}
												onChange={(event, { value }) => {
													const newWorkingTrial = produce(workingTrial, (draft) => {
														draft.trialOwnerCompanyId = value;
													});
													setWorkingTrial(newWorkingTrial);
												}}
												loading={loading && (!companyOptions || companyOptions.length === 0)}
												error={trialValidation.companyError}
											/>
										</Grid.Column>
									</>
								)
							)}
							<Grid.Column width="2" textAlign="right">
								<Form.Field>
									<label htmlFor="form-input-location">Location</label>
								</Form.Field>
							</Grid.Column>
							<Grid.Column width="5">
								<Form.Input id="form-input-location" value={workingTrial.location ?? ""} readOnly />
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							{(userAuth.currentClientId === workingTrial.trialOwnerClientId ||
								userAuth.user.userInfo.basic.userId === workingTrial.trialOwnerUserId) &&
							workingTrial.publishedToTrialOwner ? (
								<>
									<Grid.Column width="2" textAlign="right">
										<Form.Field>
											<label htmlFor="form-select-trial-owner">
												Trial Owner{" "}
												{workingTrial.trialOwnerIsPending && (
													<Popup
														trigger={<Icon name="info circle" />}
														content="This user does not have the correct license to view this trial."
													/>
												)}
											</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="5" style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
										<Input
											style={{ flexGrow: 1 }}
											fluid
											value={`${workingTrial.trialOwner} ${workingTrial.trialOwnerIsPending ? "(Pending)" : ""}`}
											readOnly
										/>
										<Button
											primary
											compact
											content="Change"
											onClick={() => {
												setTrialOwnerChangingTrialOwner(true);
												setEmailModalType("Owner");
												setShowEmailModal(true);
											}}
										/>
										<Button
											negative
											compact
											content="Remove"
											onClick={() => {
												if (workingTrial.trialSponsorUserId) {
													toast.error("Cannot remove trial owner when trial sponsor is set");
												} else {
													setOpenRemoveTrialOwnerSponsorModal(true);
													setRemoveTrialOwnerOrSponsor("Owner");
												}
											}}
										/>
									</Grid.Column>
								</>
							) : (userAuth.currentClientId === workingTrial.trialSponsorClientId ||
									userAuth.user.userInfo.basic.userId === workingTrial.trialSponsorUserId) &&
							  workingTrial.publishedToTrialSponsor ? (
								<>
									<Grid.Column width="2" textAlign="right">
										<Form.Field>
											<label htmlFor="form-select-trial-owner">
												Trial Sponsor{" "}
												{workingTrial.trialSponsorIsPending && (
													<Popup
														trigger={<Icon name="info circle" />}
														content="This user does not have the correct license to view this trial."
													/>
												)}
											</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="5" style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
										<Input
											fluid
											style={{ flexGrow: 1 }}
											value={`${workingTrial.trialSponsor} ${workingTrial.trialSponsorIsPending ? "(Pending)" : ""}`}
											readOnly
										/>
										<Button
											primary
											compact
											content="Change"
											onClick={() => {
												setTrialSponsorChangingTrialSponsor(true);
												setEmailModalType("Sponsor");
												setShowEmailModal(true);
											}}
										/>
										<Button
											negative
											compact
											content="Remove"
											onClick={() => {
												setOpenRemoveTrialOwnerSponsorModal(true);
												setRemoveTrialOwnerOrSponsor("Sponsor");
											}}
										/>
									</Grid.Column>
								</>
							) : (
								userAuth.currentClientId === workingTrial.clientId && (
									<>
										<Grid.Column width="2" textAlign="right">
											<Form.Field>
												<label htmlFor="form-select-trial-owner">
													Trial Owner{" "}
													{workingTrial.trialOwnerIsPending && (
														<Popup
															trigger={<Icon name="info circle" />}
															content="This user does not have the correct license to view this trial."
														/>
													)}
												</label>
											</Form.Field>
										</Grid.Column>
										{workingTrial.trialOwnerUserId ? (
											<>
												<Grid.Column width="5" style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
													<Input
														style={{ flexGrow: 1 }}
														fluid
														value={`${workingTrial.trialOwner} ${workingTrial.trialOwnerIsPending ? " (Pending)" : ""}`}
														readOnly
													/>
													<Button
														primary
														compact
														content="Change"
														onClick={() => {
															setTrialOwnerChangingTrialOwner(false);
															setEmailModalType("Owner");
															setShowEmailModal(true);
														}}
													/>
													<Button
														negative
														compact
														content="Remove"
														onClick={() => {
															if (workingTrial.trialSponsorUserId) {
																toast.error("Cannot remove trial owner when trial sponsor is set");
															} else {
																setOpenRemoveTrialOwnerSponsorModal(true);
																setRemoveTrialOwnerOrSponsor("Owner");
															}
														}}
													/>
												</Grid.Column>
											</>
										) : (
											<>
												<Grid.Column width="2">
													<Button
														primary
														fluid
														content="Assign Trial Owner"
														onClick={() => {
															setTrialOwnerChangingTrialOwner(false);
															setEmailModalType("Owner");
															setShowEmailModal(true);
														}}
													/>
												</Grid.Column>
												<Grid.Column width="3" />
											</>
										)}
									</>
								)
							)}
							<Grid.Column width="2" textAlign="right">
								<Form.Field>
									<label htmlFor="form-input-gps-coordinates" style={{ margin: "unset" }}>
										GPS Coordinates
									</label>
									<label style={{ margin: "unset" }}>(Plot 101)</label>
								</Form.Field>
							</Grid.Column>
							<Grid.Column width="5">
								<Form.Input id="form-input-gps-coordinates" value={workingTrial.gpsCoordinates ?? ""} readOnly />
							</Grid.Column>
						</Grid.Row>
						{(userAuth.currentClientId === workingTrial.trialOwnerClientId ||
							userAuth.user.userInfo.basic.userId === workingTrial.trialOwnerUserId) &&
						workingTrial.publishedToTrialOwner ? (
							<>
								<Grid.Row>
									<Grid.Column width="2" textAlign="right">
										<Form.Field>
											<label htmlFor="select-trial-sponsor-company">Sponsor Company</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="5">
										<Form.Select
											id="select-trial-sponsor-company"
											search
											options={companyOptions}
											value={workingTrial.trialSponsorCompanyId}
											onChange={(event, { value }) => {
												const newWorkingTrial = produce(workingTrial, (draft) => {
													draft.trialSponsorCompanyId = value;
												});
												setWorkingTrial(newWorkingTrial);
											}}
											loading={loading && (!companyOptions || companyOptions.length === 0)}
											error={trialValidation.companyError}
										/>
									</Grid.Column>
									<Grid.Column width="2" textAlign="right">
										<Form.Field>
											<label htmlFor="form-input-treatments">Treatment Count</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="2" textAlign="right">
										<Form.Input id="form-input-treatments" value={workingTrial.treatments?.length ?? ""} readOnly />
									</Grid.Column>
									<Grid.Column width="1" textAlign="right">
										<Form.Field>
											<label htmlFor="form-input-reps">Rep Count</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="2" textAlign="right">
										<Form.Input id="form-input-reps" value={workingTrial.repCount ?? ""} readOnly />
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width="2" textAlign="right">
										<Form.Field>
											<label htmlFor="form-select-trial-owner">
												Trial Sponsor{" "}
												{workingTrial.trialSponsorIsPending && (
													<Popup
														trigger={<Icon name="info circle" />}
														content="This user does not have the correct license to view this trial."
													/>
												)}
											</label>
										</Form.Field>
									</Grid.Column>
									{workingTrial.trialSponsorUserId ? (
										<>
											<Grid.Column width="5" style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
												<Input
													style={{ flexGrow: 1 }}
													fluid
													value={`${workingTrial.trialSponsor} ${
														workingTrial.trialSponsorIsPending ? "(Pending)" : ""
													}`}
													readOnly
												/>
												<Button
													primary
													compact
													content="Change"
													onClick={() => {
														setTrialSponsorChangingTrialSponsor(false);
														setEmailModalType("Sponsor");
														setShowEmailModal(true);
													}}
												/>
												<Button
													negative
													compact
													content="Remove"
													onClick={() => {
														setOpenRemoveTrialOwnerSponsorModal(true);
														setRemoveTrialOwnerOrSponsor("Sponsor");
													}}
												/>
											</Grid.Column>
										</>
									) : (
										<>
											<Grid.Column width="2">
												<Button
													primary
													fluid
													content="Assign Trial Sponsor"
													onClick={() => {
														setTrialSponsorChangingTrialSponsor(false);
														setEmailModalType("Sponsor");
														setShowEmailModal(true);
													}}
												/>
											</Grid.Column>
											<Grid.Column width="3" />
										</>
									)}
									<Grid.Column width="2" textAlign="right">
										<Form.Field>
											<label htmlFor="form-dateinput-plant-date">Plant Date</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column
										width="5"
										style={{
											gap: "7.5px",
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "flex-end"
										}}
									>
										{editTrialAuth.userCanEditPlantDate(userAuth, workingTrial.clientId) ? (
											<div style={{ flexGrow: 1 }}>
												<Form.Field
													id="form-date-time-picker-plant-date"
													control={DateTimePicker}
													includeTime={false}
													value={workingTrial.plantDate}
													onChange={(date) => {
														setPlantDate(date);
													}}
													error={trialValidation.plantDate}
												/>
											</div>
										) : (
											<Form.Input
												id="form-input-plant-date"
												value={workingTrial.plantDate ? moment(workingTrial.plantDate).local().format(DATE_FORMAT) : ""}
												readOnly
											/>
										)}
										<Form.Field>
											<label htmlFor="form-is-estimated-plant-date" style={{ marginLeft: "10px", textAlign: "right" }}>
												Is Estimated Plant Date
											</label>
										</Form.Field>
										<Form.Checkbox
											id="form-is-estimate-plant-date"
											readOnly={!editTrialAuth.userCanEditPlantDate(userAuth, workingTrial.clientId)}
											checked={workingTrial.isEstimatedPlantDate}
											onChange={(e, { checked }) => {
												const newWorkingTrial = produce(workingTrial, (draft) => {
													draft.isEstimatedPlantDate = checked;
												});
												setWorkingTrial(newWorkingTrial);
											}}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width="2" textAlign="right">
										<Form.Field>
											<label htmlFor="input-trial-sponsor-org">Trial Sponsor Org</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="5" textAlign="right">
										<Input
											fluid
											id="input-trial-sponsor-org"
											value={workingTrial.trialSponsorClientName ? workingTrial.trialSponsorClientName : "Not Assigned"}
											readOnly
										/>
									</Grid.Column>
									<Grid.Column width="2" textAlign="right">
										<Form.Field>
											<label htmlFor="form-dateinput-harvest-date">Harvest Date</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="5">
										{editTrialAuth.userCanEditHarvestDate(userAuth, workingTrial.clientId) ? (
											<Form.Field
												id="form-date-time-picker-harvest-date"
												control={DateTimePicker}
												includeTime={false}
												value={workingTrial.harvestDate}
												onChange={(date) => {
													setHarvestDate(date);
												}}
												error={trialValidation.harvestDate}
											/>
										) : (
											<Form.Input
												id="form-input-harvest-date"
												value={
													workingTrial.harvestDate ? moment(workingTrial.harvestDate).local().format(DATE_FORMAT) : ""
												}
												readOnly
											/>
										)}
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width="2" textAlign="right">
										<Form.Field>
											<label htmlFor="form-select-mean-comparison">Mean Comparison</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="2">
										<Form.Select
											id="form-select-mean-comparison"
											search
											style={{ minWidth: "unset" }}
											options={meanComparisonOptions}
											value={workingTrial.meanComparisonId}
											onChange={(event, { value }) => {
												const newWorkingTrial = produce(workingTrial, (draft) => {
													draft.meanComparisonId = value;
												});
												setWorkingTrial(newWorkingTrial);
											}}
											loading={!meanComparisonOptions || meanComparisonOptions.length === 0}
										/>
									</Grid.Column>
									<Grid.Column width="1" textAlign="right">
										<Form.Field>
											<label htmlFor="form-select-alpha">Alpha</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="2">
										<Form.Select
											id="form-select-alpha"
											search
											style={{ minWidth: "unset" }}
											options={alphaOptions}
											value={workingTrial.alphaId}
											onChange={(event, { value }) => {
												const newWorkingTrial = produce(workingTrial, (draft) => {
													draft.alphaId = value;
												});
												setWorkingTrial(newWorkingTrial);
											}}
											loading={!alphaOptions || alphaOptions.length === 0}
										/>
									</Grid.Column>
									<Grid.Column width="2" textAlign="right">
										<Form.Field>
											<label htmlFor="form-select-mean-comparison">Flight Schedule</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="5">
										<Form.Select
											id="form-select-flight-schedule"
											search
											style={{ minWidth: "unset" }}
											options={flightScheduleOptions}
											value={workingTrial.flightScheduleId}
											onChange={(event, { value }) => {
												const newWorkingTrial = produce(workingTrial, (draft) => {
													draft.flightScheduleId = value;
												});
												setWorkingTrial(newWorkingTrial);
											}}
											loading={!flightScheduleOptions || flightScheduleOptions.length === 0}
										/>
									</Grid.Column>
								</Grid.Row>
							</>
						) : (userAuth.currentClientId === workingTrial.trialSponsorClientId ||
								userAuth.user.userInfo.basic.userId === workingTrial.trialSponsorUserId) &&
						  workingTrial.publishedToTrialSponsor ? (
							<>
								<Grid.Row>
									<Grid.Column width="2" textAlign="right">
										<Form.Field>
											<label htmlFor="form-trial-owner-org">Trial Owner Org</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="5" textAlign="right">
										<Form.Input
											id="form-trial-owner-org"
											fluid
											value={workingTrial.trialOwnerClientName ? workingTrial.trialOwnerClientName : "Not Assigned"}
											readOnly
										/>
									</Grid.Column>
									<Grid.Column width="2" textAlign="right">
										<Form.Field>
											<label htmlFor="form-input-treatments">Treatment Count</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="2" textAlign="right">
										<Form.Input id="form-input-treatments" value={workingTrial.treatments?.length ?? ""} readOnly />
									</Grid.Column>
									<Grid.Column width="1" textAlign="right">
										<Form.Field>
											<label htmlFor="form-input-reps">Rep Count</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="2" textAlign="right">
										<Form.Input id="form-input-reps" value={workingTrial.repCount ?? ""} readOnly />
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width="2" textAlign="right">
										<Form.Field>
											<label htmlFor="form-select-trial-owner">
												Trial Owner{" "}
												{workingTrial.trialOwnerIsPending && (
													<Popup
														trigger={<Icon name="info circle" />}
														content="This user does not have the correct license to view this trial."
													/>
												)}
											</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="5" textAlign="right">
										<Form.Input
											id="form-trial-owner"
											value={`${workingTrial.trialOwner} ${workingTrial.trialOwnerIsPending ? "(Pending)" : ""}`}
											readOnly
										/>
									</Grid.Column>
									<Grid.Column width="2" textAlign="right">
										<Form.Field>
											<label htmlFor="form-dateinput-plant-date">Plant Date</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column
										width="5"
										style={{
											gap: "7.5px",
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "flex-end"
										}}
									>
										{editTrialAuth.userCanEditPlantDate(userAuth, workingTrial.clientId) ? (
											<div style={{ flexGrow: 1 }}>
												<Form.Field
													id="form-date-time-picker-plant-date"
													control={DateTimePicker}
													includeTime={false}
													value={workingTrial.plantDate}
													onChange={(date) => {
														setPlantDate(date);
													}}
													error={trialValidation.plantDate}
												/>
											</div>
										) : (
											<Form.Input
												id="form-input-plant-date"
												value={workingTrial.plantDate ? moment(workingTrial.plantDate).local().format(DATE_FORMAT) : ""}
												readOnly
											/>
										)}
										<Form.Field>
											<label htmlFor="form-is-estimated-plant-date" style={{ marginLeft: "10px", textAlign: "right" }}>
												Is Estimated Plant Date
											</label>
										</Form.Field>
										<Form.Checkbox
											id="form-is-estimate-plant-date"
											readOnly={!editTrialAuth.userCanEditPlantDate(userAuth, workingTrial.clientId)}
											checked={workingTrial.isEstimatedPlantDate}
											onChange={(e, { checked }) => {
												const newWorkingTrial = produce(workingTrial, (draft) => {
													draft.isEstimatedPlantDate = checked;
												});
												setWorkingTrial(newWorkingTrial);
											}}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width="7" />
									<Grid.Column width="2" textAlign="right">
										<Form.Field>
											<label htmlFor="form-dateinput-harvest-date">Harvest Date</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="2">
										{editTrialAuth.userCanEditHarvestDate(userAuth, workingTrial.clientId) ? (
											<Form.Field
												id="form-date-time-picker-harvest-date"
												control={DateTimePicker}
												includeTime={false}
												value={workingTrial.harvestDate}
												onChange={(date) => {
													setHarvestDate(date);
												}}
												error={trialValidation.harvestDate}
											/>
										) : (
											<Form.Input
												id="form-input-harvest-date"
												value={
													workingTrial.harvestDate ? moment(workingTrial.harvestDate).local().format(DATE_FORMAT) : ""
												}
												readOnly
											/>
										)}
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width="2" textAlign="right">
										<Form.Field>
											<label htmlFor="form-select-mean-comparison">Mean Comparison</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="2">
										<Form.Select
											id="form-select-mean-comparison"
											search
											style={{ minWidth: "unset" }}
											options={meanComparisonOptions}
											value={workingTrial.meanComparisonId}
											onChange={(event, { value }) => {
												const newWorkingTrial = produce(workingTrial, (draft) => {
													draft.meanComparisonId = value;
												});
												setWorkingTrial(newWorkingTrial);
											}}
											loading={!meanComparisonOptions || meanComparisonOptions.length === 0}
										/>
									</Grid.Column>
									<Grid.Column width="1" textAlign="right">
										<Form.Field>
											<label htmlFor="form-select-alpha">Alpha</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="2">
										<Form.Select
											id="form-select-alpha"
											search
											style={{ minWidth: "unset" }}
											options={alphaOptions}
											value={workingTrial.alphaId}
											onChange={(event, { value }) => {
												const newWorkingTrial = produce(workingTrial, (draft) => {
													draft.alphaId = value;
												});
												setWorkingTrial(newWorkingTrial);
											}}
											loading={!alphaOptions || alphaOptions.length === 0}
										/>
									</Grid.Column>
									<Grid.Column width="2" textAlign="right">
										<Form.Field>
											<label htmlFor="form-select-mean-comparison">Flight Schedule</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="5">
										<Form.Select
											id="form-select-flight-schedule"
											search
											style={{ minWidth: "unset" }}
											options={flightScheduleOptions}
											value={workingTrial.flightScheduleId}
											onChange={(event, { value }) => {
												const newWorkingTrial = produce(workingTrial, (draft) => {
													draft.flightScheduleId = value;
												});
												setWorkingTrial(newWorkingTrial);
											}}
											loading={!flightScheduleOptions || flightScheduleOptions.length === 0}
										/>
									</Grid.Column>
								</Grid.Row>
							</>
						) : (
							userAuth.currentClientId === workingTrial.clientId && (
								<>
									<Grid.Row>
										<Grid.Column width="2" textAlign="right">
											<Form.Field>
												<label htmlFor="input-trial-owner-org">Trial Owner Org</label>
											</Form.Field>
										</Grid.Column>
										<Grid.Column width="5" textAlign="right">
											<Input
												fluid
												id="input-trial-owner-org"
												value={workingTrial.trialOwnerClientName ? workingTrial.trialOwnerClientName : "Not Assigned"}
												readOnly
											/>
										</Grid.Column>
										<Grid.Column width="2" textAlign="right">
											<Form.Field>
												<label htmlFor="form-dateinput-plant-date">Plant Date</label>
											</Form.Field>
										</Grid.Column>
										<Grid.Column
											width="5"
											style={{
												gap: "7.5px",
												display: "flex",
												flexDirection: "row",
												alignItems: "center",
												justifyContent: "flex-end"
											}}
										>
											{editTrialAuth.userCanEditPlantDate(userAuth, workingTrial.clientId) ? (
												<div style={{ flexGrow: 1 }}>
													<Form.Field
														id="form-date-time-picker-plant-date"
														control={DateTimePicker}
														includeTime={false}
														value={workingTrial.plantDate}
														onChange={(date) => {
															setPlantDate(date);
														}}
														error={trialValidation.plantDate}
													/>
												</div>
											) : (
												<Form.Input
													id="form-input-plant-date"
													value={
														workingTrial.plantDate ? moment(workingTrial.plantDate).local().format(DATE_FORMAT) : ""
													}
													readOnly
												/>
											)}
											<Form.Field>
												<label
													htmlFor="form-is-estimated-plant-date"
													style={{ marginLeft: "10px", textAlign: "right" }}
												>
													Is Estimated Plant Date
												</label>
											</Form.Field>
											<Form.Checkbox
												id="form-is-estimate-plant-date"
												readOnly={!editTrialAuth.userCanEditPlantDate(userAuth, workingTrial.clientId)}
												checked={workingTrial.isEstimatedPlantDate}
												onChange={(e, { checked }) => {
													const newWorkingTrial = produce(workingTrial, (draft) => {
														draft.isEstimatedPlantDate = checked;
													});
													setWorkingTrial(newWorkingTrial);
												}}
											/>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width="2" textAlign="right">
											<Form.Field>
												<label htmlFor="form-input-treatments">Treatment Count</label>
											</Form.Field>
										</Grid.Column>
										<Grid.Column width="2" textAlign="right">
											<Form.Input id="form-input-treatments" value={workingTrial.treatments?.length ?? ""} readOnly />
										</Grid.Column>
										<Grid.Column width="1" textAlign="right">
											<Form.Field>
												<label htmlFor="form-input-reps">Rep Count</label>
											</Form.Field>
										</Grid.Column>
										<Grid.Column width="2" textAlign="right">
											<Form.Input id="form-input-reps" value={workingTrial.repCount ?? ""} readOnly />
										</Grid.Column>
										<Grid.Column width="2" textAlign="right">
											<Form.Field>
												<label htmlFor="form-dateinput-harvest-date">Harvest Date</label>
											</Form.Field>
										</Grid.Column>
										<Grid.Column width="5">
											{editTrialAuth.userCanEditHarvestDate(userAuth, workingTrial.clientId) ? (
												<Form.Field
													id="form-date-time-picker-harvest-date"
													control={DateTimePicker}
													includeTime={false}
													value={workingTrial.harvestDate}
													onChange={(date) => {
														setHarvestDate(date);
													}}
													error={trialValidation.harvestDate}
												/>
											) : (
												<Form.Input
													id="form-input-harvest-date"
													value={
														workingTrial.harvestDate ? moment(workingTrial.harvestDate).local().format(DATE_FORMAT) : ""
													}
													readOnly
												/>
											)}
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width="2" textAlign="right">
											<Form.Field>
												<label htmlFor="form-select-mean-comparison">Mean Comparison</label>
											</Form.Field>
										</Grid.Column>
										<Grid.Column width="2">
											<Form.Select
												id="form-select-mean-comparison"
												search
												style={{ minWidth: "unset" }}
												options={meanComparisonOptions}
												value={workingTrial.meanComparisonId}
												onChange={(event, { value }) => {
													const newWorkingTrial = produce(workingTrial, (draft) => {
														draft.meanComparisonId = value;
													});
													setWorkingTrial(newWorkingTrial);
												}}
												loading={!meanComparisonOptions || meanComparisonOptions.length === 0}
											/>
										</Grid.Column>
										<Grid.Column width="1" textAlign="right">
											<Form.Field>
												<label htmlFor="form-select-alpha">Alpha</label>
											</Form.Field>
										</Grid.Column>
										<Grid.Column width="2">
											<Form.Select
												id="form-select-alpha"
												search
												style={{ minWidth: "unset" }}
												options={alphaOptions}
												value={workingTrial.alphaId}
												onChange={(event, { value }) => {
													const newWorkingTrial = produce(workingTrial, (draft) => {
														draft.alphaId = value;
													});
													setWorkingTrial(newWorkingTrial);
												}}
												loading={!alphaOptions || alphaOptions.length === 0}
											/>
										</Grid.Column>
										<Grid.Column width="2" textAlign="right">
											<Form.Field>
												<label htmlFor="form-select-mean-comparison">Flight Schedule</label>
											</Form.Field>
										</Grid.Column>
										<Grid.Column width="5">
											<Form.Select
												id="form-select-flight-schedule"
												search
												style={{ minWidth: "unset" }}
												options={flightScheduleOptions}
												value={workingTrial.flightScheduleId}
												onChange={(event, { value }) => {
													const newWorkingTrial = produce(workingTrial, (draft) => {
														draft.flightScheduleId = value;
													});
													setWorkingTrial(newWorkingTrial);
												}}
												loading={!flightScheduleOptions || flightScheduleOptions.length === 0}
												error={trialValidation.flightScheduleId}
											/>
										</Grid.Column>
									</Grid.Row>
								</>
							)
						)}
						<Divider />
						<Grid.Row style={{ padding: 0 }}>
							<Grid.Column>
								<TreatmentCSVUpload
									hasImportedData={true}
									onAcceptCSV={saveTreatmentsFromValidCSV}
									onClearTreatments={clearTreatments}
									context="trial"
									trialData={{
										id: workingTrial.id,
										name: workingTrial.name,
										treatmentCount: workingTrial.treatments?.length ?? 0,
										clientId: userAuth.currentClientId,
										context: "trial"
									}}
									currentData={workingTrial.treatments.map((t) => ({
										Treatment: t.trialTreatmentId,
										Name: t.treatmentName,
										Type: treatmentTypeOptions?.find((tto) => tto.value === t.treatmentTypeId)?.text,
										Description: t.treatmentDescription
									}))}
								/>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column style={{ maxHeight: 500, overflowY: "auto" }}>
								<Table
									id="form-table-treatment-listing"
									celled
									striped
									sortable
									selectable
									color="blue"
									textAlign="center"
								>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>Treatment</Table.HeaderCell>
											<Table.HeaderCell>Name</Table.HeaderCell>
											<Table.HeaderCell>Type</Table.HeaderCell>
											<Table.HeaderCell>Description</Table.HeaderCell>
										</Table.Row>
									</Table.Header>

									<Table.Body>
										{workingTrial &&
											_.map(
												workingTrial.treatments,
												({ treatmentId, trialTreatmentId, treatmentName, treatmentTypeId, treatmentDescription }) => {
													return (
														<Table.Row key={treatmentId}>
															<Table.Cell>{trialTreatmentId}</Table.Cell>
															<Table.Cell>
																<Form.Input
																	key={resetKey}
																	id={`form-input-treatment-name-${treatmentId}`}
																	value={treatmentName ?? ""}
																	onChange={(event) => {
																		const newWorkingTrial = produce(workingTrial, (draft) => {
																			const index = draft.treatments.findIndex((t) => t.treatmentId === treatmentId);
																			draft.treatments[index].treatmentName = event.target.value;
																		});
																		setWorkingTrial(newWorkingTrial, "treatment");
																	}}
																	error={
																		!!trialValidation?.treatments?.messages.find((t) => t.id === treatmentId && t.name)
																	}
																	readOnly={!editTrialAuth.userCanEditTreatments(userAuth)}
																	style={{ opacity: 1, color: "#000000" }}
																/>
															</Table.Cell>
															<Table.Cell>
																<Form.Select
																	id={`form-select-treatment-type-${treatmentId}`}
																	options={treatmentTypeOptions}
																	value={treatmentTypeId ? treatmentTypeId : null}
																	placeholder="Select"
																	onChange={(event, { value }) => {
																		const newWorkingTrial = produce(workingTrial, (draft) => {
																			const index = draft.treatments.findIndex((t) => t.treatmentId === treatmentId);
																			draft.treatments[index].treatmentTypeId = value;
																		});
																		setWorkingTrial(newWorkingTrial, "treatment");
																	}}
																	loading={!treatmentTypeOptions || treatmentTypeOptions.length === 0}
																	disabled={!editTrialAuth.userCanEditTreatments(userAuth)}
																	style={{ opacity: 1, color: "#000000" }}
																/>
															</Table.Cell>
															<Table.Cell>
																<Form.Input
																	key={resetKey}
																	id={`form-input-treatment-description-${treatmentId}`}
																	value={treatmentDescription ?? ""}
																	onChange={(event) => {
																		const newWorkingTrial = produce(workingTrial, (draft) => {
																			const index = draft.treatments.findIndex((t) => t.treatmentId === treatmentId);
																			draft.treatments[index].treatmentDescription = event.target.value;
																		});
																		setWorkingTrial(newWorkingTrial, "treatment");
																	}}
																	error={
																		!!trialValidation?.treatments?.messages.find(
																			(t) => t.id === treatmentId && t.description
																		)
																	}
																	readOnly={!editTrialAuth.userCanEditTreatments(userAuth)}
																	style={{ opacity: 1, color: "#000000" }}
																/>
															</Table.Cell>
														</Table.Row>
													);
												}
											)}
									</Table.Body>
								</Table>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row columns="equal">
							<Grid.Column width="2" textAlign="right">
								<Form.Field>
									<label htmlFor="form-text-area-notes">Notes</label>
								</Form.Field>
							</Grid.Column>
							<Grid.Column id="notesColumn">
								<TextArea
									key={resetKey}
									id="form-text-area-notes"
									defaultValue={workingTrial.notes}
									onBlur={(event) => {
										const newWorkingTrial = produce(workingTrial, (draft) => {
											draft.notes = event.target.value;
										});
										setWorkingTrial(newWorkingTrial);
									}}
									rows="5"
									style={
										trialValidation.notesError
											? { borderColor: "#e0b4b4", background: "#FFF6F6" }
											: !editTrialAuth.userCanEditNotes(userAuth)
											? { opacity: 0.5 }
											: null
									}
									readOnly={!editTrialAuth.userCanEditNotes(userAuth)}
								/>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row textAlign="right">
							<Grid.Column>
								<Form.Field>
									<Button id="form-button-cancel" content="Cancel" onClick={() => setShowEditCancelConfirm(true)} />
									<Confirm
										open={showEditCancelConfirm}
										header="Discard Changes"
										content="Are you sure you want to cancel? All changes will be lost."
										onCancel={() => setShowEditCancelConfirm(false)}
										onConfirm={() => {
											undoChanges();
											setShowEditCancelConfirm(false);
										}}
									/>
									<Button
										id="form-button-save"
										primary
										content="Save"
										loading={isSaving}
										disabled={isSaving}
										onClick={() => save()}
									/>
								</Form.Field>
							</Grid.Column>
						</Grid.Row>
						<Confirm
							open={showSendEmailConfirm}
							header="A user with that email does not exist in the system"
							content={`Do you want to send an email to '${
								emailModalType === "Owner" ? trialOwnerEmailAddress : trialSponsorEmailAddress
							}' informing them of this trial?`}
							onCancel={() => setShowSendEmailConfirm(false)}
							onConfirm={() => {
								sendNewUserEmail(emailModalType === "Owner" ? trialOwnerEmailAddress : trialSponsorEmailAddress);
							}}
						/>
					</Grid>
					<Modal open={showEmailModal}>
						<Modal.Header>Assign Trial {emailModalType}</Modal.Header>
						<Modal.Content>
							<Modal.Description>
								<Grid>
									{userAuth.isApAdmin ? (
										<Grid.Row>
											<Grid.Column width={12}>
												<label>
													<b>Enter Email</b>
												</label>
												<Form.Dropdown
													style={{ width: "100%" }}
													id="form-select-email"
													search
													fluid
													options={emailAddressOptions}
													value={emailModalType === "Owner" ? emailAddressForOwner : emailAddressForSponsor}
													onChange={(event, { value }) => {
														emailModalType === "Owner"
															? setEmailAddressForOwner(value)
															: setEmailAddressForSponsor(value);
													}}
													onSearchChange={(event, { searchQuery }) => {
														emailModalType === "Owner"
															? setEmailAddressForOwner(searchQuery)
															: setEmailAddressForSponsor(searchQuery);
													}}
													loading={!emailAddressOptions || emailAddressOptions.length === 0}
													placeholder="Email Address"
													selection
													type="text"
													selectOnBlur={false}
													openOnFocus
													searchQuery={emailModalType === "Owner" ? emailAddressForOwner : emailAddressForSponsor}
												/>
											</Grid.Column>
											<Grid.Column width={2}>
												<br></br>
												<Button
													onClick={() =>
														emailModalType === "Owner" ? setEmailAddressForOwner("") : setEmailAddressForSponsor("")
													}
												>
													Clear
												</Button>
											</Grid.Column>
										</Grid.Row>
									) : (
										<Grid.Row>
											<Grid.Column width={12}>
												<label>
													<b>Enter Email</b>
												</label>
												<Input
													key={resetKey}
													fluid
													id="input-email"
													value={emailModalType === "Owner" ? trialOwnerEmailAddress : trialSponsorEmailAddress}
													onChange={(event) => {
														emailModalType === "Owner"
															? setTrialOwnerEmailAddress(event.target.value)
															: setTrialSponsorEmailAddress(event.target.value);
													}}
													readOnly={modalLoading}
													placeholder="Email Address"
												/>
											</Grid.Column>
											<Grid.Column width={2}>
												<br></br>
												<Button
													onClick={() =>
														emailModalType === "Owner" ? setTrialOwnerEmailAddress("") : setTrialSponsorEmailAddress("")
													}
												>
													Clear
												</Button>
											</Grid.Column>
										</Grid.Row>
									)}
								</Grid>
							</Modal.Description>
						</Modal.Content>
						<Modal.Actions>
							<Button
								type="button"
								floated="right"
								onClick={() =>
									emailModalType === "Owner" ? handleSaveTrialOwnerEmailAddress() : handleSaveTrialSponsorEmailAddress()
								}
								positive
								loading={modalLoading}
								disabled={modalLoading}
							>
								Save
							</Button>
							<Button type="button" floated="right" onClick={() => setShowEmailModal(false)} disabled={modalLoading}>
								Cancel
							</Button>
							<br style={{ clear: "both" }} />
						</Modal.Actions>
					</Modal>
				</Form>
			</Segment>
			<Prompt
				when={isDirty}
				message={(location) => {
					setTarget(location.pathname);
					setOpenConfirmNavModal(true);
					return false;
				}}
			/>
			<ConfrimNavModal
				open={openConfirmNavModal}
				setOpen={setOpenConfirmNavModal}
				onSave={() => {
					handleNavigateOut("saveCont");
				}}
				onDiscard={() => {
					handleNavigateOut("discard");
				}}
			/>
			<RemoveTrialOwnerSponsorModal
				open={openRemoveTrialOwnerSponsorModal}
				setOpen={(isOpen) => setOpenRemoveTrialOwnerSponsorModal(isOpen)}
				trialId={workingTrial.id}
				clientId={userAuth.currentClientId}
				removeTrialOwnerOrSponsor={removeTrialOwnerOrSponsor}
				setWorkingTrialSilently={setWorkingTrialSilently}
				workingTrial={workingTrial}
			/>
		</>
	);
};

export default EditTrial;
