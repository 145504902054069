import * as types from "./actionTypes";
import * as applicationApi from "../../apis/applicationApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";
/*
 *  Action Creators
 *
 */

export function getApplicationOptionsSuccess() {
	return { type: types.APPLICATIONS_GET_APPLICATION_OPTIONS_SUCCESS };
}
export function getApplicationsSuccess(applications) {
	return { type: types.APPLICATIONS_GET_APPLICATIONS_SUCCESS, applications };
}
export function saveApplicationsSuccess(applications) {
	return { type: types.APPLICATIONS_SAVE_APPLICATIONS_SUCCESS, applications };
}
export function updateApplicationsSuccess(applications) {
	return { type: types.APPLICATIONS_UPDATE_APPLICATIONS_SUCCESS, applications };
}
/*
 *  Thunks
 *
 */

export function getApplicationOptions(clientId, cropId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return applicationApi
				.getApplicationOptions(clientId, cropId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getApplicationOptionsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function getApplications(protocolId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return applicationApi
				.getApplications(protocolId, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(getApplicationsSuccess(res.data));
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function saveApplications(applications, protocolId, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return applicationApi
				.saveApplications(applications, protocolId, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(saveApplicationsSuccess(["pass"]));
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
export function updateApplications(applications, clientId, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return applicationApi
				.updateApplications(applications, clientId, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						dispatch(apiCallError(res.data));
						reject(res.data);
					}
					dispatch(updateApplicationsSuccess(["pass"]));
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
