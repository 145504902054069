import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Segment, Button, Modal, Checkbox, Loader } from "semantic-ui-react";
import { toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "../../../../auth/auth0";
import _ from "lodash";

import PropTypes from "prop-types";
import TreatmentsTable from "./TreatmentsTable";

import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserSettings } from "../../../../hooks/useUserSettings";

import * as protocolActions from "../../../../redux/actions/protocolActions";

import ProtocolFavoritingWidget from "../../Widgets/Favorites/ProtocolFavoritingWidget";
import { useUserAuth } from "../../../../hooks/useUserAuth";

const Summary = ({ protocolName }) => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const moduleNavigation = useModuleNavigation();
	const userSettings = useUserSettings();
	const userAuth = useUserAuth();
	const history = useHistory();

	//-- Data Sources
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));
	const [summaryInfo, setSummaryInfo] = useState([]);
	const [summaryTableData, setSummaryTableData] = useState([]);
	const [groundData, setGroundData] = useState([]);
	const [trialGroundData, setTrialGroundData] = useState([]);

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [finishProtocolModalOpen, setFinishProtocolModalOpen] = useState(false);
	const [keepTreatmentNames, setKeepTreatmentNames] = useState(false);

	useEffect(() => {
		if (clientId) {
			getSummaryInfo(clientId);
		}
	}, [clientId]);

	async function getSummaryInfo(clientId) {
		setLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(protocolActions.getSummaryInfo(moduleNavigation.protocolId, clientId, accessToken))
			.then((res) => {
				setSummaryInfo(res);
				stageSummaryInfo(res);
				stageGroundData(res);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	}

	function stageSummaryInfo(summaryData) {
		let tableData = _.map(summaryData.treatments, (t) => {
			if (!t.applications) {
				t.applications = [];
			}
			if (!t.associatedTrials) {
				t.associatedTrials = [];
			}
			t.applications = t.applications.concat(
				_.filter(summaryData.applications, (a) => {
					return a.trialTreatmentId === t.trialTreatmentId;
				})
			);
			t.associatedTrials = t.associatedTrials.concat(
				_.filter(summaryData.associatedTrials, (at) => {
					return at.trialTreatmentId === t.trialTreatmentId;
				})
			);
			return t;
		});
		setSummaryTableData(tableData);
	}

	function stageGroundData(summaryData) {
		let groupedGroundData = _.groupBy(summaryData.protocolGroundData, "datasetNumericalId");

		setGroundData(groupedGroundData);
		setTrialGroundData(summaryData.trialGroundData);
	}

	function handleFinishProtocolModalOpen() {
		setFinishProtocolModalOpen(!finishProtocolModalOpen);
	}

	async function finishProtocol() {
		setSaving(true);
		const accessToken = await getTokenSilently();
		dispatch(protocolActions.setSummaryInfo(moduleNavigation.protocolId, !keepTreatmentNames, clientId, accessToken))
			.then(() => {
				toast.success("Protocol info saved successfully.");
				handleFinishProtocolModalOpen();
				setSaving(false);
				history.push(moduleNavigation.createProtocolLink(true, moduleNavigation.protocolId, "overview-status"));
			})
			.catch((err) => {
				toast.error("Failed to save protocol info. Please try again.");
				console.log(err);
				setSaving(false);
			});
	}
	return loading ? (
		<Loader active />
	) : summaryInfo.applications?.length === 0 ||
	  summaryInfo.associatedTrials?.length === 0 ||
	  summaryInfo.treatments?.length === 0 ? (
		<Segment>
			<p>{"Protocol has not been fully set up yet. Summary cannot be displayed yet."}</p>
		</Segment>
	) : (
		<>
			<Segment basic style={{ minHeight: 50, marginBottom: 30 }}>
				<Button
					id="form-button-back"
					floated="left"
					secondary
					content={"Back"}
					disabled={saving}
					onClick={() => {
						history.push(moduleNavigation.createProtocolLink(true, moduleNavigation.protocolId, "associate-trials"));
					}}
				/>
				<Modal
					open={finishProtocolModalOpen}
					trigger={
						<Button
							id="button-finish-protocol"
							primary
							disabled={saving}
							floated="right"
							content={"Finish Protocol"}
							onClick={handleFinishProtocolModalOpen}
						/>
					}
				>
					<Modal.Header>Protocol Completion</Modal.Header>
					<Modal.Content scrolling>
						Confirmation will transfer treatment names/types and create applications and assessments for linked trials.{" "}
						<br />
						<br />
						<Checkbox
							checked={keepTreatmentNames}
							onChange={(event, { checked }) => {
								setKeepTreatmentNames(checked);
							}}
						/>
						{"  "}
						Keep existing names (Do not overwrite treatment names on trials using treatment names).
					</Modal.Content>
					<Modal.Actions>
						<Button primary floated="right" loading={saving} onClick={finishProtocol}>
							Confirm
						</Button>
						<Button color="red" floated="right" loading={saving} onClick={handleFinishProtocolModalOpen}>
							Cancel
						</Button>
						<br style={{ clear: "both" }} />
					</Modal.Actions>
				</Modal>
			</Segment>
			<Segment basic>
				<h2 style={{ float: "left" }}>Protocol Summary - Review and Confirm</h2>
				<div style={{ float: "right" }}>
					<ProtocolFavoritingWidget
						style={{ display: "inline" }}
						clientId={userAuth.currentClientId.toUpperCase()}
						protocolId={moduleNavigation.protocolId.toUpperCase()}
						userSettings={userSettings}
					></ProtocolFavoritingWidget>
					<h2 style={{ float: "right", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
						<i>{protocolName}</i>
					</h2>
				</div>
				<hr style={{ clear: "both" }} />
				<TreatmentsTable
					summaryInfo={summaryTableData}
					applications={summaryInfo.applications}
					associatedTrials={summaryInfo.associatedTrials}
					groundData={groundData}
					trialGroundData={trialGroundData}
				/>
			</Segment>
		</>
	);
};

Summary.propTypes = {
	protocolName: PropTypes.string
};

export default Summary;
