import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Segment, Loader } from "semantic-ui-react";
import { toast } from "react-toastify";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import { useUserSettings } from "../../../../hooks/useUserSettings";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";

import * as trialActions from "../../../../redux/actions/trialActions";

import TrialOutlineTable from "./TrialOutlineTable";

import TrialFavoritingWidget from "../../Widgets/Favorites/TrialFavoritingWidget";

const TrialOutline = () => {
	const userAuth = useUserAuth();
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const moduleNavigation = useModuleNavigation();

	const userSettings = useUserSettings();

	//-- Data sources
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));
	const entityNames = useSelector((state) => (state.entityNames ? state.entityNames : null));
	const [trialName, setTrialName] = useState(null);

	//-- Query results
	const [trialOutlineData, setTrialOutlineData] = useState(null);

	//-- UI Control
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (clientId) {
			getTrialOutlineData(clientId);
		}
	}, [clientId]);

	useEffect(() => {
		if (entityNames?.trialId && moduleNavigation.trialId.toUpperCase() === entityNames.trialId.toUpperCase()) {
			setTrialName(
				`${entityNames.trialName} (${entityNames.cooperatorName}, ${entityNames.city}, ${entityNames.state})`
			);
			setLoading(false);
		}
	}, [entityNames]);

	async function getTrialOutlineData(clientId) {
		if (moduleNavigation.trialId) {
			const accessToken = await getTokenSilently();
			setLoading(true);
			dispatch(trialActions.getTrialOutlineData(clientId, moduleNavigation.trialId, accessToken))
				.then((res) => {
					setTrialOutlineData(res);
					setLoading(false);
				})
				.catch((err) => {
					toast.error("Error loading Trial Outline. Please try again.");
					setLoading(false);
					console.log(err);
				});
		}
	}

	return loading ? (
		<Loader active />
	) : !loading && (!trialOutlineData || trialOutlineData.length === 0) ? (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2>Trial Outline</h2>
			<hr />
			<Segment>
				<p>{"We could not find outline data for this trial."}</p>
			</Segment>
		</Segment>
	) : (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2 style={{ float: "left" }}>Trial Outline</h2>
			<div style={{ float: "right" }}>
				<TrialFavoritingWidget
					style={{ display: "inline" }}
					clientId={userAuth.currentClientId.toUpperCase()}
					trialId={moduleNavigation.trialId.toUpperCase()}
					userSettings={userSettings}
				></TrialFavoritingWidget>
				<h2 style={{ float: "right", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
					<i>{trialName}</i>
				</h2>
			</div>
			<hr style={{ clear: "both" }} />
			<TrialOutlineTable trialOutlineData={trialOutlineData} />
		</Segment>
	);
};

export default TrialOutline;
