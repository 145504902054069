import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";
import moment from "moment";

import { Segment, Grid, Form, Button, Loader } from "semantic-ui-react";
import { DateTimePicker } from "react-widgets";
import { toast } from "react-toastify";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as trialActions from "../../../../redux/actions/trialActions";
import * as growingSeasonActions from "../../../../redux/actions/growingSeasonActions";

import TrialSearchResults from "./TrialSearchResults";

import "./styles.css";

const TrialSearch = () => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();

	const DATE_FORMAT = "MM/DD/YYYY";

	//-- Data sources
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));
	const [trialSearchDataOptions, setTrialSearchDataOptions] = useState(null);
	const [growingSeasons, setGrowingSeasons] = useState([]);

	//-- Dropdown options
	const [cropOptions, setCropOptions] = useState([]);
	const [farmOptions, setFarmOptions] = useState([]);
	const [fieldOptions, setFieldOptions] = useState([]);
	const [growingSeasonOptions, setGrowingSeasonOptions] = useState([]);
	const [trialOptions, setTrialOptions] = useState([]);
	const [trialOwnerOptions, setTrialOwnerOptions] = useState([]);
	const [companyOptions, setCompanyOptions] = useState([]);
	const [cooperatorCompanyOptions, setCooperatorCompanyOptions] = useState([]);
	const presetTimeOptions = [
		{
			key: "noDate",
			value: "noDate",
			text: "No Plant Date"
		},
		{
			key: "thisYear",
			value: "thisYear",
			text: "This Year"
		},
		{
			key: "thisMonth",
			value: "thisMonth",
			text: "This Month"
		},
		{
			key: "thisWeek",
			value: "thisWeek",
			text: "This Week"
		},
		{
			key: "lastYear",
			value: "lastYear",
			text: "Last Year"
		},
		{
			key: "lastMonth",
			value: "lastMonth",
			text: "Last Month"
		},
		{
			key: "lastWeek",
			value: "lastWeek",
			text: "Last Week"
		},
		{
			key: "custom",
			value: "custom",
			text: "Custom"
		},
		{
			key: "allDates",
			value: "allDates",
			text: "All Dates"
		}
	];

	//-- Selected filter options
	//-- When setting the default time filter to this year or last year, update these dates to getFullYear() (this year) or getFullYear() - 1
	//-- Also update the presetTimeId to either ("thisYear") or ("lastYear")
	//-- e.g.
	const [plantDateFrom, setPlantDateFrom] = useState(new Date(new Date().getFullYear() - 1, 0, 1));
	const [prevPlantDateFrom, setPrevPlantDateFrom] = useState(new Date(new Date().getFullYear() - 1, 0, 1));
	const [plantDateTo, setPlantDateTo] = useState(new Date(new Date().getFullYear() - 1, 11, 31));
	const [prevPlantDateTo, setPrevPlantDateTo] = useState(new Date(new Date().getFullYear() - 1, 11, 31));

	const [cropId, setCropId] = useState("all");
	const [farmId, setFarmId] = useState("all");
	const [fieldId, setFieldId] = useState("all");
	const [growingSeasonId, setGrowingSeasonId] = useState(null);
	const [trialId, setTrialId] = useState("all");
	const [trialOwnerUserId, setTrialOwnerUserId] = useState("all");
	const [companyId, setCompanyId] = useState("all");
	const [cooperatorCompanyId, setCooperatorCompanyId] = useState("all");
	const [presetTimeId, setPresetTimeId] = useState("thisYear");
	const [searchAllClients, setSearchAllClients] = useState(false);
	const [hasSearchedAllClients, setHasSearchedAllClients] = useState(false);

	//-- Search results
	const [trialSearchResults, setTrialSearchResults] = useState(null);
	const [filteredTrialList, setFilteredTrialList] = useState([]);
	const [trialStatuses, setTrialStatuses] = useState([]);
	const [defStatus, setDEFStatus] = useState("All");

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [loadingSearchResults, setLoadingSearchResults] = useState(true);

	const defStatusOptions = [{ key: "All", value: "All", text: "All" }]
		.concat([{ key: "Exclusions", value: "Exclusions", text: "Exclusions Only" }])
		.concat([{ key: "Flags", value: "Flags", text: "Flags Only" }])
		.concat([{ key: "Both", value: "Both", text: "Flags & Exclusions" }])
		.concat([{ key: "No Annotations", value: "No Annotations", text: "No Annotations" }]);

	useEffect(() => {
		//-- userAuth.isReady prevents a "Can't perform a React state update on an unmounted component..."
		if (userAuth.isReady && userAuth.currentClientId) {
			if (!trialSearchDataOptions) {
				setLoading(true);
				getTrialSearchDataOptions(userAuth.currentClientId);
				getGrowingSeasons();
			} else {
				searchForTrials(clientId);
			}
		}
	}, [userAuth.isReady, userAuth.currentClientId, trialSearchDataOptions]);

	useEffect(() => {
		if (trialSearchDataOptions && trialSearchResults) {
			let currentGrowingSeason = null;
			if (
				cropOptions.length === 0 ||
				farmOptions.length === 0 ||
				fieldOptions.length === 0 ||
				growingSeasonOptions.length === 0 ||
				trialOptions.length === 0 ||
				trialOwnerOptions.length === 0 ||
				companyOptions.length === 0
			) {
				currentGrowingSeason = setDropdownOptions();
			}

			handleFilter(currentGrowingSeason);
		}
	}, [trialSearchDataOptions, trialSearchResults]);

	useEffect(() => {
		if (trialSearchResults) {
			setDropdownOptions();
		}
	}, [searchAllClients]);

	async function getTrialSearchDataOptions(clientId) {
		const accessToken = await getTokenSilently();
		setLoadingSearchResults(true);
		dispatch(trialActions.getTrialSearchDataOptions(clientId, searchAllClients, accessToken))
			.then((res) => {
				setTrialSearchDataOptions(res);
				//-- Don't set loading to false here because we are going to search after we get the options, so let that function set loading to false
			})
			.catch((err) => {
				toast.error("Error loading Trial Search page. Please try again.");
				setLoading(false);
				setLoadingSearchResults(false);
				console.log(err);
			});
	}

	const getGrowingSeasons = async () => {
		const accessToken = await getTokenSilently();
		dispatch(growingSeasonActions.getGrowingSeasonDetails(accessToken, clientId))
			.then((res) => {
				if (res.statusCode === 200) {
					setGrowingSeasons(res.data);
				} else {
					toast.error("Unable to load Growing Season data. Please try again.");
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error("Unable to load Growing Season data. Please try again.");
			});
	};

	async function searchForTrials(clientId) {
		if (clientId && plantDateTo && plantDateFrom) {
			const accessToken = await getTokenSilently();
			setLoadingSearchResults(true);
			dispatch(
				trialActions.searchForTrials(
					{
						plantDateFrom: plantDateFrom.toDateString(DATE_FORMAT),
						plantDateTo: plantDateTo.toDateString(DATE_FORMAT)
					},
					clientId,
					searchAllClients,
					accessToken
				)
			)
				.then((res) => {
					setTrialSearchResultsState(res);
					setTrialStatuses(res.trialStatuses);
					setLoading(false);
					setLoadingSearchResults(false);
				})
				.catch((err) => {
					toast.error("Error searching for trials. Please try again.");
					setLoading(false);
					setLoadingSearchResults(false);
					console.log(err);
				});
		}
	}

	function setDropdownOptions() {
		let trials = trialSearchResults?.trialInfos;

		setCropOptionsState(trialSearchDataOptions.crops);
		setFarmOptionsState(trialSearchDataOptions.farms, trials);
		setFieldOptionsState(trialSearchDataOptions.fields, trials);
		let currentGs = setGrowingSeasonOptionsState(trialSearchDataOptions.growingSeasons);
		setTrialOptionsState(trialSearchDataOptions.trials, trials);
		setTrialOwnerOptionsState(trialSearchDataOptions.trialOwners, trials);
		setCompanyOptionsState(trialSearchDataOptions.companies, trials);
		setCooperatorCompanyOptionsState(
			_.filter(trialSearchDataOptions.companies, (c) => {
				return c.isCooperator === true;
			}),
			trials
		);

		return currentGs;
	}

	function setCropOptionsState(crops) {
		setCropOptions(
			[{ key: "all", value: "all", text: "All" }].concat(
				_.map(crops, ({ cropId, name }) => {
					const opt = { key: cropId, value: cropId, text: name };
					return opt;
				})
			)
		);
	}

	function setFarmOptionsState(farms, trials) {
		let fOptions = _.map(farms, ({ farmId, name, clientName }) => {
			const opt = {
				key: farmId,
				value: farmId,
				text: name,
				content: (
					<div>
						<span style={{ fontSize: 14, color: "#000000" }}>{name}</span>
						<span style={{ float: "right", fontSize: 12, opacity: 0.75 }}>({clientName})</span>
					</div>
				)
			};
			return opt;
		}).concat(
			_.map(trials, ({ farmId, farmName, clientName }) => {
				const opt = {
					key: farmId,
					value: farmId,
					text: farmName,
					content: (
						<div>
							<span style={{ fontSize: 14, color: "#000000" }}>{farmName}</span>
							<span style={{ float: "right", fontSize: 12, opacity: 0.75 }}>({clientName})</span>
						</div>
					)
				};
				return opt;
			})
		);

		fOptions = _.uniqBy(fOptions, "key");
		let orderedOptionList = _.orderBy(fOptions, [(fOptions) => fOptions.text.toLowerCase()]);
		orderedOptionList.unshift({ key: "all", value: "all", text: "All" });
		setFarmOptions(orderedOptionList);
	}

	function setFieldOptionsState(fields, trials) {
		let fOptions = _.map(fields, ({ id, name, clientName }) => {
			const opt = {
				key: id,
				value: id,
				text: name,
				content: (
					<div>
						<span style={{ fontSize: 14, color: "#000000" }}>{name}</span>
						<span style={{ float: "right", fontSize: 12, opacity: 0.75 }}>({clientName})</span>
					</div>
				)
			};
			return opt;
		}).concat(
			_.map(trials, ({ fieldId, fieldName, clientName }) => {
				const opt = {
					key: fieldId,
					value: fieldId,
					text: fieldName,
					content: (
						<div>
							<span style={{ fontSize: 14, color: "#000000" }}>{fieldName}</span>
							<span style={{ float: "right", fontSize: 12, opacity: 0.75 }}>({clientName})</span>
						</div>
					)
				};
				return opt;
			})
		);
		fOptions = _.uniqBy(fOptions, "key");
		let orderedOptionList = _.orderBy(fOptions, [(fOptions) => fOptions.text.toLowerCase()]);
		orderedOptionList.unshift({ key: "all", value: "all", text: "All" });
		setFieldOptions(orderedOptionList);
	}

	function setGrowingSeasonOptionsState(growingSeasons) {
		let tempGsOptions = [{ key: "all", value: "all", text: "All" }].concat(
			_.map(growingSeasons, ({ growingSeasonId, name }) => {
				const opt = { key: growingSeasonId, value: growingSeasonId, text: name };
				return opt;
			})
		);
		setGrowingSeasonOptions(tempGsOptions);

		//-- Set growing season to current gs
		let currentGrowingSeason = getCurrentGrowingSeason();
		let currentGrowingSeasonSelection =
			_.find(tempGsOptions, (gs) => gs.text.includes(currentGrowingSeason))?.value ?? tempGsOptions[0]?.value;
		setGrowingSeasonId(currentGrowingSeasonSelection);

		return currentGrowingSeasonSelection;
	}

	function setTrialOptionsState(trials, tTrials) {
		let tOptions = _.map(trials, ({ id, name, clientName }) => {
			const opt = {
				key: id,
				value: id,
				text: name,
				content: (
					<div>
						<span style={{ fontSize: 14, color: "#000000" }}>{name}</span>
						<span style={{ float: "right", fontSize: 12, opacity: 0.75 }}>({clientName})</span>
					</div>
				)
			};
			return opt;
		}).concat(
			_.map(tTrials, ({ trialId, trialName, clientName }) => {
				const opt = {
					key: trialId,
					value: trialId,
					text: trialName,
					content: (
						<div>
							<span style={{ fontSize: 14, color: "#000000" }}>{trialName}</span>
							<span style={{ float: "right", fontSize: 12, opacity: 0.75 }}>({clientName})</span>
						</div>
					)
				};
				return opt;
			})
		);
		tOptions = _.uniqBy(tOptions, "key");
		let orderedOptionList = _.orderBy(tOptions, [(tOptions) => tOptions.text.toLowerCase().trim()]);
		orderedOptionList.unshift({ key: "all", value: "all", text: "All" });
		setTrialOptions(orderedOptionList);
	}

	function setTrialOwnerOptionsState(trialOwners, trials) {
		let tOptions = _.map(trialOwners, ({ trialOwnerUserId, firstName, lastName, clientName }) => {
			const opt = {
				key: trialOwnerUserId,
				value: trialOwnerUserId,
				text: `${firstName} ${lastName}`,
				content: (
					<div>
						<span style={{ fontSize: 14, color: "#000000" }}>
							{firstName} {lastName}
						</span>
						<span style={{ float: "right", fontSize: 12, opacity: 0.75 }}>({clientName})</span>
					</div>
				)
			};
			return opt;
		}).concat(
			_.map(trials, ({ trialOwnerUserId, trialOwnerFirstName, trialOwnerLastName, clientName }) => {
				const opt = {
					key: trialOwnerUserId,
					value: trialOwnerUserId,
					text: `${trialOwnerFirstName} ${trialOwnerLastName}`,
					content: (
						<div>
							<span style={{ fontSize: 14, color: "#000000" }}>
								{trialOwnerFirstName} {trialOwnerLastName}
							</span>
							<span style={{ float: "right", fontSize: 12, opacity: 0.75 }}>({clientName})</span>
						</div>
					)
				};
				return opt;
			})
		);

		//Get unique list of trial owner options and then filter out any null values (null's come from no Trial Owner being set on the Trial)
		tOptions = _.filter(_.uniqBy(tOptions, "key"), (opt) => opt.key !== null);
		let orderedOptionList = _.orderBy(tOptions, [(tOptions) => tOptions.text.toLowerCase().trim()]);
		orderedOptionList.unshift({ key: "all", value: "all", text: "All" });
		setTrialOwnerOptions(orderedOptionList);
	}

	function setCompanyOptionsState(companies, trials) {
		let cOptions = [{ key: "all", value: "all", text: "All" }].concat(
			_.map(companies, ({ id, name, clientName }) => {
				const opt = {
					key: id,
					value: id,
					text: name,
					content: (
						<div>
							<span style={{ fontSize: 14, color: "#000000" }}>{name}</span>
							<span style={{ float: "right", fontSize: 12, opacity: 0.75 }}>({clientName})</span>
						</div>
					)
				};
				return opt;
			}).concat(
				_.map(trials, ({ companyId, companyName, clientName }) => {
					const opt = {
						key: companyId,
						value: companyId,
						text: companyName,
						content: (
							<div>
								<span style={{ fontSize: 14, color: "#000000" }}>{companyName}</span>
								<span style={{ float: "right", fontSize: 12, opacity: 0.75 }}>({clientName})</span>
							</div>
						)
					};
					return opt;
				})
			)
		);

		cOptions = cOptions = _.filter(
			_.uniqBy(cOptions, "key"),
			(opt) => opt.key !== null && opt.key !== "00000000-0000-0000-0000-000000000000"
		);
		setCompanyOptions(cOptions);
	}

	function setCooperatorCompanyOptionsState(companies, trials) {
		let ccOptions = _.map(companies, ({ id, name, clientName }) => {
			const opt = {
				key: id,
				value: id,
				text: name,
				content: (
					<div>
						<span style={{ fontSize: 14, color: "#000000" }}>{name}</span>
						<span style={{ float: "right", fontSize: 12, opacity: 0.75 }}>({clientName})</span>
					</div>
				)
			};
			return opt;
		}).concat(
			_.map(trials, ({ cooperatorCompanyId, cooperatorName, clientName }) => {
				const opt = {
					key: cooperatorCompanyId,
					value: cooperatorCompanyId,
					text: cooperatorName,
					content: (
						<div>
							<span style={{ fontSize: 14, color: "#000000" }}>{cooperatorName}</span>
							<span style={{ float: "right", fontSize: 12, opacity: 0.75 }}>({clientName})</span>
						</div>
					)
				};
				return opt;
			})
		);

		ccOptions = ccOptions = _.filter(
			_.uniqBy(ccOptions, "key"),
			(opt) => opt.key !== null && opt.key !== "00000000-0000-0000-0000-000000000000"
		);

		let orderedOptionList = _.orderBy(ccOptions, [(ccOptions) => ccOptions.text.toLowerCase()]);
		orderedOptionList.unshift({ key: "all", value: "all", text: "All" });
		setCooperatorCompanyOptions(orderedOptionList);
	}

	function setTrialSearchResultsState(searchResults) {
		if (searchResults) {
			setTrialSearchResults(searchResults);
		}
	}

	const getCurrentGrowingSeason = () => {
		//const date = new Date();

		//let month = date.getMonth() + 1;
		let year = "2024"; //date.getFullYear();

		let season = "Spring";
		//if (month < 5 || month > 12) season = "Fall";

		return `${year} ${season}`;
	};

	const handleFilter = (currentGrowingSeason = null) => {
		if (trialSearchResults?.trialInfos && trialSearchResults?.trialInfos) {
			if (clientId) {
				var mPlantDateFrom = moment(plantDateFrom);
				var mPrevPlantDateFrom = moment(prevPlantDateFrom);
				var mPlantDateTo = moment(plantDateTo);
				var mPrevPlantDateTo = moment(prevPlantDateTo);

				if (searchAllClients === true && hasSearchedAllClients === false) {
					getTrialSearchDataOptions(clientId);
					setHasSearchedAllClients(true);
				} else if (!mPlantDateFrom.isSame(mPrevPlantDateFrom) || !mPlantDateTo.isSame(mPrevPlantDateTo)) {
					if (searchAllClients === false) {
						setHasSearchedAllClients(false);
					}
					searchForTrials(clientId);
				}
				setPrevPlantDateFrom(plantDateFrom);
				setPrevPlantDateTo(plantDateTo);
			}

			let filteredList = trialSearchResults.trialInfos.filter((ti) => {
				return (
					//-- clients
					(searchAllClients === true || ti.clientId === clientId) &&
					//-- crop
					(cropId === "all" || ti.cropId === cropId) &&
					//-- farm
					(farmId === "all" || ti.farmId === farmId) &&
					//-- field
					(fieldId === "all" || ti.fieldId.toLowerCase() === fieldId.toLowerCase()) &&
					//-- growing season
					(growingSeasonId === "all" ||
						ti.growingSeasonId === growingSeasonId ||
						(growingSeasonId === null && ti.growingSeasonId === currentGrowingSeason)) &&
					//-- trial
					(trialId === "all" || ti.trialId === trialId) &&
					//-- trial owner
					(trialOwnerUserId === "all" || ti.trialOwnerUserId === trialOwnerUserId) &&
					//-- company
					(companyId === "all" || ti.companyId === companyId) &&
					//-- cooperator
					(cooperatorCompanyId === "all" || ti.cooperatorCompanyId === cooperatorCompanyId) &&
					((plantDateFrom === null && plantDateTo === null) || presetTimeId === "noDate" ? ti.plantDate === null : true)
				);
			});

			//Filter DEF Statuses
			let filteredTempTrialData = _.filter(trialSearchResults.trialStatuses, (ts) => {
				if (defStatus === "Exclusions") {
					return (ts.hasExclusions || ts.hasPartialExclusions) && !ts.hasFlaggings && !ts.hasPartialFlaggings;
				} else if (defStatus === "Flags") {
					return (ts.hasFlaggings || ts.hasPartialFlaggings) && !ts.hasExclusions && !ts.hasPartialExclusions;
				} else if (defStatus === "Both") {
					return (ts.hasFlaggings || ts.hasPartialFlaggings) && (ts.hasExclusions || ts.hasPartialExclusions);
				} else if (defStatus === "No Annotations") {
					return !ts.hasFlaggings && !ts.hasExclusions && !ts.hasPartialFlaggings && !ts.hasPartialExclusions;
				} else {
					return filteredList;
				}
			});
			let trialIdsToFind = _.map(filteredTempTrialData, "trialId");
			filteredList = _.filter(filteredList, (ftd) => {
				if (_.includes(trialIdsToFind, ftd.trialId)) {
					return ftd;
				}
			});
			setFilteredTrialList(filteredList);
		}
	};

	const resetFilter = () => {
		//--TODO: Date and preset time need to be updated when changing back to look at current year
		setPlantDateFrom(new Date(new Date().getFullYear(), 0, 1));
		setPlantDateTo(new Date(new Date().getFullYear(), 11, 31));
		setPresetTimeId("thisYear");
		setCropId("all");
		setFarmId("all");
		setFieldId("all");
		setTrialId("all");
		setTrialOwnerUserId("all");
		setCompanyId("all");
		setCooperatorCompanyId("all");
		//-- Set growing season to current gs
		let currentGrowingSeason = getCurrentGrowingSeason();
		let currentGrowingSeasonSelection = _.find(growingSeasonOptions, (gs) =>
			gs.text.includes(currentGrowingSeason)
		)?.value;
		setGrowingSeasonId(currentGrowingSeasonSelection);
		//Need to refetch data
		getTrialSearchDataOptions(clientId);
	};

	useEffect(() => {
		if (presetTimeId) {
			if (presetTimeId !== "custom") {
				setPrevPlantDateFrom(plantDateFrom);
				setPrevPlantDateTo(plantDateTo);
			}

			if (presetTimeId === "thisYear") {
				setPlantDateFrom(new Date(new Date().getFullYear(), 0, 1));
				setPlantDateTo(new Date(new Date().getFullYear(), 11, 31));
			} else if (presetTimeId === "thisMonth") {
				let lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();

				setPlantDateFrom(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
				setPlantDateTo(new Date(new Date().getFullYear(), new Date().getMonth(), lastDayOfMonth));
			} else if (presetTimeId === "thisWeek") {
				let currentDay = new Date();
				let diff = currentDay.getDate() - currentDay.getDay() + 1;
				let beginningOfWeek = new Date(new Date(currentDay).setDate(diff - 1));
				let endOfWeek = new Date(new Date(currentDay).setDate(diff + 5));

				setPlantDateFrom(beginningOfWeek);
				setPlantDateTo(endOfWeek);
			} else if (presetTimeId === "lastYear") {
				setPlantDateFrom(new Date(new Date().getFullYear() - 1, 0, 1));
				setPlantDateTo(new Date(new Date().getFullYear() - 1, 11, 31));
			} else if (presetTimeId === "lastMonth") {
				let lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate();
				let year = new Date().getFullYear();
				if (new Date().getMonth() === 1) {
					year = new Date().getFullYear() - 1;
				}

				setPlantDateFrom(new Date(year, new Date().getMonth() - 1, 1));
				setPlantDateTo(new Date(year, new Date().getMonth() - 1, lastDayOfMonth));
			} else if (presetTimeId === "lastWeek") {
				let currentDay = new Date();
				let diff = currentDay.getDate() - currentDay.getDay() + 1;
				let beginningOfWeek = new Date(new Date(currentDay).setDate(diff - 8));
				let endOfWeek = new Date(new Date(currentDay).setDate(diff - 2));

				setPlantDateFrom(beginningOfWeek);
				setPlantDateTo(endOfWeek);
			} else if (presetTimeId === "allDates") {
				setPlantDateFrom(new Date(2001, 0, 1));
				setPlantDateTo(new Date(2099, 0, 1));
				setGrowingSeasonId("all");
			} else if (presetTimeId === "noDate") {
				setPlantDateFrom(null);
				setPlantDateTo(null);
			}
		}
	}, [presetTimeId]);

	return loading ? (
		<Loader active />
	) : !loading && (!trialSearchDataOptions || !trialOptions || (trialOptions && trialOptions.length === 0)) ? (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2>Trial Search</h2>
			<hr />
			<Segment>
				<p>{"Trial Search could not be loaded. Please try again. If the problem persists, please contact support."}</p>
			</Segment>
		</Segment>
	) : (
		<Segment style={{ marginTop: 15, marginLeft: 50 }}>
			<h2>Trial Search</h2>
			<hr />
			<Form onSubmit={handleFilter}>
				<Grid id="trialSearchGrid" verticalAlign="middle">
					<Grid.Row>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-dateinput-plant-date-from">Plant Date From*</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="3">
							<Form.Field
								id="form-date-time-plant-date-from"
								control={DateTimePicker}
								includeTime={false}
								value={plantDateFrom}
								required
								error={!plantDateFrom && presetTimeId !== "noDate"}
								disabled={loadingSearchResults}
								onChange={(date) => {
									setPresetTimeId("custom");
									if (date) {
										date.setHours(0, 0, 0, 0);
										setPlantDateFrom(date);
									} else {
										setPlantDateFrom(null);
									}
									setGrowingSeasonId("all");
								}}
							/>
						</Grid.Column>
						<Grid.Column textAlign="center" style={{ padding: "unset", width: 10 }}>
							<Form.Field>
								<label htmlFor="form-dateinput-plant-date-to">to*</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="3">
							<Form.Field
								id="form-date-time-plant-date-to"
								control={DateTimePicker}
								includeTime={false}
								value={plantDateTo}
								required
								error={!plantDateTo && presetTimeId !== "noDate"}
								disabled={loadingSearchResults}
								onChange={(date) => {
									setPresetTimeId("custom");
									if (date) {
										date.setHours(0, 0, 0, 0);
										setPlantDateTo(date);
									} else {
										setPlantDateTo(null);
									}
									setGrowingSeasonId("all");
								}}
							/>
						</Grid.Column>
						<Grid.Column width="1" textAlign="right" style={{ padding: "unset" }}>
							<Form.Field>
								<label htmlFor="form-select-preset-times">Preset Times</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="3">
							<Form.Select
								id="form-select-preset-times"
								options={presetTimeOptions}
								value={presetTimeId}
								disabled={loadingSearchResults}
								onChange={(event, { value }) => {
									setPresetTimeId(value);
									setGrowingSeasonId("all");
								}}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-growing-season">Growing Season</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-growing-season"
								search
								options={growingSeasonOptions}
								value={growingSeasonId}
								disabled={loadingSearchResults}
								onChange={(event, { value }) => {
									setGrowingSeasonId(value);
									if (value === "all") {
										setPlantDateFrom(new Date(2001, 0, 1));
										setPlantDateTo(new Date(2099, 0, 1));
									} else {
										let gsMatch = _.filter(growingSeasons, (gs) => {
											return gs.id === value;
										});
										setPlantDateFrom(new Date(gsMatch[0].startYear, 0, 1));
										setPlantDateTo(new Date(gsMatch[0].endYear, 11, 31));
									}
									setPresetTimeId("custom");
								}}
								loading={!growingSeasonOptions || growingSeasonOptions.length === 0}
							/>
						</Grid.Column>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-farm">Farm</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-farm"
								search
								options={farmOptions}
								value={farmId}
								disabled={loadingSearchResults}
								onChange={(event, { value }) => {
									setFarmId(value);
								}}
								loading={!farmOptions || farmOptions.length === 0}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-crop">Crop</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-crop"
								search
								options={cropOptions}
								value={cropId}
								disabled={loadingSearchResults}
								onChange={(event, { value }) => {
									setCropId(value);
								}}
								loading={!cropOptions || cropOptions.length === 0}
							/>
						</Grid.Column>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-field">Field</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-state"
								options={fieldOptions}
								value={fieldId}
								disabled={loadingSearchResults}
								search
								onChange={(event, { value }) => {
									setFieldId(value);
								}}
								loading={!fieldOptions || fieldOptions.length === 0}
							/>
						</Grid.Column>
					</Grid.Row>

					<Grid.Row>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-trial">Trial</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-trial"
								options={trialOptions}
								value={trialId}
								disabled={loadingSearchResults}
								search
								onChange={(event, { value }) => {
									setTrialId(value);
								}}
								loading={!trialOptions || trialOptions.length === 0}
							/>
						</Grid.Column>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-trial-owners">Trial Owners</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-trial-owner"
								search
								options={trialOwnerOptions}
								value={trialOwnerUserId}
								disabled={loadingSearchResults}
								onChange={(event, { value }) => {
									setTrialOwnerUserId(value);
								}}
								loading={!trialOwnerOptions || trialOwnerOptions.length === 0}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-company">Company</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-company"
								options={companyOptions}
								value={companyId}
								disabled={loadingSearchResults}
								search
								onChange={(event, { value }) => {
									setCompanyId(value);
								}}
								loading={!companyOptions || companyOptions.length === 0}
							/>
						</Grid.Column>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-cooperator-company">Cooperator</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-cooperator-company"
								options={cooperatorCompanyOptions}
								value={cooperatorCompanyId}
								disabled={loadingSearchResults}
								search
								onChange={(event, { value }) => {
									setCooperatorCompanyId(value);
								}}
								loading={!cooperatorCompanyOptions || cooperatorCompanyOptions.length === 0}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-search-all-clients">Search All Clients</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Checkbox
								id="form-search-all-clients"
								checked={searchAllClients}
								disabled={loadingSearchResults}
								onChange={(e, { checked }) => {
									setSearchAllClients(checked);
								}}
							/>
						</Grid.Column>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-select-annotation-status">Annotation Status</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Select
								id="form-select-annotation-status"
								options={defStatusOptions}
								value={defStatus}
								disabled={loadingSearchResults}
								search
								onChange={(event, { value }) => {
									setDEFStatus(value);
								}}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row style={{ paddingBottom: "unset" }}>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label style={{ minWidth: 80, opacity: 0.75 }}>* Required</label>
							</Form.Field>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row textAlign="right">
						<Grid.Column>
							<Form.Field>
								<Button
									id="form-button-clear-filter"
									content="Reset"
									disabled={loadingSearchResults}
									onClick={resetFilter}
								/>
								<Button id="form-button-filter" primary disabled={loadingSearchResults} content="Filter" />
							</Form.Field>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Form>
			{loadingSearchResults ? (
				<Loader active inline="centered" />
			) : (
				<TrialSearchResults trialList={filteredTrialList} trialStatuses={trialStatuses} />
			)}
		</Segment>
	);
};

export default TrialSearch;
