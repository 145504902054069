// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui.form .disabled.field,\n.ui.form .disabled.fields .field,\n.ui.form .field :disabled {\n\topacity: 0.75 !important;\n}\n\n.sticky {\n\tz-index: 1;\n}\n\n.ribbonBanner.ui.segment {\n\tposition: sticky;\n\ttop: 0;\n\tz-index: 1050;\n\tmargin: 0 -2em;\n\tborder-radius: unset;\n\tborder: unset;\n\tborder-top: 1px solid rgba(34, 36, 38, 0.15);\n}\n", "",{"version":3,"sources":["webpack://./src/components/Content/Protocols/ProtocolAnalysis/style.css"],"names":[],"mappings":"AAAA;;;CAGC,wBAAwB;AACzB;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,gBAAgB;CAChB,MAAM;CACN,aAAa;CACb,cAAc;CACd,oBAAoB;CACpB,aAAa;CACb,4CAA4C;AAC7C","sourcesContent":[".ui.form .disabled.field,\n.ui.form .disabled.fields .field,\n.ui.form .field :disabled {\n\topacity: 0.75 !important;\n}\n\n.sticky {\n\tz-index: 1;\n}\n\n.ribbonBanner.ui.segment {\n\tposition: sticky;\n\ttop: 0;\n\tz-index: 1050;\n\tmargin: 0 -2em;\n\tborder-radius: unset;\n\tborder: unset;\n\tborder-top: 1px solid rgba(34, 36, 38, 0.15);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui": "ui",
	"form": "form",
	"disabled": "disabled",
	"field": "field",
	"fields": "fields",
	"sticky": "sticky",
	"ribbonBanner": "ribbonBanner",
	"segment": "segment"
};
export default ___CSS_LOADER_EXPORT___;
