import React, { useState } from "react";
import PropTypes from "prop-types";
import { Table, Icon, Modal, Loader } from "semantic-ui-react";
import _ from "lodash";
import "./styles.css";
import moment from "moment";

const AnnotationSummaryModal = ({
	open,
	setOpen,
	trialName,
	annotationSummaryData,
	setAnnotationSummaryData,
	annotationSummaryDataLoader,
	setAnnotationSummaryDataLoader
}) => {

	//-- Sorting
	const [sortColumn, setSortColumn] = useState("");
	const [sortDirection, setSortDirection] = useState("");
	const DATE_FORMAT = "M/D/YYYY";

	const handleSort = (clickedColumn) => {
		if (sortColumn !== clickedColumn) {
			setSortColumn(clickedColumn);
			if (clickedColumn.match(/date/i)) {
				//-- https://thomaskekeisen.de/en/blog/array-date-sort-lodash-momentjs/
				setAnnotationSummaryData(
					_.sortBy(
						annotationSummaryData,
						function (d) {
							return moment(d[clickedColumn], DATE_FORMAT);
						},
						[sortDirection === "ascending" ? "asc" : "desc"]
					)
				);
			} else if (clickedColumn.match(/num|plotId|rep|altitude|total|treatment/i)) {
				setAnnotationSummaryData(_.sortBy(annotationSummaryData, [clickedColumn]));
			} else {
				setAnnotationSummaryData(
					_.sortBy(annotationSummaryData, [(d) => (d[clickedColumn] ? d[clickedColumn].toLowerCase() : d[clickedColumn])])
				);
			}

			setSortDirection("ascending");
			return;
		}

		setSortDirection(sortDirection === "ascending" ? "descending" : "ascending");
		setAnnotationSummaryData(annotationSummaryData.reverse());
	};
	return (
		<Modal onClose={() => { setOpen(false); setAnnotationSummaryData([]); setAnnotationSummaryDataLoader(true); setSortColumn(""); setSortDirection(null) }} onOpen={() => setOpen(true)} open={open} style={{ width: "60%", border: "none", marginTop: "100px" }}>
			<Modal.Header>
				Annotation Summary for Trial {trialName}
				{annotationSummaryDataLoader ? <span style={{ float: "right", marginRight: "50px" }}><Loader inline active></Loader> Getting Annotation Data...</span> : null}
				<Icon name="close" link onClick={() => { setOpen(false); setAnnotationSummaryData([]); setAnnotationSummaryDataLoader(true); setSortColumn(""); setSortDirection(null) }} />
			</Modal.Header>
			<Modal.Content>
				<div className="tableFixHead">
					<Table celled striped sortable textAlign="center">
						<Table.Header>
							<Table.Row id="annotationSummaryModal">
								<Table.HeaderCell
									sorted={sortColumn === 'treatment' ? sortDirection : null}
									onClick={() => handleSort('treatment')}>Treatment</Table.HeaderCell>
								<Table.HeaderCell
									sorted={sortColumn === 'plotId' ? sortDirection : null}
									onClick={() => handleSort('plotId')}>Plot</Table.HeaderCell>
								<Table.HeaderCell
									sorted={sortColumn === 'rep' ? sortDirection : null}
									onClick={() => handleSort('rep')}>Rep</Table.HeaderCell>
								<Table.HeaderCell
									sorted={sortColumn === 'annotationType' ? sortDirection : null}
									onClick={() => handleSort('annotationType')}>Annotation Type</Table.HeaderCell>
								<Table.HeaderCell
									sorted={sortColumn === 'annotationClass' ? sortDirection : null}
									onClick={() => handleSort('annotationClass')}>Annotation Class</Table.HeaderCell>
								<Table.HeaderCell>Subsamples</Table.HeaderCell>
								<Table.HeaderCell>Date Range</Table.HeaderCell>
								<Table.HeaderCell
									sorted={sortColumn === 'user' ? sortDirection : null}
									onClick={() => handleSort('user')}>User</Table.HeaderCell>
								<Table.HeaderCell
									sorted={sortColumn === 'addedOn' ? sortDirection : null}
									onClick={() => handleSort('addedOn')}>Added On</Table.HeaderCell>
								<Table.HeaderCell>Notes</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{_.map(annotationSummaryData, (asd) => {
								// map each row
								return (
									<Table.Row>
										<Table.Cell>
											{asd.treatment}
										</Table.Cell>
										<Table.Cell>
											{asd.plotId}
										</Table.Cell>
										<Table.Cell>
											{asd.rep}
										</Table.Cell>
										<Table.Cell>
											{asd.annotationType}
										</Table.Cell>
										<Table.Cell>
											{asd.annotationClass}
										</Table.Cell>
										<Table.Cell>
											{asd.subsample}
										</Table.Cell>
										<Table.Cell>
											{asd.dateRange}
										</Table.Cell>
										<Table.Cell>
											{asd.user}
										</Table.Cell>
										<Table.Cell>
											{asd.addedOn}
										</Table.Cell>
										<Table.Cell fixed style={{ maxWidth: "400px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
											{asd.note}
										</Table.Cell>
									</Table.Row>
								);
							})}
						</Table.Body>
					</Table>
				</div>
			</Modal.Content>
		</Modal >
	);
};

AnnotationSummaryModal.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	trialName: PropTypes.string.isRequired,
	annotationSummaryData: PropTypes.array.isRequired,
	setAnnotationSummaryData: PropTypes.func.isRequired,
	annotationSummaryDataLoader: PropTypes.bool.isRequired,
	setAnnotationSummaryDataLoader: PropTypes.bool.isRequired
};

export default AnnotationSummaryModal;
