import React, { useEffect, useState } from "react";
import { Table, Loader, Segment, Button, Icon } from "semantic-ui-react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";
import "./style.scss";

import {
	NEUTRAL_CORRELATION_COLOR,
	NEGATIVE_CORRELATION_COLOR,
	POSITIVE_CORRELATION_COLOR
} from "../CorrelationColors";
import CorrelationColorGradient from "../CorrelationColorGradient";
import calculateOverlappingCorrelationValue from "../CalculateOverlappingCorrelationValue";

const TimecourseCorrelationMatrix = ({
	plotAnalysisData,
	anchorAssessmentData,
	colors,
	selectedQuantifiedRegionTypeId
}) => {
	const [structuredPlotAnalysisData, setStructuredPlotAnalysisData] = useState(null);
	const [structuredPlotAnalysisDataLoading, setStructuredPlotAnalysisDataLoading] = useState(true);
	const [processedData, setProcessedData] = useState(null);
	const [processedDataLoading, setProcessedDataLoading] = useState(true);
	const [sortedFlightDataLoading, setSortedFlightDataLoading] = useState(true);
	const [sortedFlightData, setSortedFlightData] = useState(true);
	const [flightDateLookup, setflightDateLookup] = useState([]);
	const [analysisNameLookup, setAnalysisNameLookup] = useState([]);
	const [columnHeaders, setColumnHeaders] = useState(null);
	const [dataRows, setDataRows] = useState([]);

	// 1. When the plot analysis data changes, structure it.
	useEffect(() => {
		if (plotAnalysisData) {
			structurePlotAnalysisData();
		}
	}, [plotAnalysisData, anchorAssessmentData, selectedQuantifiedRegionTypeId]);

	// 2. Once the structured data becomes available, process the data (calculate pearson coefficients).
	useEffect(() => {
		if (structuredPlotAnalysisDataLoading === false) {
			processStructuredData();
		}
	}, [structuredPlotAnalysisData]);

	useEffect(() => {
		if (processedDataLoading === false) {
			sortFlightData();
		}
	}, [processedData]);

	// Put the analysis data in a structure to be easily iterated when calculating pearson values.
	function structurePlotAnalysisData() {
		let structuredDataTemp = {};

		let localAnalysisNameLookup = analysisNameLookup;
		let localFlightDateLookup = flightDateLookup;

		let filteredQuantifiedRegionData = _.cloneDeep(plotAnalysisData);
		filteredQuantifiedRegionData = _.filter(filteredQuantifiedRegionData, (d) => {
			return d.quantifiedRegionTypeId === selectedQuantifiedRegionTypeId;
		});

		let analysisGroupedData = _.groupBy(filteredQuantifiedRegionData, "analysisId");
		for (const [analysisKey, analysisGrouping] of Object.entries(analysisGroupedData)) {
			structuredDataTemp[analysisKey] = {};

			// Create a lookup entry from the analysis to it's abbreviation.
			if (!Object.keys(analysisNameLookup).includes(analysisKey)) {
				localAnalysisNameLookup[analysisKey] = _.find(filteredQuantifiedRegionData, {
					analysisId: analysisKey
				}).analysisName;

				setAnalysisNameLookup(localAnalysisNameLookup);
			}

			let flightGroupedData = _.groupBy(analysisGrouping, "flightId");
			for (const [flightKey, flightIdGrouping] of Object.entries(flightGroupedData)) {
				let currentFlightDate = new Date(
					_.find(filteredQuantifiedRegionData, {
						flightId: flightKey
					}).flightDate
				);

				// Create a lookup entry from the flight to it's date.
				if (!Object.keys(flightDateLookup).includes(flightKey)) {
					localFlightDateLookup[flightKey] = currentFlightDate;
					setflightDateLookup(localFlightDateLookup);
				}

				// Place the raw data values into the structured array to be processed.
				structuredDataTemp[analysisKey][flightKey] = flightIdGrouping;
			}
		}

		setStructuredPlotAnalysisData(structuredDataTemp);
		setStructuredPlotAnalysisDataLoading(false);
	}

	// Calculate and store the pearson values.
	function processStructuredData() {
		let processedDataTemp = [];

		for (const [analysisId] of Object.entries(structuredPlotAnalysisData)) {
			processedDataTemp[analysisId] = {};

			for (const [flightId] of Object.entries(structuredPlotAnalysisData[analysisId])) {
				// Build the list of values from the plot analysis data to be used as a parameter to the pearson function.
				let values = [];
				structuredPlotAnalysisData[analysisId][flightId].forEach((obj) => {
					values.push({
						plotRange: obj.plotRange,
						plotColumn: obj.plotColumn,
						value: obj.value,
						plotId: obj.plotId,
						excludeFromAssessment: obj.excludeFromAssessment
					});
				});

				// Calculate and store the pearson data per flight & analysis composite key.
				processedDataTemp[analysisId][flightId] = calculateOverlappingCorrelationValue(values, anchorAssessmentData);
			}
		}

		setProcessedData(processedDataTemp);
		setProcessedDataLoading(false);
	}

	function sortFlightData() {
		let flightArray = Object.keys(flightDateLookup).map(function (key) {
			return [key, flightDateLookup[key]];
		});
		let sortedFlightArray = flightArray.sort(function (a, b) {
			return a[1] - b[1];
		});

		setSortedFlightData(sortedFlightArray);
		setSortedFlightDataLoading(false);
	}

	// When the pearson values have been calculated, build the table to display.
	useEffect(() => {
		if (processedDataLoading == false && sortedFlightDataLoading == false) {
			let localColumnHeaderElements = [<Table.HeaderCell key={"empty-column-header-cell"}></Table.HeaderCell>]; //_.cloneDeep(columnHeaders);
			let localDataRowElements = []; //_.cloneDeep(dataRows);

			// Create a column header for each available flight date.
			const DATE_FORMAT = "MM/DD/YYYY h:mma";
			for (let index = 0; index < sortedFlightData.length; index++) {
				localColumnHeaderElements.push(
					<Table.HeaderCell key={sortedFlightData[index][0] + "-column-header-cell"} style={{ textAlign: "center" }}>
						{moment(flightDateLookup[sortedFlightData[index][0]]).format(DATE_FORMAT)}
					</Table.HeaderCell>
				);
			}

			for (const [analysisId] of Object.entries(structuredPlotAnalysisData)) {
				// Create a row for the current analysis.
				let currentRowCells = [
					<Table.Cell
						key={analysisId + "-row-header-cell"}
						style={{
							fontWeight: "bold"
						}}
					>
						{analysisNameLookup[analysisId]}
					</Table.Cell>
				];

				// Create a cell in the current row for each flight.
				for (let index = 0; index < sortedFlightData.length; index++) {
					let flightId = sortedFlightData[index][0];

					let cellColor = CorrelationColorGradient(
						colors.negativeColor,
						colors.neutralColor,
						colors.positiveColor,
						-1,
						1,
						processedData[analysisId][flightId]
					);

					let targetValue = processedData[analysisId][flightId];
					currentRowCells.push(
						<Table.Cell
							key={flightId + "-data-cell"}
							style={{
								textAlign: "center",
								backgroundColor: "rgb(" + cellColor.red + "," + cellColor.green + "," + cellColor.blue + ")"
							}}
						>
							{targetValue ? targetValue.toFixed(3) : "-"}
						</Table.Cell>
					);
				}

				localDataRowElements.push(<Table.Row key={analysisId + "-data-row"}>{currentRowCells}</Table.Row>);
			}

			setColumnHeaders(localColumnHeaderElements);
			setDataRows(localDataRowElements);
		}
	}, [processedData, sortedFlightData, anchorAssessmentData]);

	return (
		<Segment basic style={{ overflow: "auto" }}>
			{sortedFlightDataLoading ? (
				<Loader active={true} />
			) : (
				<div style={{ border: "1px solid" }}>
					<TransformWrapper
						options={{
							disabled: false,
							limitToWrapper: false,
							limitToBounds: false,
							minScale: "0.5",
							centerContent: false
						}}
						defaultScale={1}
						defaultPositionX={0}
						defaultPositionY={0}
						wheel={{
							wheelEnabled: false
						}}
						style={{
							display: "block"
						}}
						zoomOut={{
							step: 5
						}}
						zoomIn={{
							step: 5
						}}
					>
						{({ zoomIn, zoomOut, resetTransform }) => (
							<React.Fragment>
								<div className="tools" style={{ float: "left" }}>
									<Button icon onClick={zoomIn}>
										<Icon name="plus" />
									</Button>
									<Button icon onClick={zoomOut}>
										<Icon name="minus" />
									</Button>
									<Button icon onClick={resetTransform}>
										<Icon name="repeat" />
									</Button>
								</div>
								<TransformComponent>
									<Table className="timecourse-correlation-table" celled>
										<Table.Header>{columnHeaders}</Table.Header>
										<Table.Body>{dataRows}</Table.Body>
									</Table>
								</TransformComponent>
							</React.Fragment>
						)}
					</TransformWrapper>
				</div>
			)}
		</Segment>
	);
};

TimecourseCorrelationMatrix.propTypes = {
	plotAnalysisData: PropTypes.array,
	anchorAssessmentData: PropTypes.array,
	colors: PropTypes.object,
	selectedQuantifiedRegionTypeId: PropTypes.string
};

TimecourseCorrelationMatrix.defaultProps = {
	plotAnalysisData: [],
	anchorAssessmentData: [],
	colors: {
		negativeColor: NEGATIVE_CORRELATION_COLOR,
		neutralColor: NEUTRAL_CORRELATION_COLOR,
		positiveColor: POSITIVE_CORRELATION_COLOR
	}
};

export default TimecourseCorrelationMatrix;
