import PropTypes from "prop-types";
import React, { useState } from "react";

import { Link } from "react-router-dom";

import _ from "lodash";

import Tooltip from "rc-tooltip";

import { Icon, Menu } from "semantic-ui-react";

import { useUserAuth } from "../../../hooks/useUserAuth";

import { useModuleNavigation } from "../../../hooks/useModuleNavigation";
import "./style.css";
import AdminTools from "../../Lumber/AdminTools";

const LeftNavSecondary = ({ navItems, activeItem }) => {
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();

	const [adminToolsOpen, setAdminToolsOpen] = useState(false);

	function moduleIsInContext(moduleName) {
		var modInContext = moduleNavigation.moduleIsInContext(moduleName);
		return modInContext;
	}

	return (
		<Menu
			id="leftNavSecondary"
			vertical
			floated
			borderless
			style={{
				width: "55px",
				backgroundColor: "#597eaa",
				border: "unset",
				borderRadius: "unset",
				margin: "unset"
			}}
		>
			{_.map(navItems, (navItem, index) => {
				return (
					navItem.enabled &&
					navItem.showInNav &&
					userAuth.userCanAccessSubmodule(navItem) && (
						<Tooltip
							key={index}
							placement="right"
							align={{ offset: [-8, 0] }}
							mouseEnterDelay={0}
							mouseLeaveDelay={0}
							trigger="hover"
							overlay={<p>{navItem.linkText}</p>}
							transitionName="rc-tooltip-zoom"
						>
							<Menu.Item
								key={navItem.id}
								as={Link}
								to={navItem.link}
								style={{ height: 50 }}
								className={!moduleIsInContext(navItem.module) && navItem.requiresContext ? "disabledNavItem" : null}
								active={activeItem.name === navItem.name || navItem.navMatchingNames?.includes(activeItem.name)}
								onClick={(e) => {
									if (!moduleIsInContext(navItem.module) && navItem.requiresContext) {
										e.preventDefault();
									}
								}}
							>
								<Icon.Group>
									{navItem.cornerIcon ? (
										<>
											<Icon name={navItem.icon} corner="top left" style={{ fontSize: 22, textShadow: "unset" }} />
											<Icon name={navItem.cornerIcon} corner="bottom right" style={{ fontSize: 12 }} />
											{/* textShadow: "-1px -1px 0 rgb(255, 255, 255, 1), 1px -1px 0 rgb(255, 255, 255, 1), -1px 1px 0 rgb(255, 255, 255, 1), 1px 1px 0 rgb(255, 255, 255, 1)" */}
										</>
									) : (
										<Icon name={navItem.icon} size="large" />
									)}
								</Icon.Group>
							</Menu.Item>
						</Tooltip>
					)
				);
			})}
			{["trials", "flights", "protocols", "fields"].includes(moduleNavigation.activeModule.name) &&
				userAuth.isApAdmin && (
					<Tooltip
						placement="right"
						align={{ offset: [-8, 0] }}
						mouseEnterDelay={0}
						mouseLeaveDelay={0}
						trigger="hover"
						overlay={<p>Admin Tools</p>}
						transitionName="rc-tooltip-zoom"
					>
						<Menu.Item
							style={{ height: 50, position: "fixed", bottom: 0 }}
							position="right"
							//className={!moduleIsInContext(navItem.module) && navItem.requiresContext ? "disabledNavItem" : null}
							onClick={() => {
								setAdminToolsOpen(!adminToolsOpen);
							}}
						>
							<Icon.Group>
								<Icon name="cog" size="large" />
								<div className="adminText">ADMIN</div>
							</Icon.Group>
						</Menu.Item>
					</Tooltip>
				)}
			{adminToolsOpen && (
				<AdminTools
					style={{ position: "fixed", bottom: 0, left: 110, zIndex: 10 }}
					handleClose={() => {
						setAdminToolsOpen(false);
					}}
				/>
			)}
		</Menu>
	);
};

LeftNavSecondary.propTypes = {
	navItems: PropTypes.array.isRequired,
	activeItem: PropTypes.object.isRequired
};

export default LeftNavSecondary;
