import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as queryString from "query-string";
import * as trialActions from "../../../../redux/actions/trialActions";

import { Loader, Segment, Menu, Message } from "semantic-ui-react";

import { useUserAuth } from "../../../../hooks/useUserAuth";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useHistory, useLocation } from "react-router-dom";
import { useUserSettings } from "../../../../hooks/useUserSettings";
import { useAuth0 } from "../../../../auth/auth0";

import SummaryTable from "../../Widgets/SummaryTable";
import AnalysisBoxWhiskerChart from "../../Widgets/Charts/analysisBoxWhiskerChart";
import Timecourse from "../../Widgets/Timecourse";
import CorrelationPlots from "../../Widgets/Charts/correlationPlots";
import CorrelationMatrix from "../../Widgets/CorrelationMatrix";

import TrialFavoritingWidget from "../../Widgets/Favorites/TrialFavoritingWidget";
import { ApplicationArea } from "../../../Lumber/ApplicationAreas";

const TrialAnalysis = () => {
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const entityNames = useSelector((state) => (state.entityNames ? state.entityNames : null));
	const { getTokenSilently } = useAuth0();

	const userSettings = useUserSettings();

	const urlQueryStrings = queryString.parse(location.search);
	const qsAt = urlQueryStrings.at;

	//-- Data
	const [trialName, setTrialName] = useState(null);

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [activeItem, setActiveItem] = useState(summaryTable);
	const [correlationChartEnabled] = useState(false);
	const [dataDone, setDataDone] = useState(true);

	const summaryTable = "summary-table";
	const boxWhiskerChart = "box-whisker";
	const timecourse = "timecourse";
	const correlationPlots = "correlation-plots";
	const correlationMatrix = "correlation-matrix";

	useEffect(() => {
		if (userAuth.isReady) {
			setActiveItem(getAnalysisTypeFromQueryString());
			getPlotAnalysisStatus();
		}
	}, [userAuth.isReady, location.search]);

	async function getPlotAnalysisStatus() {
		const accessToken = await getTokenSilently();
		const res = await dispatch(
			trialActions.getPlotAnalysisStatusForTrial(moduleNavigation.trialId, userAuth.currentClientId, accessToken)
		);
		setDataDone(res);
	}

	function getAnalysisTypeFromQueryString() {
		if (
			!userAuth.hasApplicationArea(ApplicationArea.TrialAnalysisTimecourse, userAuth.currentClientId) ||
			!userAuth.hasApplicationArea(ApplicationArea.TrialAnalysisSummaryTable, userAuth.currentClientId)
		) {
			return boxWhiskerChart;
		} else {
			switch (qsAt) {
				case summaryTable:
					return summaryTable;
				case boxWhiskerChart:
					return boxWhiskerChart;
				case correlationPlots:
					return correlationPlots;
				case correlationMatrix:
					return correlationMatrix;
				default:
				case timecourse:
					return timecourse;
			}
		}
	}

	useEffect(() => {
		if (entityNames.trialId && moduleNavigation.trialId?.toUpperCase() === entityNames.trialId?.toUpperCase()) {
			setTrialName(
				`${entityNames.trialName} (${entityNames.cooperatorName}, ${entityNames.city}, ${entityNames.state})`
			);
			setLoading(false);
		}
	}, [entityNames]);

	return loading || !userAuth.isReady ? (
		<Loader active />
	) : !loading && !trialName && userAuth.isReady ? (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2>Trial Analysis</h2>
			<hr />
			<Segment>
				<p>{"We could not find analysis data for this trial."}</p>
			</Segment>
		</Segment>
	) : (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2 style={{ float: "left" }}>Trial Analysis</h2>
			<div style={{ float: "right" }}>
				<TrialFavoritingWidget
					style={{ display: "inline" }}
					clientId={userAuth.currentClientId.toUpperCase()}
					trialId={moduleNavigation.trialId.toUpperCase()}
					userSettings={userSettings}
				></TrialFavoritingWidget>
				<h2 style={{ display: "inline", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
					<i>{trialName}</i>
				</h2>
			</div>
			<hr style={{ clear: "both" }} />
			<Menu pointing>
				<Menu.Item
					name="Timecourse"
					active={activeItem === timecourse}
					onClick={() => {
						history.push(`?at=${timecourse}`);
					}}
					disabled={!userAuth.hasApplicationArea(ApplicationArea.TrialAnalysisTimecourse, userAuth.currentClientId)}
				/>
				<Menu.Item
					name="Box Whisker"
					active={activeItem === boxWhiskerChart}
					onClick={() => {
						history.push(`?at=${boxWhiskerChart}`);
					}}
					disabled={!userAuth.hasApplicationArea(ApplicationArea.TrialAnalysisBoxWhisker, userAuth.currentClientId)}
				/>
				<>
					<Menu.Item
						name="Summary Table"
						active={activeItem === summaryTable}
						onClick={() => {
							history.push(`?at=${summaryTable}`);
						}}
						disabled={!userAuth.hasApplicationArea(ApplicationArea.TrialAnalysisSummaryTable, userAuth.currentClientId)}
					/>
				</>
				<Menu.Item
					name="Correlation Matrix"
					active={activeItem === correlationMatrix}
					onClick={() => {
						history.push(`?at=${correlationMatrix}`);
					}}
					disabled={
						!userAuth.hasApplicationArea(ApplicationArea.TrialAnalysisCorrelationMatrix, userAuth.currentClientId)
					}
				/>
			</Menu>
			{!dataDone && (
				<Message warning>
					Plot analysis data for this trial is currently being recalculated, so data may not be complete or up to date.
					Please refresh the page in a few minutes.
				</Message>
			)}
			{activeItem === summaryTable ? <SummaryTable /> : null}
			{activeItem === boxWhiskerChart ? <AnalysisBoxWhiskerChart /> : null}
			{activeItem === timecourse ? <Timecourse /> : null}
			{activeItem === correlationPlots && correlationChartEnabled ? <CorrelationPlots /> : null}
			{activeItem === correlationMatrix ? <CorrelationMatrix /> : null}
		</Segment>
	);
};

export default TrialAnalysis;
