// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".confirmAction {\n\tanimation: shake 300ms;\n}\n\n@keyframes shake {\n\t0% {\n\t\ttransform: translate(3px, 0px);\n\t}\n\t20% {\n\t\ttransform: translate(-3px, 0px);\n\t}\n\t40% {\n\t\ttransform: translate(2px, 0px);\n\t}\n\t60% {\n\t\ttransform: translate(-2px, 0px);\n\t}\n\t80% {\n\t\ttransform: translate(1px, 0px);\n\t}\n\t100% {\n\t\ttransform: translate(-1px, 0px);\n\t}\n}\n", "",{"version":3,"sources":["webpack://./src/components/Lumber/TreatmentCSVUpload/styles.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;AACvB;;AAEA;CACC;EACC,8BAA8B;CAC/B;CACA;EACC,+BAA+B;CAChC;CACA;EACC,8BAA8B;CAC/B;CACA;EACC,+BAA+B;CAChC;CACA;EACC,8BAA8B;CAC/B;CACA;EACC,+BAA+B;CAChC;AACD","sourcesContent":[".confirmAction {\n\tanimation: shake 300ms;\n}\n\n@keyframes shake {\n\t0% {\n\t\ttransform: translate(3px, 0px);\n\t}\n\t20% {\n\t\ttransform: translate(-3px, 0px);\n\t}\n\t40% {\n\t\ttransform: translate(2px, 0px);\n\t}\n\t60% {\n\t\ttransform: translate(-2px, 0px);\n\t}\n\t80% {\n\t\ttransform: translate(1px, 0px);\n\t}\n\t100% {\n\t\ttransform: translate(-1px, 0px);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmAction": "confirmAction",
	"shake": "shake"
};
export default ___CSS_LOADER_EXPORT___;
