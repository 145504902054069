import FlightLog from "./Content/Flights/FlightLog";
import FlightProcess from "./Content/Flights/FlightProcess";
import FlightViewer from "./Content/Flights/FlightViewer";

import { guidRegex } from "../utilities/regex";
import { ApplicationArea } from "./Lumber/ApplicationAreas";

//-- enabled has no effect if the parent module (i.e. the module refrencing these routs in routs.js), is disabled
//--  That is, the enabled property cannot override the parent module enabled property if it is disabled

export const flightRoutes = [
	{
		id: "flight-log",
		name: "log",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Log",
		icon: "search",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/flights`, `/flights/:id${guidRegex}?/log`],
		exact: true,
		component: FlightLog,
		module: "flights",
		applicationAreas:[ApplicationArea.FlightSearch],
		requiresContext: false,
		showModuleIdInBreadcrumb: false
	},
	{
		id: "flight-new",
		name: "new",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "New",
		icon: "paper plane outline",
		cornerIcon: "plus",
		showInNav: true,
		secured: true,
		paths: [`/flights/:id${guidRegex}?/new`],
		exact: true,
		component: () => new FlightProcess({ activeStep: "info", mode: "new" }),
		module: "flights",
		applicationAreas:[ApplicationArea.FlightNew],
		requiresContext: false,
		showModuleIdInBreadcrumb: false
	},
	{
		id: "flight-info",
		navMatchingNames: ["info", "ortho-upload", "align-fields", "add-new-fields"],
		name: "info",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Edit",
		icon: "paper plane outline",
		cornerIcon: "pencil",
		showInNav: true,
		secured: true,
		paths: [`/flights/:id${guidRegex}?/info`],
		exact: true,
		component: () => new FlightProcess({ activeStep: "info", mode: "edit" }),
		module: "flights",
		applicationAreas:[ApplicationArea.FlightInfo, ApplicationArea.FlightEdit],
		requiresContext: true,
		showModuleIdInBreadcrumb: false
	},
	{
		id: "flight-ortho-upload",
		navMatchingNames: ["info", "ortho-upload", "align-fields", "add-new-fields"],
		name: "ortho-upload",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Ortho Upload",
		icon: "picture",
		cornerIcon: null,
		showInNav: false,
		secured: true,
		paths: [`/flights/:id${guidRegex}/ortho-upload`],
		exact: true,
		component: () => new FlightProcess({ activeStep: "orthoUpload" }),
		module: "flights",
		applicationAreas:[ApplicationArea.FlightOrthoUpload, ApplicationArea.FlightGenerateOrtho, ApplicationArea.FlightDownloadOrtho],
		requiresContext: true
	},
	{
		id: "flight-align-fields",
		navMatchingNames: ["info", "ortho-upload", "align-fields", "add-new-fields"],
		name: "align-fields",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Align Fields",
		icon: null,
		cornerIcon: null,
		showInNav: false,
		secured: true,
		paths: [`/flights/:id${guidRegex}/align-fields`],
		exact: true,
		component: () => new FlightProcess({ activeStep: "alignFields" }),
		module: "flights",
		applicationAreas:[ApplicationArea.FlightAlignFields],
		requiresContext: true
	},
	{
		id: "flight-add-new-fields",
		name: "add-new-fields",
		navMatchingNames: ["info", "ortho-upload", "align-fields", "add-new-fields"],
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Add New Fields",
		icon: null,
		cornerIcon: null,
		showInNav: false,
		secured: true,
		paths: [`/flights/:id${guidRegex}/add-new-fields`],
		exact: true,
		component: () => new FlightProcess({ activeStep: "addNewFields" }),
		module: "flights",
		applicationAreas:[ApplicationArea.FlightAddNewFields],
		requiresContext: true
	},
	{
		id: "flight-plot-analysis",
		name: "plot-analysis",
		navMatchingNames: [],
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Analysis",
		icon: "chart bar outline",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/flights/:id${guidRegex}/plot-analysis`],
		exact: true,
		component: () => new FlightProcess({ activeStep: "plotAnalysis" }),
		module: "flights",
		applicationAreas:[ApplicationArea.FlightPlotAnalysis, ApplicationArea.FlightPlotAnalysisCRUD],
		requiresContext: true
	},
	{
		id: "flight-viewer",
		name: "viewer",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Viewer",
		icon: "paper plane outline",
		cornerIcon: "binoculars",
		showInNav: true,
		secured: true,
		paths: [`/flights/:id${guidRegex}`, `/flights/:id${guidRegex}/viewer`],
		exact: true,
		component: FlightViewer,
		module: "flights",
		applicationAreas:[ApplicationArea.FlightViewer],
		requiresContext: true
	}
];

export default {
	flightRoutes
};
