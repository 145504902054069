import React, { useState } from "react";
import { Icon, Modal, Grid, Button } from "semantic-ui-react";
import * as trialActions from "../../../../redux/actions/trialActions";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useAuth0 } from "../../../../auth/auth0";
import { produce } from "immer";
//import moment from "moment";

const RemoveTrialOwnerSponsorModal = ({
	open,
	setOpen,
	trialId,
	clientId,
	removeTrialOwnerOrSponsor,
	setWorkingTrialSilently,
	workingTrial
}) => {
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();
	const [isRemoving, setIsRemoving] = useState(false);

	const removeTrialOwnerSponsor = async () => {
		const accessToken = await getTokenSilently();
		setIsRemoving(true);

		if (removeTrialOwnerOrSponsor === "Owner") {
			dispatch(trialActions.removeTrialOwner(trialId, clientId, accessToken))
				.then((res) => {
					if (res.statusCode === 200) {
						toast.success("Trial Owner removed successfully");

						const newWorkingTrial = produce(workingTrial, (draft) => {
							draft.trialOwnerUserId = null;
							draft.trialOwnerPersonId = null;
							draft.trialOwnerIsPending = false;
							draft.trialOwnerClientId = null;
							draft.trialOwnerClientName = null;
						});
						setWorkingTrialSilently(newWorkingTrial);
					}

					setIsRemoving(false);
					setOpen(false);
				})
				.catch(() => {
					setIsRemoving(false);
					toast.error("Failed to remove trial owner");
				});
		}
		else if (removeTrialOwnerOrSponsor === "Sponsor") {
			dispatch(trialActions.removeTrialSponsor(trialId, clientId, accessToken))
				.then((res) => {
					if (res.statusCode === 200) {
						toast.success("Trial sponsor removed successfully");

						const newWorkingTrial = produce(workingTrial, (draft) => {
							draft.trialSponsorUserId = null;
							draft.trialSponsorIsPending = false;
							draft.trialSponsorClientId = null;
							draft.trialSponsorClientName = null;
						});
						setWorkingTrialSilently(newWorkingTrial);
					}

					setIsRemoving(false);
					setOpen(false);
				})
				.catch(() => {
					setIsRemoving(false);
					toast.error("Failed to remove trial sponsor");
				});
		}
	};


	return (
		<Modal onClose={() => { setOpen(false) }} onOpen={() => setOpen(true)} open={open} style={{ width: "30%", border: "none" }}>
			<Modal.Header>
				Remove Trial {removeTrialOwnerOrSponsor.toLowerCase()}
				<Icon name="close" link onClick={() => { setOpen(false) }} />
			</Modal.Header>
			<Modal.Content>
				<b>Are you sure you want to remove trial {removeTrialOwnerOrSponsor.toLowerCase()} ? {removeTrialOwnerOrSponsor === "Owner" ? "This will also unpublish any previously published flights." : null}</b>
				<Grid>
					<Grid.Row>
						<Grid.Column floated="right" width="2" style={{ marginRight: "5%" }}>
							<Button onClick={() => { setOpen(false) }}>
								Cancel
							</Button>
						</Grid.Column>
						<Grid.Column width="2" style={{ marginRight: "5%" }}>
							<Button primary loading={isRemoving} onClick={() => { removeTrialOwnerSponsor() }}>
								Confirm
							</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Modal.Content>
		</Modal >
	);
};

RemoveTrialOwnerSponsorModal.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	trialId: PropTypes.string.isRequired,
	clientId: PropTypes.string.isRequired,
	removeTrialOwnerOrSponsor: PropTypes.bool.isRequired,
	setWorkingTrialSilently: PropTypes.func.isRequired,
	workingTrial: PropTypes.object.isRequired
};

export default RemoveTrialOwnerSponsorModal;