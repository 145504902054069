import PropTypes from "prop-types";
import React from "react";
import { Button, Modal } from "semantic-ui-react";

const ConfrimNavModal = ({ open, setOpen, onSave, onDiscard, saving = false, discarding = false }) => {
	return (
		<Modal open={open}>
			<Modal.Header>Confirm Navigation</Modal.Header>
			<Modal.Content>
				<Modal.Description>Page has unsaved changes</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button content="Cancel" onClick={() => setOpen(false)} disabled={saving || discarding} />
				<Button
					negative
					content="Discard Changes"
					loading={discarding}
					disabled={saving || discarding}
					onClick={() => {
						onDiscard();
					}}
				/>
				<Button
					style={{ margin: 0 }}
					positive
					content="Save and Continue"
					loading={saving}
					disabled={saving || discarding}
					onClick={() => {
						onSave();
					}}
				/>
			</Modal.Actions>
		</Modal>
	);
};

ConfrimNavModal.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
	onSave: PropTypes.func,
	onDiscard: PropTypes.func,
	saving: PropTypes.bool,
	discarding: PropTypes.bool
};

export default ConfrimNavModal;
