import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import * as queryString from "query-string";

import { moduleRoutes, moduleNames, allRoutes, loginRoute, homeRoute } from "../components/routes";

import { guidRegex, optionalRoutePathIdRegex, replaceAll, escapeRegExp } from "../utilities/regex";

export const useModuleNavigation = () => {
	const ENVIRONMENT = process.env.environment;

	const history = useHistory();

	const location = useLocation();
	let pathArray = location.pathname.split("/").filter((el) => el !== "");
	// console.log("location.pathname");
	// console.log(location.pathname);
	// console.log("pathArray");
	// console.log(pathArray);

	const tokenizedLocation = replaceAll(location.pathname, guidRegex, ":id");
	// console.log("tokenizedLocation");
	// console.log(tokenizedLocation);

	const routeMatch = useRouteMatch(tokenizedLocation);
	// console.log("routeMatch");
	// console.log(routeMatch);

	const urlQueryStrings = queryString.parse(location.search);
	// console.log("urlQueryStrings");
	// console.log(urlQueryStrings);

	const qsPtfk = urlQueryStrings.ptfk;
	// console.log(`qsPtfk: ${qsPtfk}`);

	const moduleIdsFromUrl = getModuleIdsFromUrl();

	const flightId = moduleIdsFromUrl.flights ?? null;
	const fieldId = moduleIdsFromUrl.fields ?? null;
	const trialId = moduleIdsFromUrl.trials ?? null;
	const datasetId = moduleIdsFromUrl["ground-data"] ?? null;
	const protocolId = moduleIdsFromUrl.protocols ?? null;

	const activeModule = getActiveModule(moduleIdsFromUrl);
	const currentSubmodules = getSubmodules(activeModule);
	const activeSubmodule = getActiveSubmodule(currentSubmodules);
	const currentPathIsEnabled = getCurrentPathIsEnabled();

	const timelineFlightId =
		activeSubmodule.name === "heatmap" ? moduleIdsFromUrl.heatmap ?? null : moduleIdsFromUrl.viewer ?? null;

	function getModuleIdsFromUrl() {
		const moduleIdMap = {};
		for (let i = 0; i < pathArray.length; i += 2) {
			const moduleName = pathArray[i];
			const moduleIdInContext = pathArray[i + 1]?.match(guidRegex) ? pathArray[i + 1] : null;
			moduleIdMap[moduleName] = moduleIdInContext;
		}

		// console.log("moduleIdMap");
		// console.log(moduleIdMap);
		return moduleIdMap;
	}

	function moduleIsInContext(moduleName) {
		const modIsInContext = Object.keys(moduleIdsFromUrl).some((mod) => {
			return mod === moduleName && moduleIdsFromUrl[moduleName];
		});
		return modIsInContext;
	}

	function getActiveModule(moduleIdsFromUrl) {
		// console.log(Object.keys(moduleIdsFromUrl)[Object.keys(moduleIdsFromUrl).length - 1]);
		// console.log(moduleIdsFromUrl[Object.keys(moduleIdsFromUrl)[Object.keys(moduleIdsFromUrl).length - 1]] !== undefined);

		const amName =
			Object.keys(moduleIdsFromUrl).length > 0
				? moduleNames.includes(Object.keys(moduleIdsFromUrl)[Object.keys(moduleIdsFromUrl).length - 1])
					? Object.keys(moduleIdsFromUrl)[Object.keys(moduleIdsFromUrl).length - 1]
					: Object.keys(moduleIdsFromUrl)[Object.keys(moduleIdsFromUrl).length - 2]
				: loginRoute.name;
		// console.log("amName");
		// console.log(amName);

		const am = moduleRoutes.find(({ name }) => name === amName);
		// console.log("am");
		// console.log(am);
		return am || loginRoute;
	}

	function getSubmodules(mod) {
		const sms = mod.submodules;

		// console.log("sms");
		// console.log(sms);

		if (sms) {
			switch (mod.module) {
				case "dashboard":
					sms.forEach((sm) => (sm.link = createDashboardLink(sm.name === sm.module ? null : sm.name)));
					break;
				case "flights":
					sms.forEach((sm) => {
						sm.link = createFlightLink(true, null, sm.name === sm.module ? null : sm.name);
					});
					break;
				case "fields":
					sms.forEach((sm) => (sm.link = createFieldLink(true, null, sm.name === sm.module ? null : sm.name, null)));
					break;
				case "trials":
					sms.forEach(
						(sm) => (sm.link = createTrialLink(true, null, sm.name === sm.module ? null : sm.name, null, null))
					);
					break;
				case "protocols":
					sms.forEach((sm) => (sm.link = createProtocolLink(true, null, sm.name === sm.module ? null : sm.name, null)));
					break;
				case "ap-settings":
					sms.forEach((sm) => (sm.link = createApSettingsLink(sm)));
					break;
				case "client-settings":
					sms.forEach((sm) => (sm.link = createClientSettingsLink(sm)));
					break;
			}
			// console.log(sms);
			return sms;
		}

		return [];
	}

	function getActiveSubmodule(submods) {
		if (submods) {
			const activeSubmod = submods.find((sm) => {
				let smPaths = sm.paths;
				let smPathsWithId = sm.paths.map((p) => {
					let regexedPath = replaceAll(p, escapeRegExp(guidRegex), "");
					regexedPath = regexedPath.replace(optionalRoutePathIdRegex, ":id/");
					return regexedPath;
				});
				smPaths = smPaths.concat(smPathsWithId);
				let smPathsWithoutId = sm.paths.map((p) => {
					let regexedPath = replaceAll(p, escapeRegExp(guidRegex), "");
					regexedPath = regexedPath.replace(optionalRoutePathIdRegex, "");
					return regexedPath;
				});
				smPaths = [...new Set(smPaths.concat(smPathsWithoutId))];
				const includes = smPaths.includes(routeMatch.path.replace(/\/$/, ""));
				return includes;
			});
			// console.log("activeSubmod");
			// console.log(activeSubmod);
			return activeSubmod || {};
		}

		return {};
	}

	function getCurrentPathIsEnabled() {
		let isEnabled = false;
		if (activeSubmodule && Object.keys(activeSubmodule).length > 0) {
			isEnabled = activeSubmodule.enabled;
		} else {
			const route = allRoutes.find((r) => {
				return r.paths.includes(location.pathname);
			});

			if (route) {
				isEnabled = route.enabled;
			}
		}

		return isEnabled;
	}

	//-- Purposely trying to keep this "dumb"
	//--  It will not know if the generated link is a valid hierarchy (i.e. if trialId 123 is in fieldId abc). The view should validate this.

	function createDashboardLink(submodule) {
		let dashboardLink = "/dashboard";

		if (submodule) {
			dashboardLink += `/${submodule}`;
		}

		return dashboardLink;
	}

	//-- This is the highest level entity, it will always be replaced if navigating to a different instance
	function createFlightLink(useIdsFromUrl, flightId, submodule) {
		let flightLink = "/flights";

		if (flightId || (useIdsFromUrl && moduleIdsFromUrl.flights)) {
			flightLink += `/${flightId ?? moduleIdsFromUrl.flights}`;
		}

		if (submodule) {
			flightLink += `/${submodule}`;
		}

		return flightLink;
	}

	//-- Pass in the flightId if we know it, otherwise try to pull it from the url if it's currently set
	function createFieldLink(useIdsFromUrl, fieldId, submodule, flightId, datasetId) {
		let fieldLink = "";

		if (flightId || (useIdsFromUrl && moduleIdsFromUrl.flights)) {
			fieldLink += `/flights/${flightId ?? moduleIdsFromUrl.flights}`;
		}

		fieldLink += "/fields";

		if (fieldId || (useIdsFromUrl && moduleIdsFromUrl.fields)) {
			fieldLink += `/${fieldId ?? moduleIdsFromUrl.fields}`;
		}

		if (submodule) {
			fieldLink += `/${submodule}`;
		}

		if (submodule && datasetId) {
			fieldLink += `/${datasetId}`;
		}

		return fieldLink;
	}

	//-- Pass in the *Id if we know them, otherwise try to pull them from the url if they're currently set
	function createTrialLink(useIdsFromUrl, trialId, submodule, fieldId, flightId, extraId) {
		let trialLink = "";

		if (flightId || (useIdsFromUrl && moduleIdsFromUrl.flights)) {
			trialLink += `/flights/${flightId ?? moduleIdsFromUrl.flights}`;
		}

		if (fieldId || (useIdsFromUrl && moduleIdsFromUrl.fields)) {
			trialLink += `/fields/${fieldId ?? moduleIdsFromUrl.fields}`;
		}

		trialLink += "/trials";

		if (trialId || (useIdsFromUrl && moduleIdsFromUrl.trials)) {
			trialLink += `/${trialId ?? moduleIdsFromUrl.trials}`;
		}

		if (submodule) {
			trialLink += `/${submodule}`;
		}

		// extra id will be used after submodule
		if (submodule && extraId) {
			trialLink += `/${extraId}`;
		}

		return trialLink;
	}

	function createProtocolLink(useIdsFromUrl, protocolId, submodule) {
		let protocolLink = "";

		protocolLink += "/protocols";

		if (protocolId || (useIdsFromUrl && moduleIdsFromUrl.protocols)) {
			protocolLink += `/${protocolId ?? moduleIdsFromUrl.protocols}`;
		}

		if (submodule) {
			protocolLink += `/${submodule}`;
		}

		return protocolLink;
	}

	function createApSettingsLink(route) {
		let settingsLink = "/ap-settings/";

		settingsLink += route.name;

		return settingsLink;
	}

	function createClientSettingsLink(route) {
		let settingsLink = "/client-settings/";

		settingsLink += route.name;

		return settingsLink;
	}

	function goToLogin() {
		history.push(loginRoute.paths[0]);
	}
	function goToSupport() {
		history.push("/support");
	}
	function goToSetupNeeded() {
		history.push("/setup-needed");
	}
	function goToEula() {
		history.push("/eula");
	}
	function goToSubscriptionExpired() {
		history.push("/subscription-expired");
	}
	function goToAccessDenied() {
		history.push("/access-denied");
	}
	function goToPublishedTrials() {
		history.push("/trials/published-trials");
	}
	function goHome() {
		history.push(homeRoute.paths[0]);
	}

	function saveCurrentPath() {
		if (!activeModule.login && !activeModule.logout) {
			localStorage.setItem(`${ENVIRONMENT}-savedPath`, location.pathname);
		}
	}

	function navigateToSavedPath() {
		var savedPath = localStorage.getItem(`${ENVIRONMENT}-savedPath`);
		if (savedPath) {
			history.push(savedPath);
		} else {
			goHome();
		}
	}

	//Breaks the saved path into an array containing each component of the URL.
	//Then iterates through array checking to see if any part of the link contains a GUID, indicating that there is a
	//Trial, Protocol, Flight, or Field that a user is trying to navigate to.
	function savedPathIsLink() {
		var savedPathArray = localStorage
			.getItem(`${ENVIRONMENT}-savedPath`)
			.split("/")
			.filter((el) => el !== "");
		for (let i = 0; i < savedPathArray.length; i += 2) {
			if (savedPathArray[i + 1]?.match(guidRegex)) {
				return true;
			}
		}
		return false;
	}

	function removeCurrentPath() {
		localStorage.removeItem(`${ENVIRONMENT}-savedPath`);
	}

	return {
		allModules: moduleRoutes,
		allModulesAndSubmodules: allRoutes,
		loginModule: loginRoute,
		homeModule: homeRoute,
		moduleIdsFromUrl,
		flightId,
		fieldId,
		trialId,
		datasetId,
		protocolId,
		timelineFlightId,
		urlQueryStrings,
		qsPtfk,
		moduleIsInContext,
		activeModule,
		currentSubmodules,
		activeSubmodule,
		currentPathIsEnabled,
		createDashboardLink,
		createFlightLink,
		createFieldLink,
		createTrialLink,
		createProtocolLink,
		goToLogin,
		goToSupport,
		goToSetupNeeded,
		goToEula,
		goToSubscriptionExpired,
		goToPublishedTrials,
		goHome,
		saveCurrentPath,
		navigateToSavedPath,
		removeCurrentPath,
		goToAccessDenied,
		savedPathIsLink,
		currentLocation: location.pathname
	};
};
