import FieldSearch from "./Content/Fields/FieldSearch";
import FieldViewer from "./Content/Fields/FieldViewer";
import FieldHeatmap from "./Content/Fields/FieldHeatmap";
import FieldHeatmapLegacy from "./Content/Fields/FieldHeatmap/legacyIndex.js";
import FieldOutline from "./Content/Fields/FieldOutline";
import FieldGroundData from "./Content/Fields/FieldGroundData";
import FieldAssessmentLog from "./Content/Fields/FieldAssessmentLog";

import { guidRegex } from "../utilities/regex";
import FieldWizard from "./Content/Fields/FieldWizard";
import { ApplicationArea } from "./Lumber/ApplicationAreas";

//-- enabled has no effect if the parent module (i.e. the module refrencing these routs in routs.js), is disabled
//--  That is, the enabled property cannot override the parent module enabled property if it is disabled

export const fieldRoutes = [
	{
		id: "field-search",
		name: "search",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Search",
		icon: "search",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [
			`/fields`,
			`/fields/:id${guidRegex}?/search`,
			`/flights/:id${guidRegex}/fields`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}?/search`
		],
		exact: true,
		component: FieldSearch,
		module: "fields",
		applicationAreas: [ApplicationArea.FieldSearch],
		requiresContext: false,
		showModuleIdInBreadcrumb: false
	},
	{
		id: "field-new",
		name: "new",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "New",
		icon: "bars",
		cornerIcon: "plus",
		showInNav: true,
		secured: true,
		paths: [`/fields/:id${guidRegex}?/new`, `/flights/:id${guidRegex}/fields/:id${guidRegex}?/new`],
		exact: true,
		component: () => new FieldWizard({ activeStep: "info", mode: "new" }),
		module: "fields",
		applicationAreas: [ApplicationArea.FieldNew],
		requiresContext: false,
		showModuleIdInBreadcrumb: false
	},
	{
		id: "field-edit",
		navMatchingNames: ["new", "edit", "layout", "gps-setup", "advanced-analytics"],
		name: "edit",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Edit",
		icon: "bars",
		cornerIcon: "pencil",
		showInNav: true,
		secured: true,
		paths: [`/fields/:id${guidRegex}/edit`, `/flights/:id${guidRegex}/fields/:id${guidRegex}/edit`],
		exact: true,
		component: () => new FieldWizard({ activeStep: "info", mode: "edit" }),
		module: "fields",
		applicationAreas: [ApplicationArea.FieldEdit],
		requiresContext: true
	},
	{
		id: "field-layout",
		navMatchingNames: ["new", "edit", "layout", "gps-setup", "advanced-analytics"],
		name: "layout",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Field Layout",
		icon: null,
		cornerIcon: null,
		showInNav: false,
		secured: true,
		paths: [`/fields/:id${guidRegex}/layout`, `/flights/:id${guidRegex}/fields/:id${guidRegex}/layout`],
		exact: true,
		component: () => new FieldWizard({ activeStep: "layout" }),
		module: "fields",
		applicationAreas: [ApplicationArea.FieldEdit],
		requiresContext: true
	},
	{
		id: "field-gps-setup",
		navMatchingNames: ["new", "edit", "layout", "gps-setup", "advanced-analytics"],
		name: "gps-setup",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "GPS Setup",
		icon: null,
		cornerIcon: null,
		showInNav: false,
		secured: true,
		paths: [`/fields/:id${guidRegex}/gps-setup`, `/flights/:id${guidRegex}/fields/:id${guidRegex}/gps-setup`],
		exact: true,
		component: () => new FieldWizard({ activeStep: "gps-setup" }),
		module: "fields",
		applicationAreas: [ApplicationArea.FieldEdit],
		requiresContext: true
	},
	{
		id: "field-advanced-analytics",
		navMatchingNames: ["new", "edit", "layout", "gps-setup", "advanced-analytics"],
		name: "advanced-analytics",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Advanced Analytics",
		icon: null,
		cornerIcon: null,
		showInNav: false,
		secured: true,
		paths: [
			`/fields/:id${guidRegex}/advanced-analytics`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/advanced-analytics`
		],
		exact: true,
		component: () => new FieldWizard({ activeStep: "advanced-analytics" }),
		module: "fields",
		applicationAreas: [ApplicationArea.FieldEdit],
		requiresContext: true
	},
	{
		id: "field-viewer",
		name: "viewer",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Viewer",
		icon: "bars",
		cornerIcon: "binoculars",
		showInNav: true,
		secured: true,
		paths: [
			`/fields/:id${guidRegex}`,
			`/fields/:id${guidRegex}/viewer`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/viewer`
		],
		exact: true,
		component: FieldViewer,
		module: "fields",
		applicationAreas: [ApplicationArea.FieldViewer, ApplicationArea.FieldVisualHeatmapValues],
		requiresContext: true
	},
	{
		id: "field-heatmap",
		name: "heatmap",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Heatmap",
		icon: "map",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/fields/:id${guidRegex}/heatmap`, `/flights/:id${guidRegex}/fields/:id${guidRegex}/heatmap`],
		exact: true,
		component: FieldHeatmap,
		module: "fields",
		applicationAreas: [ApplicationArea.FieldHeatMap],
		requiresContext: true
	},
	{
		id: "field-heatmap-legacy",
		name: "heatmap-legacy",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Heatmap",
		icon: "map",
		cornerIcon: null,
		showInNav: false,
		secured: true,
		paths: [`/fields/:id${guidRegex}/heatmap-legacy`, `/flights/:id${guidRegex}/fields/:id${guidRegex}/heatmap-legacy`],
		exact: true,
		component: FieldHeatmapLegacy,
		module: "fields",
		applicationAreas: [ApplicationArea.FieldHeatMap],
		requiresContext: true
	},
	{
		id: "field-outline",
		name: "outline",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Outline",
		icon: "table",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [`/fields/:id${guidRegex}/outline`, `/flights/:id${guidRegex}/fields/:id${guidRegex}/outline`],
		exact: true,
		component: FieldOutline,
		module: "fields",
		applicationAreas: [ApplicationArea.FieldOutline],
		requiresContext: true
	},
	{
		id: "ground-data",
		name: "ground-data",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Ground Data",
		icon: "upload",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [
			`/fields/:id${guidRegex}/ground-data`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/ground-data`,
			`/fields/:id${guidRegex}/ground-data/:id${guidRegex}`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/ground-data/:id${guidRegex}`
		],
		exact: true,
		component: FieldGroundData,
		module: "fields",
		applicationAreas: [ApplicationArea.FieldGroundData],
		requiresContext: true
	},
	{
		id: "field-assessment-log",
		name: "field-assessment-log",
		enabled: false, //disabled upon Jeremiah's request
		login: false,
		logout: false,
		home: false,
		linkText: "Assessment Log",
		icon: "list",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [
			`/fields/:id${guidRegex}/field-assessment-log`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/field-assessment-log`
		],
		exact: true,
		component: FieldAssessmentLog,
		module: "fields",
		applicationAreas: [],
		requiresContext: true
	}
];

export default {
	fieldRoutes
};
