import React, { useEffect } from "react";
import { Switch } from "react-router-dom";

import _ from "lodash";

import "semantic-ui-css/semantic.min.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; //-- Placing ToastContainer here will allow a toast to be displayed even if we display and redirect immediately after. The toast will display on the page that we redirect to.

import "rc-tooltip/assets/bootstrap.css";

import Layout from "./Structure/Layout";
import PublicRoute from "../auth/PublicRoute";
import PrivateRoute from "../auth/PrivateRoute";

import { useModuleNavigation } from "../hooks/useModuleNavigation";
import { useSelector } from "react-redux";

import { AppInsightsContext, ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import PropTypes from "prop-types";

const App = ({ browserHistory }) => {
	const moduleNavigation = useModuleNavigation();
	const reactPlugin = new ReactPlugin();

	const currentClientId = useSelector((state) => (state.clients ? state.clients.currentId : null));
	const user = useSelector((state) => (state.user ? state.user : null));

	useEffect(() => {
		if (user && currentClientId) {
			setupApplicationInsights();
		}
	}, [user, currentClientId]);

	function setupApplicationInsights() {
		const applicationInsights = new ApplicationInsights({
			config: {
				instrumentationKey: process.env.applicationInsightsInstrumentationKey,
				extensions: [reactPlugin],
				extensionConfig: {
					[reactPlugin.identifier]: { history: browserHistory }
				}
			}
		});
		applicationInsights.loadAppInsights();
		setupCustomTelemetry(applicationInsights);
	}

	function setupCustomTelemetry(applicationInsights) {
		applicationInsights.addTelemetryInitializer((t) => {
			// Update criteria as per your need.
			if (t.baseType === "PageviewData") {
				let data = t.baseData;
				data.properties = data.properties || {};

				//-- User data
				data.properties["user"] = user.userInfo?.basic;

				//-- ClientId
				data.properties["clientId"] = currentClientId;
			}
			return true; // enable all telemetry types
		});
	}
	return (
		<>
			<AppInsightsContext.Provider value={reactPlugin}>
				<Layout>
					<Switch>
						{_.map(
							moduleNavigation.allModulesAndSubmodules,
							({ enabled, secured, id, exact, paths, component: Component }) => {
								if (enabled && Component) {
									return secured ? (
										<PrivateRoute key={id} exact={exact} path={paths}>
											<Component key={currentClientId} />
										</PrivateRoute>
									) : (
										<PublicRoute key={id} exact={exact} path={paths}>
											<Component key={currentClientId} />
										</PublicRoute>
									);
								}
							}
						)}
					</Switch>
				</Layout>
			</AppInsightsContext.Provider>
			<ToastContainer autoClose={3000} theme="colored" />
		</>
	);
};

App.propTypes = {
	browserHistory: PropTypes.object.isRequired
};

export default App;
