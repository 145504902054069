import TrialSearch from "./Content/Trials/TrialSearch";
import PublishedTrials from "./Content/Trials/PublishedTrials";
import TrialPublishing from "./Content/Trials/TrialPublishing";
import TrialViewer from "./Content/Trials/TrialViewer";
import EditTrial from "./Content/Trials/EditTrial";
import TrialHeatmap from "./Content/Trials/TrialHeatmap";
import TrialHeatmapLegacy from "./Content/Trials/TrialHeatmap/legacyIndex.js";
import TrialOutline from "./Content/Trials/TrialOutline";
import TrialAnalysis from "./Content/Trials/TrialAnalysis";
import TrialGroundData from "./Content/Trials/TrialGroundData";
import TrialCurveModels from "./Content/Trials/TrialCurveModels";
import TrialWeather from "./Content/Trials/TrialWeather/index";

import { guidRegex } from "../utilities/regex";
import TrialAssessmentLog from "./Content/Trials/TrialAssessmentLog";
import { ApplicationArea } from "./Lumber/ApplicationAreas";

//-- enabled has no effect if the parent module (i.e. the module refrencing these routs in routs.js), is disabled
//--  That is, the enabled property cannot override the parent module enabled property if it is disabled

export const trialRoutes = [
	{
		id: "trial-search",
		name: "search",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Search",
		icon: "search",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [
			`/trials`,
			`/trials/:id${guidRegex}?/search`,
			`/flights/:id${guidRegex}/trials`,
			`/flights/:id${guidRegex}/trials/:id${guidRegex}?/search`,
			`/fields/:id${guidRegex}/trials`,
			`/fields/:id${guidRegex}/trials/:id${guidRegex}?/search`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/trials`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/trials/:id${guidRegex}?/search`
		],
		exact: true,
		component: TrialSearch,
		module: "trials",
		applicationAreas: [ApplicationArea.TrialSearch],
		requiresContext: false,
		showModuleIdInBreadcrumb: false
	},
	{
		id: "published-trials",
		name: "published-trials",
		enabled: false, //disabled upon Jeremiah's request
		login: false,
		logout: false,
		home: false,
		linkText: "Published Trials",
		icon: "leanpub",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [
			`/trials/published-trials`,
			`/trials/:id${guidRegex}/published-trials`,
			`/flights/:id${guidRegex}/trials/:id${guidRegex}/published-trials`,
			`/fields/:id${guidRegex}/trials/:id${guidRegex}/published-trials`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/trials/:id${guidRegex}/published-trials`
		],
		exact: true,
		component: PublishedTrials,
		module: "trials",
		applicationAreas: [],
		requiresContext: false,
		showModuleIdInBreadcrumb: false
	},
	{
		id: "trial-publishing",
		name: "trial-publishing",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Trial Publishing",
		icon: "leaf",
		cornerIcon: "leanpub",
		showInNav: true,
		secured: true,
		paths: [
			`/trials/trial-publishing`,
			`/trials/:id${guidRegex}/trial-publishing`,
			`/flights/:id${guidRegex}/trials/:id${guidRegex}/trial-publishing`,
			`/fields/:id${guidRegex}/trials/:id${guidRegex}/trial-publishing`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/trials/:id${guidRegex}/trial-publishing`
		],
		exact: true,
		component: TrialPublishing,
		module: "trials",
		applicationAreas: [ApplicationArea.TrialPublishing],
		requiresContext: true,
		showModuleIdInBreadcrumb: false
	},
	{
		id: "trial-viewer",
		name: "viewer",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Viewer",
		icon: "leaf",
		cornerIcon: "binoculars",
		showInNav: true,
		secured: true,
		paths: [
			`/trials/:id${guidRegex}`,
			`/trials/:id${guidRegex}/viewer`,
			`/flights/:id${guidRegex}/trials/:id${guidRegex}`,
			`/flights/:id${guidRegex}/trials/:id${guidRegex}/viewer`,
			`/fields/:id${guidRegex}/trials/:id${guidRegex}`,
			`/fields/:id${guidRegex}/trials/:id${guidRegex}/viewer`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/trials/:id${guidRegex}`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/trials/:id${guidRegex}/viewer`,
			`/trials/:id${guidRegex}/viewer/:id${guidRegex}`,
			`/flights/:id${guidRegex}/trials/:id${guidRegex}/viewer/:id${guidRegex}`,
			`/fields/:id${guidRegex}/trials/:id${guidRegex}/viewer/:id${guidRegex}`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/trials/:id${guidRegex}/viewer/:id${guidRegex}`
		],
		exact: true,
		component: TrialViewer,
		module: "trials",
		applicationAreas: [
			ApplicationArea.TrialViewer,
			ApplicationArea.TrialVisualHeatmapShading,
			ApplicationArea.TrialVisualHeatmapValues,
			ApplicationArea.TrialExportFunctionality,
			ApplicationArea.TrialDamagePlots,
			ApplicationArea.TrialDamagePlotsCRUD,
			ApplicationArea.TrialExcludePlots,
			ApplicationArea.TrialExcludePlotsCRUD,
			ApplicationArea.TrialFlagPlots
		],
		requiresContext: true
	},
	{
		id: "trial-edit",
		name: "edit",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Edit",
		icon: "leaf",
		cornerIcon: "pencil",
		showInNav: true,
		secured: true,
		paths: [
			`/trials/:id${guidRegex}/edit`,
			`/flights/:id${guidRegex}/trials/:id${guidRegex}/edit`,
			`/fields/:id${guidRegex}/trials/:id${guidRegex}/edit`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/trials/:id${guidRegex}/edit`
		],
		exact: true,
		component: EditTrial,
		module: "trials",
		applicationAreas: [ApplicationArea.TrialEdit],
		requiresContext: true
	},
	{
		id: "trial-heatmap",
		name: "heatmap",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Heatmap",
		icon: "map",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [
			`/trials/:id${guidRegex}/heatmap`,
			`/flights/:id${guidRegex}/trials/:id${guidRegex}/heatmap`,
			`/fields/:id${guidRegex}/trials/:id${guidRegex}/heatmap`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/trials/:id${guidRegex}/heatmap`,
			`/trials/:id${guidRegex}/heatmap/:id${guidRegex}`,
			`/flights/:id${guidRegex}/trials/:id${guidRegex}/heatmap/:id${guidRegex}`,
			`/fields/:id${guidRegex}/trials/:id${guidRegex}/heatmap/:id${guidRegex}`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/trials/:id${guidRegex}/heatmap/:id${guidRegex}`
		],
		exact: true,
		component: TrialHeatmap,
		module: "trials",
		applicationAreas: [ApplicationArea.TrialHeatMap],
		requiresContext: true
	},
	{
		id: "trial-heatmap-legacy",
		name: "heatmap-legacy",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Heatmap",
		icon: "map",
		cornerIcon: null,
		showInNav: false,
		secured: true,
		paths: [
			`/trials/:id${guidRegex}/heatmap-legacy`,
			`/flights/:id${guidRegex}/trials/:id${guidRegex}/heatmap-legacy`,
			`/fields/:id${guidRegex}/trials/:id${guidRegex}/heatmap-legacy`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/trials/:id${guidRegex}/heatmap-legacy`,
			`/trials/:id${guidRegex}/heatmap-legacy/:id${guidRegex}`,
			`/flights/:id${guidRegex}/trials/:id${guidRegex}/heatmap-legacy/:id${guidRegex}`,
			`/fields/:id${guidRegex}/trials/:id${guidRegex}/heatmap-legacy/:id${guidRegex}`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/trials/:id${guidRegex}/heatmap-legacy/:id${guidRegex}`
		],
		exact: true,
		component: TrialHeatmapLegacy,
		module: "trials",
		applicationAreas: [ApplicationArea.TrialHeatMap],
		requiresContext: true
	},
	{
		id: "trial-analysis",
		name: "analysis",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Analysis",
		icon: "chart bar outline",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [
			`/trials/:id${guidRegex}/analysis`,
			`/flights/:id${guidRegex}/trials/:id${guidRegex}/analysis`,
			`/fields/:id${guidRegex}/trials/:id${guidRegex}/analysis`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/trials/:id${guidRegex}/analysis`
		],
		exact: true,
		component: () => new TrialAnalysis(),
		module: "trials",
		applicationAreas: [
			ApplicationArea.TrialAnalysis,
			ApplicationArea.TrialAnalysisTimecourse,
			ApplicationArea.TrialAnalysisBoxWhisker,
			ApplicationArea.TrialAnalysisSummaryTable,
			ApplicationArea.TrialAnalysisCorrelationMatrix
		],
		requiresContext: true
	},
	{
		id: "trial-analysis-scatter-plot",
		name: "analysis-scatter-plot",
		enabled: false,
		login: false,
		logout: false,
		home: false,
		linkText: "Scatter Plot",
		icon: "chart bar outline",
		cornerIcon: null,
		showInNav: false,
		secured: true,
		paths: [
			`/trials/:id${guidRegex}/analysis-scatter-plot`,
			`/flights/:id${guidRegex}/trials/:id${guidRegex}/analysis-scatter-plot`,
			`/fields/:id${guidRegex}/trials/:id${guidRegex}/analysis-scatter-plot`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/trials/:id${guidRegex}/analysis-scatter-plot`
		],
		exact: true,
		component: () => new TrialAnalysis("scatter-plot"),
		module: "trials",
		applicationAreas: [],
		requiresContext: true
	},
	{
		id: "trial-analysis-timecourse",
		name: "analysis-timecourse",
		enabled: false,
		login: false,
		logout: false,
		home: false,
		linkText: "Timecourse",
		icon: "chart bar outline",
		cornerIcon: null,
		showInNav: false,
		secured: true,
		paths: [
			`/trials/:id${guidRegex}/analysis-timecourse`,
			`/flights/:id${guidRegex}/trials/:id${guidRegex}/analysis-timecourse`,
			`/fields/:id${guidRegex}/trials/:id${guidRegex}/analysis-timecourse`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/trials/:id${guidRegex}/analysis-timecourse`
		],
		exact: true,
		component: () => new TrialAnalysis("timecourse"),
		module: "trials",
		applicationAreas: [ApplicationArea.TrialAnalysisTimecourse],
		requiresContext: true
	},
	{
		id: "trial-outline",
		name: "outline",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Outline",
		icon: "table",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [
			`/trials/:id${guidRegex}/outline`,
			`/flights/:id${guidRegex}/trials/:id${guidRegex}/outline`,
			`/fields/:id${guidRegex}/trials/:id${guidRegex}/outline`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/trials/:id${guidRegex}/outline`
		],
		exact: true,
		component: TrialOutline,
		module: "trials",
		applicationAreas: [ApplicationArea.TrialOutline],
		requiresContext: true
	},
	{
		id: "ground-data",
		name: "ground-data",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Ground Data",
		icon: "upload",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [
			`/trials/:id${guidRegex}/ground-data`,
			`/flights/:id${guidRegex}/trials/:id${guidRegex}/ground-data`,
			`/fields/:id${guidRegex}/trials/:id${guidRegex}/ground-data`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/trials/:id${guidRegex}/ground-data`,
			`/trials/:id${guidRegex}/ground-data/:id${guidRegex}`,
			`/flights/:id${guidRegex}/trials/:id${guidRegex}/ground-data/:id${guidRegex}`,
			`/fields/:id${guidRegex}/trials/:id${guidRegex}/ground-data/:id${guidRegex}`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/trials/:id${guidRegex}/ground-data/:id${guidRegex}`
		],
		exact: true,
		component: TrialGroundData,
		module: "trials",
		applicationAreas: [
			ApplicationArea.TrialGroundData,
			ApplicationArea.TrialGroundDataCRUD,
			ApplicationArea.TrialGroundDataTemplate
		],
		requiresContext: true
	},
	{
		id: "trial-assessment-log",
		name: "trial-assessment-log",
		enabled: false, //disabled upon Jeremiah's request
		login: false,
		logout: false,
		home: false,
		linkText: "Assessment Log",
		icon: "list",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [
			`/trials/:id${guidRegex}/trial-assessment-log`,
			`/flights/:id${guidRegex}/trials/:id${guidRegex}/trial-assessment-log`,
			`/fields/:id${guidRegex}/trials/:id${guidRegex}/trial-assessment-log`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/trials/:id${guidRegex}/trial-assessment-log`
		],
		exact: true,
		component: TrialAssessmentLog,
		module: "trials",
		applicationAreas: [],
		requiresContext: true
	},
	{
		id: "trial-curve-model",
		name: "trial-curve-model",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Curve Model",
		icon: "chart area",
		cornerIcon: null,
		showInNav: true,
		secured: true,
		paths: [
			`/trials/:id${guidRegex}/trial-curve-model`,
			`/flights/:id${guidRegex}/trials/:id${guidRegex}/trial-curve-model`,
			`/fields/:id${guidRegex}/trials/:id${guidRegex}/trial-curve-model`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/trials/:id${guidRegex}/trial-curve-model`
		],
		exact: true,
		component: TrialCurveModels,
		module: "trials",
		applicationAreas: [ApplicationArea.TrialCurveModels, ApplicationArea.TrialCurveModelsCRUD],
		requiresContext: true
	},
	{
		id: "trial-weather",
		name: "trial-weather",
		enabled: true,
		login: false,
		logout: false,
		home: false,
		linkText: "Trial Weather",
		icon: "sun",
		cornerIcon: "cloud",
		showInNav: true,
		secured: true,
		paths: [
			`/trials/:id${guidRegex}/trial-weather`,
			`/flights/:id${guidRegex}/trials/:id${guidRegex}/trial-weather`,
			`/fields/:id${guidRegex}/trials/:id${guidRegex}/trial-weather`,
			`/flights/:id${guidRegex}/fields/:id${guidRegex}/trials/:id${guidRegex}/trial-weather`
		],
		exact: true,
		component: () => new TrialWeather(),
		module: "trials",
		applicationAreas: [ApplicationArea.TrialWeather],
		requiresContext: true
	}
];

export default {
	trialRoutes
};
