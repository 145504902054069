// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ribbonBanner.ui.segment {\n\tposition: sticky;\n\ttop: 0;\n\tz-index: 1050;\n\tmargin: 0 -2em;\n\tborder-radius: unset;\n\tborder: unset;\n\tborder-top: 1px solid rgba(34, 36, 38, 0.15);\n}\n", "",{"version":3,"sources":["webpack://./src/components/Content/Widgets/SummaryTable/style.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,MAAM;CACN,aAAa;CACb,cAAc;CACd,oBAAoB;CACpB,aAAa;CACb,4CAA4C;AAC7C","sourcesContent":[".ribbonBanner.ui.segment {\n\tposition: sticky;\n\ttop: 0;\n\tz-index: 1050;\n\tmargin: 0 -2em;\n\tborder-radius: unset;\n\tborder: unset;\n\tborder-top: 1px solid rgba(34, 36, 38, 0.15);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ribbonBanner": "ribbonBanner",
	"ui": "ui",
	"segment": "segment"
};
export default ___CSS_LOADER_EXPORT___;
