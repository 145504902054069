import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import _ from "lodash";
import moment from "moment";

import { Segment, Grid, Form, Loader, Button, Icon, Popup, Message } from "semantic-ui-react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { toast } from "react-toastify";

import { getDistinctObjects } from "../../../../utilities/jsUtils";

import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as analysisActions from "../../../../redux/actions/analysisActions";
import * as fieldActions from "../../../../redux/actions/fieldActions";
import * as excelGeneratorActions from "../../../../redux/actions/excelGeneratorActions";

import Heatmap from "../../../Lumber/Heatmap";
import Timelineslider from "../../../Lumber/TimelineSlider";
import AnalysisDropDownLists from "../../../Lumber/AnalysisDropDownLists";
import * as analysisDropDownListFunctions from "../../../Lumber/AnalysisDropDownLists/AnalysisDropDownListFunctions";
import * as excelGenerationFunctions from "../../../../utilities/excelGeneration";

import "./styles.css";

const FieldHeatmap = () => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const moduleNavigation = useModuleNavigation();
	const history = useHistory();

	//-- Data Sources
	const userAuth = useUserAuth();
	const [fieldHeatmapData, setFieldHeatmapData] = useState(null);
	const [flights, setFlights] = useState(null);
	const [analyses, setAnalyses] = useState(null);
	const [trials, setTrials] = useState(null);
	const [rawPresetOptions, setRawPresetOptions] = useState(null);

	//-- Timeline nodes
	const [flightNodes, setFlightNodes] = useState(null);

	//-- Dropdown options
	const [trialOptions, setTrialOptions] = useState([]);
	const [quantifiedRegionTypeOptions, setQuantifiedRegionTypeOptions] = useState([]);
	const [allAssessments, setAllAssessments] = useState([]);

	//-- Selected option
	const [flightId, setFlightId] = useState(null);
	const [analysisId, setAnalysisId] = useState(null);
	const [quantifiedRegionTypeId, setQuantifiedRegionTypeId] = useState(null);
	const [analysisType, setAnalysisType] = useState(null);

	const [analysisResults, setAnalysisResults] = useState(null);

	//-- Excel
	const [excelFileName, setExcelFileName] = useState("FieldHeatmap.xlsx");
	const [excelDownloadLoading, setExcelDownloadLoading] = useState(false);

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [processing, setProcessing] = useState(false);
	const [dataDone, setDataDone] = useState(true);
	const [showTrialOutline, setShowTrialOutline] = useState(false);

	const isForLegacy = false;

	//-- Get/Set - Field heatmap data
	useEffect(() => {
		if (!fieldHeatmapData && userAuth.currentClientId) {
			getPlotAnalysisStatus();
			getFieldHeatmapData(moduleNavigation.fieldId);
		}
	}, [userAuth.currentClientId]);

	async function getPlotAnalysisStatus() {
		const accessToken = await getTokenSilently();
		const res = await dispatch(
			fieldActions.getPlotAnalysisStatusForField(moduleNavigation.fieldId, userAuth.currentClientId, accessToken)
		);
		setDataDone(res);
	}

	async function getFieldHeatmapData(fieldId) {
		const accessToken = await getTokenSilently();
		setLoading(true);
		dispatch(analysisActions.getAnalysisResultsForField(userAuth.currentClientId, fieldId, isForLegacy, accessToken))
			.then((res) => {
				const subsampleOrdering = [
					{ type: "Plot-Centered with Subsamples", order: 1 },
					{ type: "Plot-Centered", order: 2 },
					{ type: "Row-Centered", order: 3 },
					{ type: "Full Row", order: 4 },
					{ type: "Between-Row", order: 5 }
				];

				_.map(res.plotAnalysisResults, (par) => {
					par.subsampleOrder = _.find(subsampleOrdering, { type: par.quantifiedRegionTypeName })?.order;
					par.analysisOrder =
						_.find(
							res.presetOptions.timeSeriesPresets,
							(po) => po.analysisId === par.analysisId && po.analysisTypeId === par.analysisTypeId
						)?.order || Infinity;
				});

				let tempPlotAnalysisResults = _.sortBy(res.plotAnalysisResults, ["subsampleOrder", "analysisOrder"]);

				setFieldHeatmapData(tempPlotAnalysisResults);

				let doesTrialHaveStandCountData = false;
				if (
					_.some(
						tempPlotAnalysisResults,
						(tpar) => tpar.analysisName !== null && tpar.analysisName.includes("Stand Count (LAI)")
					)
				) {
					doesTrialHaveStandCountData = true;
				}
				//Remove Stand Count from the advanced analysis type options if no stand count data exists
				if (!doesTrialHaveStandCountData) {
					let standCountIndex = res.presetOptions.analysisTypes.findIndex((x) => x.name === "Stand Count");
					res.presetOptions.analysisTypes.splice(standCountIndex, 1);
					setRawPresetOptions(res.presetOptions);
				} else {
					setRawPresetOptions(res.presetOptions);
				}

				const fieldName = tempPlotAnalysisResults[0].fieldName;
				setExcelFileName(`${fieldName}_FieldHeatmap_${moment().format("YYYYMMDD_hhmma")}.xlsx`);
				//-- Don't set loading to false here because we are going to search after we get the options, so let that function set loading to false
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	}

	async function generateFieldHeatmapExcel(removeExcluded) {
		const accessToken = await getTokenSilently();
		setExcelDownloadLoading(true);
		let currentDateTimeString = JSON.stringify(new Date().toLocaleString());
		dispatch(
			excelGeneratorActions.generateFieldHeatmapExcel(
				userAuth.currentClientId,
				userAuth.currentClient.clientName,
				moduleNavigation.fieldId,
				currentDateTimeString,
				removeExcluded,
				accessToken
			)
		)
			.then((res) => {
				if (!removeExcluded) {
					excelGenerationFunctions.downloadExcelFile(res, excelFileName.split(".")[0] + "_Without_Exclusions.xlsx");
				} else {
					excelGenerationFunctions.downloadExcelFile(res, excelFileName);
				}

				setExcelDownloadLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				setExcelDownloadLoading(false);
				console.log(err);
				toast.error("Error downloading excel file. Please try again.");
			});
	}

	useEffect(() => {
		if (fieldHeatmapData) {
			//-- analyses
			let anlyss = fieldHeatmapData.map((fhmd) => {
				const a = {};
				a.id = fhmd.analysisId ? fhmd.analysisId : fhmd.assessmentId;
				a.name = fhmd.analysisName ? fhmd.analysisName : fhmd.assessmentName;
				a.subsample = fhmd.subsample;
				return a;
			});

			let idsWithSubsamples = _.uniq(
				_.map(
					_.filter(anlyss, (a) => a.subsample && a.subsample > 1),
					"id"
				)
			);

			anlyss = _.filter(anlyss, (a) => !idsWithSubsamples.includes(a.id));

			anlyss = _.sortBy(_.uniqWith(anlyss, _.isEqual), ["name"]);

			setAnalyses(anlyss);

			//-- trials
			let trls = fieldHeatmapData
				.filter((fmhd) => fmhd.trialId)
				.map((fhmd) => {
					const t = {};
					t.id = fhmd.trialId;
					t.name = fhmd.trialName;
					return t;
				});

			trls = getDistinctObjects(trls);
			setTrials(trls);

			//-- quantified regions
			let quantifiedRegions = _.map(_.uniqBy(fieldHeatmapData, "quantifiedRegionTypeId"), (qr) => {
				return { key: qr.quantifiedRegionTypeId, value: qr.quantifiedRegionTypeId, text: qr.quantifiedRegionTypeName };
			});

			setQuantifiedRegionTypeOptions(quantifiedRegions);

			setLoading(false);
		}
	}, [fieldHeatmapData]);

	useEffect(() => {
		if (
			fieldHeatmapData &&
			analysisId &&
			(quantifiedRegionTypeId || _.every(fieldHeatmapData, (fhmd) => fhmd.assessmentId))
		) {
			let subsample = null;
			let subsampleId = null;
			if (analysisId.includes("(")) {
				subsample = analysisId.split("(")[1];
				subsampleId = analysisId.split("(")[0];
			}

			//-- flights
			let flts = fieldHeatmapData
				.filter(
					(fhmd) =>
						(fhmd.analysisId ? fhmd.analysisId : fhmd.assessmentId) === (subsampleId ?? analysisId) &&
						//-- flight analysis quantified region data
						(fhmd.quantifiedRegionTypeId === quantifiedRegionTypeId || fhmd.groundDatasetId || fhmd.assessmentId) &&
						//-- Aalysis Type
						(fhmd.analysisTypeId === analysisType || fhmd.groundDatasetId || fhmd.assessmentId) &&
						//-- Ground Data subsamples
						(subsampleId === null || (subsampleId !== null && subsample == fhmd.subsample))
				)
				.map((fhmd) => {
					const f = {};
					f.id = fhmd.flightId ? fhmd.flightId : fhmd.groundDatasetId;
					f.name = fhmd.flightName;
					f.date = fhmd.flightDate ? fhmd.flightDate : fhmd.groundDatasetDate;
					f.groundData = fhmd.flightId === null;
					f.excludeFromAssessment = fhmd.excludeFromAssessment;
					return f;
				});

			flts = getDistinctObjects(flts);
			let flightDifferences = _.difference(flts, flights);
			if (flightDifferences.length > 0) {
				setFlights(flts);
			}

			setFlights(flts);
		}
	}, [fieldHeatmapData, analysisId, quantifiedRegionTypeId]);

	//-- Set node and ddl options
	useEffect(() => {
		if (flights && flights.length > 0) {
			setProcessing(true);

			setFlightNodes(
				_.map(flights, ({ id, date }) => {
					const opt = {
						id: id,
						date: moment(date).local(),
						color: "#4183c4",
						clickable: true,
						firstActive: moduleNavigation.flightId === id ? true : false,
						tooltip: (
							<>
								{`Flight Date & Time`} <br /> {moment(date).format("ddd --- MMMM DD, YYYY --- hh:mm a")}
							</>
						)
					};
					return opt;
				})
			);

			if (moduleNavigation.flightId) {
				setFlightId(moduleNavigation.flightId);
			} else {
				setFlightId(flights[0].id);
			}

			setProcessing(false);
		}
	}, [flights]);

	useEffect(() => {
		if (analyses && analyses.length > 0) {
			setProcessing(true);

			let analyticOptions = [];
			let orthos = analysisDropDownListFunctions.getOrthoTypes(fieldHeatmapData);
			let groundData = analysisDropDownListFunctions.getGroundDataPresets(fieldHeatmapData);
			let growthCurves = analysisDropDownListFunctions.getGrowthCurvePresets(fieldHeatmapData);

			analyticOptions = orthos.concat(groundData).concat(growthCurves);
			setAllAssessments(analyticOptions);

			setProcessing(false);
		}
	}, [analyses]);

	useEffect(() => {
		if (trials && trials.length > 0) {
			setProcessing(true);
			setTrialOptions(
				_.map(trials, ({ id, name }) => {
					const opt = { key: id, value: id, text: name };
					return opt;
				})
			);

			setProcessing(false);
		}
	}, [trials]);

	useEffect(() => {
		//-- analysis results
		if (flightId && analysisId && quantifiedRegionTypeId) {
			setProcessing(true);

			let subsample = null;
			let subsampleId = null;
			if (analysisId.includes("(")) {
				subsample = analysisId.split("(")[1];
				subsampleId = analysisId.split("(")[0];
			}

			var ars = fieldHeatmapData
				.filter(
					(fhmd) =>
						(fhmd.flightId ? fhmd.flightId : fhmd.groundDatasetId) === flightId &&
						//-- flight analysis quantified region data
						(fhmd.quantifiedRegionTypeId === quantifiedRegionTypeId || fhmd.groundDatasetId || fhmd.assessmentId) &&
						//-- Analysis Type
						(fhmd.analysisTypeId === analysisType || fhmd.groundDatasetId || fhmd.assessmentId) &&
						(fhmd.analysisId ? fhmd.analysisId : fhmd.assessmentId) === (subsampleId ?? analysisId) &&
						(subsampleId === null || (subsampleId !== null && subsample == fhmd.subsample))
				)
				.map((fhmd) => {
					var r = {};
					r.id = fhmd.flightDataAnalysisResultId;
					r.x = fhmd.plotRange;
					r.y = fhmd.plotColumn;
					r.trialId = fhmd.trialId;
					r.trialName = fhmd.trialName;
					r.analysis = fhmd.analysisName;
					r.value = fhmd.flightId ? fhmd.plotAnalysisResultValue : fhmd.groundDatasetAssessmentValue;
					r.tooltipText = generatePlotTooltipText(fhmd);
					r.excludeFromAssessment = fhmd.excludeFromAssessment;
					r.maxRanges = fhmd.maxRanges;
					r.maxColumns = fhmd.maxColumns;
					return r;
				});
			setAnalysisResults(ars);
			setProcessing(false);
		} else {
			setAnalysisResults([]);
		}
	}, [flightId, analysisId, quantifiedRegionTypeId, analysisType]);

	function generatePlotTooltipText(plotData) {
		let tooltipText = {};
		if (plotData.plotName) {
			tooltipText["Plot"] = plotData.plotName;
		}

		if (plotData.trialTreatmentId) {
			tooltipText["Treatment Id"] = plotData.trialTreatmentId;
		}

		if (plotData.plotReplicate) {
			tooltipText["Replicate"] = plotData.plotReplicate;
		}

		if (plotData.plotSeed) {
			tooltipText["Seed"] = plotData.plotSeed;
		}

		return tooltipText;
	}

	function updateSelectedAnalysis(value) {
		setAnalysisId(value);
	}

	function updateSelectedQuantifiedRegion(value) {
		setQuantifiedRegionTypeId(value);
	}

	function updateSelectedAnalysisType(value) {
		setAnalysisType(value);
	}

	return loading ? (
		<Loader active />
	) : !loading && (!fieldHeatmapData || fieldHeatmapData.length === 0) ? (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2>Field Heatmap</h2>
			<hr />
			<Segment>
				<p>{"Heatmap data for this field could not be found."}</p>
			</Segment>
		</Segment>
	) : (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2 style={{ float: "left" }}>Field Heatmap</h2>
			<h2 style={{ float: "right", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
				<i>{fieldHeatmapData[0].fieldName}</i>
			</h2>
			<hr style={{ clear: "both" }} />
			{!dataDone && (
				<Message warning>
					Plot analysis data for this field is currently being recalculated, so data may not be complete or up to date.
					Please refresh the page in a few minutes.
				</Message>
			)}
			<Form>
				<Grid id="field-heatmap-grid" verticalAlign="middle">
					<Grid.Row>
						<Grid.Column>
							<AnalysisDropDownLists
								rawPresetOptions={rawPresetOptions}
								allAssessmentTypes={allAssessments}
								quantifiedRegionTypes={quantifiedRegionTypeOptions}
								defaultQuantifiedRegion={
									_.find(fieldHeatmapData, (t) => {
										return t.isDefaultQuantifiedRegion === true;
									})?.quantifiedRegionTypeId
								}
								updatedSelectedAnalysisType={updateSelectedAnalysisType}
								updateSelectedAnalysis={updateSelectedAnalysis}
								updatedSelectedQuantifiedRegion={updateSelectedQuantifiedRegion}
								isApAdmin={userAuth.isApAdmin}
								heatmap={true}
								style={{ width: "unset" }}
							>
								<div style={{ width: "calc(100% - 1000px)" }} />
								<Form.Field>
									<label htmlFor="form-select-trial" style={{ display: "unset" }}>
										Trial
									</label>
								</Form.Field>
								<Form.Select
									id="form-select-trial"
									search
									placeholder={_.isEmpty(trialOptions) ? "No trials in this field" : "Go to trial heatmap"}
									options={trialOptions}
									onChange={(event, { value }) => {
										history.push(moduleNavigation.createTrialLink(true, value, "heatmap"));
									}}
									loading={!trialOptions}
									selectOnBlur={false}
								/>
								{fieldHeatmapData && (
									<Link
										loading={excelDownloadLoading}
										onClick={() => generateFieldHeatmapExcel(true)}
										style={{ whiteSpace: "nowrap" }}
									>
										Download Heatmap Data
									</Link>
								)}
								{fieldHeatmapData && userAuth.isApAdmin && (
									<Link
										loading={excelDownloadLoading}
										onClick={() => generateFieldHeatmapExcel(false)}
										style={{ whiteSpace: "nowrap" }}
									>
										Download Heatmap Data (without exclusions)
									</Link>
								)}
								{fieldHeatmapData && excelDownloadLoading ? <Loader active></Loader> : null}
								{fieldHeatmapData && userAuth.isApAdmin && (
									<Popup
										trigger={<Icon name="info circle" />}
										flowing
										hoverable
										content={
											<>
												Click &nbsp;
												<Link to={moduleNavigation.createFieldLink(true, null, "heatmap-legacy")}>Here</Link> &nbsp; for
												legacy page with CSV file download.
											</>
										}
									/>
								)}
							</AnalysisDropDownLists>
						</Grid.Column>
					</Grid.Row>
					{!_.isEmpty(flightNodes) && (
						<Grid.Row style={{ paddingBottom: 30 }}>
							<Grid.Column>
								<Segment basic style={{ width: "90%", margin: "0 auto" }}>
									<Timelineslider
										dates={flightNodes}
										updateSelected={(selected) => {
											setFlightId(selected.id);
										}}
									/>
								</Segment>
							</Grid.Column>
						</Grid.Row>
					)}
					<Grid.Row centered style={{ border: "1px solid" }}>
						<Grid.Column width="16">
							{!processing && _.isEmpty(analysisResults) ? (
								<Segment basic>
									<p>
										{
											"We couldn't find data for the field or selected analysis. Please run an analysis to see the heatmap."
										}
									</p>
								</Segment>
							) : processing ? (
								<></>
							) : (
								<TransformWrapper
									options={{
										disabled: false,
										limitToWrapper: false,
										limitToBounds: false
									}}
									wheel={{
										wheelEnabled: false
									}}
								>
									{({ zoomIn, zoomOut, resetTransform }) => (
										<React.Fragment>
											<div className="tools" style={{ float: "left" }}>
												<Button icon onClick={zoomIn}>
													<Icon name="plus" />
												</Button>
												<Button icon onClick={zoomOut}>
													<Icon name="minus" />
												</Button>
												<Button icon onClick={resetTransform}>
													<Icon name="repeat" />
												</Button>
												{!_.isEmpty(trials) && (
													<Button icon onClick={() => setShowTrialOutline(!showTrialOutline)}>
														<Icon name="clone outline" />
													</Button>
												)}
											</div>
											<TransformComponent>
												<Heatmap data={analysisResults} showTrialOutline={showTrialOutline} isField={true} />
											</TransformComponent>
										</React.Fragment>
									)}
								</TransformWrapper>
							)}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Form>
		</Segment>
	);
};

export default FieldHeatmap;
